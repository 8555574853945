import { useState, useEffect, useMemo } from "react";

import Card from "@mui/material/Card";
import styled from "styled-components";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import CustomLabels from "layouts/bookings/pages/components/CustomLabels";
import logoImg from "assets/images/main-logo-color.png";
import Grid from "@mui/material/Grid";
import config from "configs/config.json";
import { useDispatch } from "react-redux";
import { showAlert } from "actions/alert";
import { showLoading } from "actions/loading";
import { callServiceGetAuth, callServicePostAuth } from "services/AuthServices";
import CustomCalender from "layouts/bookings/pages/components/CustomCalendars/CustomCalender";
import IconButton from "layouts/bookings/pages/components/IconButton";
import Button from "@mui/material/Button";
import Select from "react-select";
import Moment from "moment/moment";
import DataTable from "components/DataTable";
// import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";

// import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

const TAG = "CustomerReport";

const max = { height: 210, width: 297 };

const columns = [
  { Header: "Shërbimi", accessor: "name", align: "center", fontSize: 20 },
  { Header: "Stafi", accessor: "staff", align: "center", fontSize: 20 },
  { Header: "Komenti", accessor: "comment", align: "center", fontSize: 20 },
  { Header: "Data", accessor: "date", align: "center", fontSize: 20 },
  { Header: "Studio", accessor: "studio", align: "center", fontSize: 20 },
];

const optionReports = [
  { value: "pdf", label: "PDF" },
  { value: "xls", label: "XLS" },
];

const convertData2Option = (data) =>
  data.map((item) => ({
    value: item.fields.name,
    label: item.fields.name,
  }));

function CustomerReporter({ onClose, customerId, isVisible }) {
  const [clientData, setClientData] = useState();
  const [bookings, setBookings] = useState();
  const [beginDate, setBeginDate] = useState();
  const [endDate, setEndDate] = useState();
  const [showBeginCalendar, setShowBeginCalendar] = useState(false);
  const [showEndCalendar, setShowEndCalendar] = useState(false);
  const [dataRows, setDataRows] = useState([]);
  const [summary, setSummary] = useState({ totalServices: 0, totalStudios: 0, sum: 0 });
  // const [individualData, setIndividualData] = useState({});
  const [applyFilters, setApplyFilters] = useState(true);

  const [initialService, setInitialService] = useState({});
  const [initialStaffs, setInitialStaffs] = useState({});
  const [initialStudio, setInitialStudio] = useState({});

  const [selectedService, setSelectedService] = useState({});
  const [selectedStaff, setSelectedStaff] = useState({});
  const [selectedStudio, setSelectedStudio] = useState({});
  const [reportFileType, setReportFileType] = useState("pdf");

  const dispatch = useDispatch();

  const handleGetCustomerWithServiceResponse = async (res, isError) => {
    dispatch(showLoading(false));

    console.log(TAG, "callAPIGetCustomerWithService res = ", JSON.stringify(res), clientData);

    if (isError) {
      dispatch(showAlert({ type: "error", msg: res }));
      return;
    }

    if (res.data && res.data.length > 0) {
      setClientData(res.data[0].fields);
      setBookings(res.data);
    }
  };

  const callAPIGetCustomerWithService = async (id) => {
    const url = config.BASE_URL + config.GET_CUSTOMER_WITH_SERVICE;
    console.log(TAG, "callAPIGetCustomerWithService url = ", url, id);

    const postData = { id };

    dispatch(showLoading(true));
    callServicePostAuth(url, postData, handleGetCustomerWithServiceResponse);
  };

  const handleGetAllIndivitualResponse = async (res, isErr) => {
    dispatch(showLoading(false));
    if (isErr) {
      dispatch(showAlert({ type: "error", msg: res }));
      return;
    }

    if (res.result === "success" && res.data) {
      // setIndividualData(res.data);
      console.log(TAG, "response = ", res.data);
      setInitialService(convertData2Option(res.data.services));
      setInitialStaffs(convertData2Option(res.data.staffs));
      setInitialStudio(convertData2Option(res.data.studios));
    }
  };

  const callAPIGetAllIndividuals = async () => {
    const url = config.BASE_URL + config.GET_ALL_INDIVITIAL_INFOS;
    console.log(TAG, "callAPIGetAllIndividuals url = ", url);

    dispatch(showLoading(true));

    callServiceGetAuth(url, handleGetAllIndivitualResponse);
  };

  const sumPrices = (prices) => {
    const fixedVals = prices.filter((item) => item.fixed);
    let fixedSum = 0;

    fixedVals.forEach((item) => {
      fixedSum += parseInt(item.fixed, 10);
    });
    const rangedVals = prices.filter((item) => item.min);

    console.log(fixedSum, fixedVals);

    if (rangedVals.length === 0) return fixedSum;

    let rangedSum = { min: 0, max: 0 };
    rangedVals.forEach((item) => {
      rangedSum = {
        min: rangedSum.min + parseInt(item.min, 10),
        max: rangedSum.max + parseInt(item.max, 10),
      };
    });
    console.log(rangedSum);
    return `${fixedSum + rangedSum.min} ~ ${fixedSum + rangedSum.max}`;
  };
  const getData4Table = () => {
    // const rows = [];
    // refTable.current.table = { rows, columns };

    if (bookings) {
      const tmpRows = [];

      let cntServices = 0;
      const studios = [];
      const prices = [];
      bookings.forEach((item) => {
        const row = {};

        if (
          beginDate &&
          Moment(item.fields.booking_datetime).utc() < Moment(beginDate, "DD.MM.YYYY")
        )
          return;

        if (endDate && Moment(item.fields.booking_datetime).utc() > Moment(endDate, "DD.MM.YYYY"))
          return;

        if (
          selectedService &&
          selectedService.length > 0 &&
          !item.fields.services_names.join(" | ").includes(selectedService)
        )
          return;

        if (selectedStaff && selectedStaff.length > 0 && selectedStaff !== item.fields.staff.name)
          return;

        if (
          selectedStudio &&
          selectedStudio.length > 0 &&
          selectedStudio !== item.fields.staff.studio
        )
          return;

        row.name = item.fields.services_names.join(" | ");
        row.staff = item.fields.staff.name;
        row.comment = item.fields.comment;
        row.date = Moment(item.fields.booking_datetime).format("DD.MM.YYYY");
        row.studio = item.fields.staff.studio;
        // row.price = item.fields.service.price;

        tmpRows.push(row);
        // tmpRows.push(row);
        // tmpRows.push(row);
        // tmpRows.push(row);
        // tmpRows.push(row);
        // tmpRows.push(row);

        // tmpRows.push(row);
        // tmpRows.push(row);
        // tmpRows.push(row);
        // tmpRows.push(row);
        // tmpRows.push(row);

        // tmpRows.push(row);
        // tmpRows.push(row);

        // tmpRows.push(row);

        // tmpRows.push(row);
        // tmpRows.push(row);
        // tmpRows.push(row);
        // tmpRows.push(row);
        // tmpRows.push(row);

        // tmpRows.push(row);
        // tmpRows.push(row);
        // tmpRows.push(row);
        // tmpRows.push(row);
        // tmpRows.push(row);

        // tmpRows.push(row);
        // tmpRows.push(row);

        // tmpRows.push(row);

        if (item.fields.service.price && item.fields.service.price.includes("~")) {
          const arrVal = item.fields.service.price.split("~");
          prices.push({ min: parseInt(arrVal[0], 10), max: parseInt(arrVal[1], 10) });
        } else {
          prices.push({ fixed: item.fields.service.price });
        }
        cntServices += 1;
        if (studios.filter((i) => i === item.fields.staff.pk).length === 0)
          studios.push(item.fields.staff.pk);
      });

      console.log("dddddddddddddddd", tmpRows);

      setDataRows(tmpRows);
      setSummary({
        totalServices: cntServices,
        totalStudios: studios.length,
        sum: sumPrices(prices),
      });
    }

    setApplyFilters(false);
  };

  useEffect(() => {
    if (isVisible && customerId > 0) {
      callAPIGetAllIndividuals();
      callAPIGetCustomerWithService(customerId);
    }
  }, [customerId, isVisible]);

  useEffect(() => {
    if (bookings && bookings.length > 0 && applyFilters) getData4Table();
  }, [bookings, applyFilters]);

  const handleClickedPrint = () => {
    if (!clientData || !clientData.client) return;
    if (reportFileType === "pdf") {
      const customerHeader = [
        {
          name: "Emri & mbiemri:",
          pid: "Numri personal:",
          dob: "Data e lindjës:",
          phone: "Telefoni:",
          address: "Adresa:",
          city: "Qyteti:",
          email: "E-mail:",
        },
      ];

      const customerData = [
        {
          name: `${clientData.client.name} ${clientData.client.surname}`,
          pid: clientData.client.personal_id,
          dob: clientData.client.dob,
          phone: clientData.client.phone,
          address: clientData.client.address,
          city: clientData.client.city,
          email: clientData.client.email,
        },
      ];

      const doc = new jsPDF("l", "mm", "a4");

      const img = new Image();
      img.src = logoImg;
      doc.addImage(img, "png", 120, 10, 65, 20);

      doc.setFontSize(16);
      // doc.setFontStyle("arial");

      doc.text("Detajet e klientit", 10, 30);
      const dim = doc.getTextDimensions("Detajet e klientit");
      doc.setFontSize(12);

      autoTable(doc, {
        head: customerHeader,
        body: customerData,
        startY: dim.h + 30,
        // tableLineColor: [189, 195, 199],
        // tableLineWidth: 0.1,
        margin: {
          bottom: 20,
          left: 10,
        },
        headStyles: {
          backgroundColor: "#C20B7E",
          fillColor: "#C20B7E",
        },
        /* apply styling to table body */
        bodyStyles: {
          valign: "top",
        },
        /* apply global styling */
        styles: {
          cellWidth: "wrap",
          rowPageBreak: "auto",
          halign: "justify",
        },
        /* apply styling specific to table columns */
        columnStyles: {
          name: {
            cellWidth: 40,
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
          },
          pid: {
            cellWidth: 20,
          },
          dob: {
            cellWidth: 40,
          },
          phone: {
            cellWidth: 30,
          },
          address: {
            cellWidth: 90,
          },
          city: {
            cellWidth: 30,
          },
          email: {
            cellWidth: 20,
          },
        },
      });

      const headerService = [
        {
          name: "Shërbimi",
          staff: "Stafi",
          comment: "Komenti",
          date: "Data",
          studio: "Studio",
          // price: "Shuma në €",
        },
      ];
      const tableService = [];
      let cntServices = 0;
      const totalStdios = [];

      bookings.forEach((item) => {
        const row = {};

        row.name = item.fields.services_names.join(" | ");
        row.staff = item.fields.staff.name;
        row.comment = item.fields.comment;
        row.date = Moment(item.fields.booking_datetime).format("DD.MM.YYYY");
        row.studio = item.fields.staff.studio;
        // row.price = item.fields.service.price;
        tableService.push(row);

        cntServices += 1;
        if (totalStdios.filter((i) => i === row.studio).length === 0) totalStdios.push(row.studio);
      });

      autoTable(doc, {
        head: headerService,
        body: tableService,
        startY: doc.lastAutoTable.finalY + 10,
        margin: {
          bottom: 20,
          left: 10,
        },

        // didParseCell: (t) => {
        //   if (t.section === "head") {
        //     t.cell.styles.backgroundColor = "#C20B7E";
        //   }
        // },
        headStyles: {
          backgroundColor: "#C20B7E",
          fillColor: "#C20B7E",
        },

        /* apply styling to table body */
        bodyStyles: {
          valign: "top",
        },
        /* apply global styling */
        styles: {
          cellWidth: "wrap",
          rowPageBreak: "auto",
          halign: "justify",
        },
        /* apply styling specific to table columns */
        columnStyles: {
          text: {
            cellWidth: "auto",
          },
        },
      });

      doc.text(" ", 10, doc.lastAutoTable.finalY + 10);
      const dim3 = doc.getTextDimensions(" ");

      autoTable(doc, {
        head: [
          {
            cntServices: "Shërbime të kryera",
            totalStdios: "Klienti ka vizituar",
          },
        ],
        body: [{ cntServices, totalStdios: totalStdios.length }],
        startY: doc.lastAutoTable.finalY + dim3.h + 10,
        margin: {
          bottom: 20,
          left: 10,
        },
        headStyles: {
          backgroundColor: "#C20B7E",
          fillColor: "#C20B7E",
        },
        /* apply styling to table body */
        bodyStyles: {
          valign: "top",
        },
        /* apply global styling */
        styles: {
          cellWidth: "wrap",
          rowPageBreak: "auto",
          halign: "justify",
        },
        /* apply styling specific to table columns */
        columnStyles: {
          text: {
            cellWidth: "auto",
          },
        },
      });

      const strDateTime = Moment(new Date()).format("DD-MM-YYYY, HH:mm");
      const dim2 = doc.getTextDimensions(strDateTime);
      doc.text(strDateTime, max.width - dim2.w - 20, doc.lastAutoTable.finalY + dim3.h + 10);

      const strUser = localStorage.getItem("user");
      let strReportedBy = "";
      if (strUser) {
        strReportedBy = `Krijuar nga ${JSON.parse(strUser).username}`;
      } else {
        strReportedBy = `Krijuar nga UNKNOWN`;
      }
      doc.setFontSize(14);
      doc.text(
        strReportedBy,
        max.width - dim2.w - 20,
        doc.lastAutoTable.finalY + dim3.h + dim2.h + 12
      );
      // customer_report

      const pageCount = doc.internal.getNumberOfPages();
      console.log("page count = ", pageCount);
      for (let i = 1; i <= pageCount; i += 1) {
        // Go to page i
        doc.setPage(i);
        // Print Page 1 of 4 for example
        doc.setFontSize(7);
        doc.text(
          ` Të gjitha të drejtat e këtij dokumenti janë të rezervuara nga Passion. Nuk lejohet kopjimi, duplikimi, citimi i tij në asnjë rast pa lejen me shkrim.`,
          doc.internal.pageSize.width / 2,
          198,
          {
            align: "center",
          }
        );

        const dim4 = doc.getTextDimensions("Staff details.");

        doc.text(`www.bepassion.com`, doc.internal.pageSize.width / 2, 198 + dim4.h, {
          align: "center",
        });
      }

      doc.save(`raporter - ${Moment(new Date()).format("DD.MM.YYYY")}.pdf`);
    } else {
      // XLSX
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet([]);
      XLSX.utils.book_append_sheet(wb, ws, "sheet1");

      const clientDetail = [
        {
          "Emri & mbiemri:": `${clientData.client.name} ${clientData.client.surname}`,
          "Numri personal:": clientData.client.personal_id,
          "Data e lindjës:": clientData.client.dob,
          "Telefoni:": clientData.client.phone,
          "Adresa:": clientData.client.address,
          "Qyteti:": clientData.client.city,
          "E-mail:": clientData.client.email,
        },
      ];
      XLSX.utils.sheet_add_json(ws, clientDetail);

      let cntServices = 0;
      const totalStdios = [];

      const serviceDetails = bookings.map((item) => {
        cntServices += 1;

        if (totalStdios.filter((i) => i === item.fields.staff.studio).length === 0)
          totalStdios.push(item.fields.staff.studio);
        return {
          Shërbimi: item.fields.service,
          Stafi: item.fields.staff.name,
          Komenti: item.fields.comment,
          Data: Moment(item.fields.booking_datetime).format("DD.MM.YYYY"),
          Studio: item.fields.staff.studio,
        };
      });

      XLSX.utils.sheet_add_json(ws, [], { origin: -1 });
      XLSX.utils.sheet_add_json(ws, [], { origin: -1 });
      XLSX.utils.sheet_add_json(ws, serviceDetails, { origin: -1 });

      const summaryData = [
        {
          "Shërbime të kryera": cntServices,
          "Klienti ka vizituar": totalStdios.length,
        },
      ];
      XLSX.utils.sheet_add_json(ws, [], { origin: -1 });
      XLSX.utils.sheet_add_json(ws, [], { origin: -1 });
      XLSX.utils.sheet_add_json(ws, summaryData, { origin: -1 });

      XLSX.writeFile(wb, `raporter - ${Moment(new Date()).format("DD.MM.YYYY")}.xls`);
      // const excelBuffer = XLSX.write(wb, {
      //   type: "buffer",
      //   cellStyles: true,
      // });

      // console.log("sdfasdfasdf", excelBuffer);
      // FileSaver.saveAs(excelBuffer, "sdfsdf.xlsx");
    }
  };

  return (
    <S.Card>
      <S.Conainer id="customer_report">
        <S.CloseBtnDiv>
          <MDTypography
            component="span"
            color="secondary"
            onClick={onClose}
            style={{ cursor: "pointer" }}
          >
            <Icon color="red">close</Icon>
          </MDTypography>
        </S.CloseBtnDiv>
        <S.HeaderContainer>
          <img src={logoImg} alt="logo" />
        </S.HeaderContainer>
        {clientData ? (
          <S.CustomerInfoArea>
            <S.CustomerInfoCell>
              <CustomLabels title="Emri & mbiemri:" fontSize={16} fontWeight={600} />
              <S.CustomerInfoCelData>
                {clientData.client.name} {clientData.client.surname}
              </S.CustomerInfoCelData>
            </S.CustomerInfoCell>
            <S.CustomerInfoCell>
              <CustomLabels title="Numri personal:" fontSize={16} fontWeight={600} />
              <S.CustomerInfoCelData>{clientData.client.personal_id}</S.CustomerInfoCelData>
            </S.CustomerInfoCell>
            <S.CustomerInfoCell>
              <CustomLabels title="Data e lindjës:" fontSize={16} fontWeight={600} />
              <S.CustomerInfoCelData>{clientData.client.dob}</S.CustomerInfoCelData>
            </S.CustomerInfoCell>
            <S.CustomerInfoCell>
              <CustomLabels title="Telefoni:" fontSize={16} fontWeight={600} />
              <S.CustomerInfoCelData>{clientData.client.phone}</S.CustomerInfoCelData>
            </S.CustomerInfoCell>
            <S.CustomerInfoCell>
              <CustomLabels title="Adresa:" fontSize={16} fontWeight={600} />
              <S.CustomerInfoCelData>{clientData.client.address}</S.CustomerInfoCelData>
            </S.CustomerInfoCell>
            <S.CustomerInfoCell>
              <CustomLabels title="Qyteti:" fontSize={16} fontWeight={600} />
              <S.CustomerInfoCelData>{clientData.client.city}</S.CustomerInfoCelData>
            </S.CustomerInfoCell>
            <S.CustomerInfoCell>
              <CustomLabels title="E-mail:" fontSize={16} fontWeight={600} />
              <S.CustomerInfoCelData>{clientData.client.email}</S.CustomerInfoCelData>
            </S.CustomerInfoCell>
          </S.CustomerInfoArea>
        ) : (
          <div style={{ paddingLeft: "20px", fontWeight: "bold" }}>
            Nuk ka shërbime deri më sot {Moment(new Date()).format("DD.MM.YYYY")}
          </div>
        )}
        <S.ServicesContainer>
          <S.GradientBg />
          <S.SearchFilters>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={4}>
                <CustomLabels title="Kërko sipas datave:" fontSize={16} fontWeight={600} />
                <Grid container spacing={1}>
                  <Grid item xs={6} md={6} lg={6}>
                    <div style={{ position: "relative", width: "100%" }}>
                      <IconButton
                        onClick={() => {
                          setShowBeginCalendar(!showBeginCalendar);
                          setShowEndCalendar(false);
                        }}
                        title={beginDate}
                        icon="calendar_month_icon"
                        imageSize="30px"
                        fontSize={16}
                        iconColor="#C20B7E"
                        textColor="gray"
                        contentPadding={10}
                        disableBorder
                        borderRadius={10}
                      />
                      {showBeginCalendar && (
                        <div style={{ width: "100%", position: "absolute", zIndex: 10 }}>
                          <CustomCalender
                            onChange={(value) => {
                              setBeginDate(Moment(value).format("DD.MM.YYYY"));
                              setShowBeginCalendar(false);
                            }}
                            useWeekdaysShort
                          />
                        </div>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={6} md={6} lg={6}>
                    <div style={{ position: "relative", width: "100%" }}>
                      <IconButton
                        onClick={() => {
                          setShowEndCalendar(!showEndCalendar);
                          setShowBeginCalendar(false);
                        }}
                        title={endDate}
                        icon="calendar_month_icon"
                        imageSize="30px"
                        fontSize={16}
                        iconColor="#C20B7E"
                        textColor="gray"
                        contentPadding={10}
                        disableBorder
                        borderRadius={10}
                      />
                      {showEndCalendar && (
                        <div style={{ width: "100%", position: "absolute", zIndex: 10 }}>
                          <CustomCalender
                            onChange={(value) => {
                              setEndDate(Moment(value).format("DD.MM.YYYY"));
                              setShowEndCalendar(false);
                            }}
                            useWeekdaysShort
                            minDate={
                              beginDate ? new Date(Moment(beginDate, "DD.MM.YYYY")) : new Date()
                            }
                          />
                        </div>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={2}>
                <CustomLabels title="Kërko sipas stafit:" fontSize={16} fontWeight={600} />
                <div style={{ width: "100%" }}>
                  {useMemo(
                    () => (
                      <S.Select
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable
                        isSearchable
                        placeholder="Zgjedh..."
                        onChange={(value) => setSelectedStaff(value ? value.value : null)}
                        options={initialStaffs}
                        style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
                      />
                    ),
                    [initialStaffs]
                  )}
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={2}>
                <CustomLabels title="Kërko sipas shërbimit:" fontSize={16} fontWeight={600} />
                <div style={{ width: "100%" }}>
                  {useMemo(
                    () => (
                      <S.Select
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable
                        isSearchable
                        placeholder="Zgjedh..."
                        onChange={(value) => setSelectedService(value ? value.value : null)}
                        options={initialService}
                        style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
                      />
                    ),
                    [initialService]
                  )}
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={2}>
                <CustomLabels title="Kërko sipas studio:" fontSize={16} fontWeight={600} />
                <div style={{ width: "100%" }}>
                  {useMemo(
                    () => (
                      <S.Select
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable
                        placeholder="Zgjedh..."
                        isSearchable
                        onChange={(value) => setSelectedStudio(value ? value.value : null)}
                        options={initialStudio}
                        style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
                      />
                    ),
                    [initialStudio]
                  )}
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={2} alignItems="bottom">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    height: "100%",
                  }}
                >
                  <S.Button
                    onClick={() => {
                      setApplyFilters(true);
                    }}
                  >
                    Kërko
                  </S.Button>
                </div>
              </Grid>
            </Grid>
          </S.SearchFilters>
          <S.ServicesContent>
            <S.SearchResult>
              <S.DataTable
                id="table"
                table={{ rows: dataRows, columns }}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={false}
                noEndBorder
              />
            </S.SearchResult>
            <S.SummaryInfos>
              <div style={{ display: "flex", alignItems: "center" }}>
                <CustomLabels title="Shërbime të kryera" fontSize={16} fontWeight={600} />
                <S.CustomerInfoCelData
                  borderRadius={10}
                  auto
                  style={{ paddingLeft: 40, paddingRight: 40, marginLeft: 40 }}
                >
                  {summary.totalServices}
                </S.CustomerInfoCelData>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <CustomLabels title="Klienti ka vizituar" fontSize={16} fontWeight={600} />
                <S.CustomerInfoCelData
                  borderRadius={10}
                  auto
                  style={{ paddingLeft: 40, paddingRight: 40, marginLeft: 40 }}
                >
                  {summary.totalStudios}
                </S.CustomerInfoCelData>
              </div>
              {/* <div style={{ display: "flex", alignItems: "center" }}>
                <CustomLabels title="Shuma totale në €" fontSize={16} fontWeight={600} />
                <S.CustomerInfoCelData
                  borderRadius={10}
                  auto
                  style={{ paddingLeft: 40, paddingRight: 40, marginLeft: 40 }}
                >
                  {summary.sum}€
                </S.CustomerInfoCelData>
              </div> */}
            </S.SummaryInfos>
          </S.ServicesContent>
        </S.ServicesContainer>
      </S.Conainer>
      <S.BottomContainer>
        <div
          style={{
            width: 350,
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            position: "relative",
          }}
        >
          <S.Select
            className="basic-single"
            classNamePrefix="select"
            isClearable
            isSearchable
            dropdown
            placeholder="Zgjedh..."
            menuPlacement="top"
            value={optionReports.filter((item) => item.value === reportFileType)[0]}
            onChange={(value) => setReportFileType(value ? value.value : null)}
            options={optionReports}
            style={{ backgroundColor: "rgba(0, 0, 0, 0.2)", marginRight: 10 }}
          />
          <div style={{ width: 10 }} />
          <S.Button onClick={handleClickedPrint} width={200}>
            Report
          </S.Button>
        </div>
      </S.BottomContainer>
    </S.Card>
  );
}

const S = {
  Card: styled(Card)`
    width: 90%;
    height: 90%;
    max-height: 1024px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-top: 20px;

    @media (max-width: 1024px) {
      width: 90%;
      height: 90%;
    }
  `,
  Conainer: styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
  `,
  CloseBtnDiv: styled.div`
    position: absolute;
    top: 0px;
    right: 20px;
  `,

  HeaderContainer: styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    img {
      width: 330px;
      height: 100px;
      @media (max-width: 768px) {
        width: 200px;
        height: 60px;
      }
    }
  `,
  // display: grid !important;
  //   grid-template-columns: repeat(7, 1fr) !important;
  // grid-gap: 10px !important;
  // @media (max-width: 1024px) {
  //   grid-template-columns: repeat(2, 1fr) !important;
  // }
  CustomerInfoArea: styled.div`
    margin-right: 20px !important;
    display: flex;
    flex-wrap: wrap;
    border-bottom: none !important;
    max-height: none !important;
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
  `,
  CustomerInfoCell: styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
  `,
  CustomerInfoCelData: styled.div`
    height: 40px;
    border-radius: ${(props) => (props.borderRadius ? `${props.borderRadius}px` : "40px")};
    background-color: rgba(0, 0, 0, 0.06);
    width: ${(props) => (props.auto ? "auto" : "100%")};
    font-size: 1rem;
    color: #C20B7E;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    white-space: nowrap;
    padding-left: 10px;
    padding-right: 10px;
}
  `,
  ServicesContainer: styled.div`
    position: relative;
    padding-top: 20px;
    flex: 1;
    min-height: 400px;
  `,

  SearchFilters: styled.div`
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  `,
  ServicesContent: styled.div`
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  `,
  BottomContainer: styled.div`
    height: 80px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
  `,
  GradientBg: styled.div`
    position: absolute;
    background: white;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.03) 20%, white 50%);
  `,
  Select: styled(Select)`
    flex: 1;
    min-height: 50px !important;
    border-radius: 10px;

    .select__control {
      box-shadow: unset;
      background-color: rgba(0, 0, 0, 0.06);
      border-radius: 10px;
      min-height: 50px !important;
      border: none;
      :hover {
        border: none;
      }
      :focuse {
        border: none;
      }
      :actived {
        border: none;
      }
    }
    .select__multi-value {
      border: 1px solid #c20b7e;
    }
    .select__indicator {
      color: #c20b7e !important;
    }
    .select__indicator-separator {
      display: none !important;
    }
  `,
  Button: styled(Button)`
    height: 50px !important;
    width: ${(props) => (props.width ? `${props.width}px` : "100%")} !important;
    border-radius: 10px !important;
    background-color: ${(props) => (props.dark ? "gray !important" : "#C20B7E !important")};
    text-transform: none !important;
    font-size: 20px !important;
    font-weight: 100 !important;
    color: white !important;
    margin-bottom: 2px;
    :hover {
      background-color: ${(props) => (props.dark ? "lightgray !important" : "#E560B3 !important")};
      color: white !important;
    }
  `,

  SearchResult: styled.div`
    margin-top: 10px;
  `,
  SummaryInfos: styled.div`
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  `,
  DataTable: styled(DataTable)`
    .MuiTable-root {
      thead {
        background-color: #c20b7e !important;
        color: white !important;
      }
    }
  `,
};

CustomerReporter.defaultProps = {};
CustomerReporter.propTypes = {
  onClose: PropTypes.func.isRequired,
  customerId: PropTypes.number.isRequired,
  isVisible: PropTypes.bool.isRequired,
};

export default CustomerReporter;
