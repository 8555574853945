import { useState } from "react";

import PropTypes from "prop-types";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { showAlert } from "actions/alert";
import { showLoading } from "actions/loading";

import { callServicePostAuth } from "services/AuthServices";
import Select from "react-select";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import InputAdornment from "@mui/material/InputAdornment";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import CustomLabels from "layouts/bookings/pages/components/CustomLabels";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LockIcon from "@mui/icons-material/Lock";
import EmailIcon from "@mui/icons-material/Email";
// import CustomCalender from "layouts/bookings/pages/components/CustomCalendars/CustomCalender";
// import IconButton from "layouts/bookings/pages/components/IconButton";
import config from "configs/config.json";
// import Moment from "moment/moment";

const TAG = "CreateEditUsers";

function CreateEditUsers({ selectedItem, studios, onClose }) {
  const [name, setName] = useState(selectedItem ? selectedItem.username : "");
  const [phone, setPhone] = useState(selectedItem ? selectedItem.phone : "");
  const [password, setPassword] = useState("");
  const [address, setAddress] = useState(selectedItem ? selectedItem.address : "");
  const [email, setEmail] = useState(selectedItem ? selectedItem.email : "");
  const [role, setRole] = useState(selectedItem ? selectedItem.role : 2);
  const [selectedStudio, setSelectedStudio] = useState(selectedItem ? selectedItem.studio_id : 0);

  const dispatch = useDispatch();

  console.log(TAG, dispatch);

  const handleAddOrEditUserResponse = async (res, isErr) => {
    dispatch(showLoading(false));
    if (isErr) {
      dispatch(showAlert({ type: "error", msg: res }));
      return;
    }

    if (res.result !== "success") {
      dispatch(showAlert({ type: "error", msg: res.msg }));
      return;
    }
    onClose();
  };
  const callApiAddOrEditUser = async (isAdd) => {
    if (!name || !phone || !address || !email || !selectedStudio) {
      dispatch(showAlert({ type: "error", msg: "Ju lutemi plotësoni fushat." }));
      return;
    }

    let url = "";
    const postData = {
      name,
      phone,
      password,
      address,
      email,
      role,
      studio: selectedStudio,
    };
    if (!isAdd) {
      url = config.BASE_URL + config.UPDATE_USER;
      postData.id = selectedItem.id;
    } else {
      url = config.BASE_URL + config.ADD_NEW_USER;
    }

    console.log(TAG, "callApiAddOrEditUser url = ", url);
    dispatch(showLoading(true));
    callServicePostAuth(url, postData, handleAddOrEditUserResponse);
  };

  const handleClickedRegister = () => {
    callApiAddOrEditUser(!selectedItem);
  };

  return (
    <S.Card>
      <S.Header>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          Përdoruesit
        </div>

        <S.CloseBtnContainer>
          <Button
            aria-label="delete"
            size="large"
            onClick={onClose}
            width={20}
            height={20}
            style={{ width: "20px", height: "20px", borderRadius: "20px", padding: 0, margin: 0 }}
          >
            <CloseIcon fontSize="large" color="white" />
          </Button>
        </S.CloseBtnContainer>
      </S.Header>
      <S.Container>
        <Grid container spacing={3} height="100%">
          <Grid item xs={12} md={12} lg={12}>
            <CustomLabels title="Emri & mbiemri:" fontSize={16} fontWeight={600} important />
            <S.NoLabelTextField
              variant="filled"
              label={null}
              value={name}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    color="#C20B7E"
                    size="large"
                    style={{ color: "#C20B7E", marginTop: 0, fontSize: 30 }}
                  >
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
              onChange={(val) => {
                setName(val.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <CustomLabels title="Fjalëkalimi:" fontSize={16} fontWeight={600} />
            <S.NoLabelTextField
              variant="filled"
              label={null}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    color="#C20B7E"
                    size="large"
                    style={{ color: "#C20B7E", marginTop: 0, fontSize: 30 }}
                  >
                    <LockIcon />
                  </InputAdornment>
                ),
              }}
              type="password"
              placeholder="********"
              value={password}
              onChange={(val) => {
                setPassword(val.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <CustomLabels title="Telefoni:" fontSize={16} fontWeight={600} important />

            <S.NoLabelTextField
              variant="filled"
              label={null}
              value={phone}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    color="#C20B7E"
                    size="large"
                    style={{ color: "#C20B7E", marginTop: 0, fontSize: 30 }}
                  >
                    <PhoneAndroidIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(val) => {
                setPhone(val.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <CustomLabels title="Adresa:" fontSize={16} fontWeight={600} important />
            <S.NoLabelTextField
              variant="filled"
              label={null}
              value={address}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    color="#C20B7E"
                    size="large"
                    style={{ color: "#C20B7E", marginTop: 0, fontSize: 30 }}
                  >
                    <LocationOnIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(val) => {
                setAddress(val.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <CustomLabels title="E-mail:" fontSize={16} fontWeight={600} important />
            <S.NoLabelTextField
              variant="filled"
              label={null}
              value={email}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    color="#C20B7E"
                    size="large"
                    style={{ color: "#C20B7E", marginTop: 0, fontSize: 30 }}
                  >
                    <EmailIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(val) => {
                setEmail(val.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <CustomLabels title="Studio:" fontSize={16} fontWeight={600} important />
            {/* <S.NoLabelTextField
              variant="filled"
              label={null}
              value={salaryFixed}
              onChange={(val) => {
                setSalaryFixed(val.target.value);
              }}
            /> */}
            <S.SelectFilter
              className="basic-single"
              classNamePrefix="select"
              isClearable
              isSearchable
              dropdown
              value={studios.filter((item) => item.id === selectedStudio)[0]}
              onChange={(value) => setSelectedStudio(value ? value.id : null)}
              options={studios}
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.2)",
                marginLeft: 10,
                height: "50px !important",
                fontSize: "1rem",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <S.Button
              onClick={() => setRole(1)}
              dark
              startIcon={
                role === 1 ? (
                  <div
                    style={{ width: 10, height: 10, borderRadius: 10, backgroundColor: "#C20B7E" }}
                  />
                ) : (
                  <div
                    style={{
                      width: 10,
                      height: 10,
                      borderRadius: 10,
                      backgroundColor: "transparent",
                      borderColor: "darkgray",
                      borderWidth: 1,
                    }}
                  />
                )
              }
            >
              Administrator
            </S.Button>
          </Grid>
          <Grid item xs={12} md={6} lg={6} display="flex" justifyContent="flex-end">
            <S.Button
              onClick={() => setRole(2)}
              dark
              startIcon={
                role === 2 ? (
                  <div
                    style={{ width: 10, height: 10, borderRadius: 10, backgroundColor: "#C20B7E" }}
                  />
                ) : (
                  <div
                    style={{
                      width: 10,
                      height: 10,
                      borderRadius: 10,
                      backgroundColor: "transparent",
                      borderColor: "darkgray",
                      borderStyle: "solid",
                      borderWidth: 1,
                    }}
                  />
                )
              }
            >
              Shfrytëzues
            </S.Button>
          </Grid>
        </Grid>
      </S.Container>
      <S.Bottom>
        <S.Button onClick={() => handleClickedRegister()} width={200}>
          {selectedItem ? "Ndrysho" : "Krijo"}
        </S.Button>
      </S.Bottom>
    </S.Card>
  );
}

const S = {
  Card: styled(Card)`
    width: 40%;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 90%;
    @media (max-width: 1024px) {
      width: 90%;
      height: 90%;
    }
  `,
  Header: styled.div`
    height: 50px;
    color: white;
    background-color: #c20b7e;
    font-size: 1.5rem;
    font-weight: 400;
    width: 100%;
    position: relative;

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  `,
  CloseBtnContainer: styled.div`
    position: absolute;
    right: 10px;
    top: 2px;
    color: white;
  `,
  Container: styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    overflow: auto;
    @media (max-width: 1024px) {
      padding: 40px 20px;
    }
    @media (max-width: 768px) {
      padding: 20px;
    }
  `,
  Bottom: styled.div`
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 10px;
  `,
  Button: styled(Button)`
    height: ${(props) => (props.height ? `${props.height}px` : "50px")} !important;
    width: ${(props) => (props.width ? `${props.width}px` : "100%")} !important;
    border-radius: 10px !important;
    background-color: ${(props) => (props.dark ? "#f0f2f5 !important" : "#C20B7E !important")};
    text-transform: none !important;
    font-size: 1rem !important;
    font-weight: ${(props) => (props.dark ? "600 !important" : "100 !important;")};
    color: ${(props) => (props.dark ? "darkgray !important" : "white !important;")};
    margin-bottom: 2px;
    :hover {
      background-color: ${(props) => (props.dark ? "lightgray !important" : "#E560B3 !important")};
      color: white !important;
    }
  `,
  NoLabelTextField: styled(TextField)`
    width: 100%;
    .MuiFilledInput-root {
      padding: 5px !important;
    }
    input {
      padding: 0 !important;
      height: 40px;
    }
  `,

  SelectFilter: styled(Select)`
    flex: 1;

    border-radius: 0px;
    font-size: 1rem !important;

    .select__control {
      box-shadow: unset;
      background-color: rgba(0, 0, 0, 0.06);
      border-radius: 0px;
      min-height: 50px !important;
      font-size: 1rem;
      border: none;
      border-bottom: solid 1px #000000de;
      :hover {
        border: none;
        border-bottom: solid 1px #000000de;
      }
      :focuse {
        border: none;
      }
      :actived {
        border: none;
      }
    }
    .select__multi-value {
      border: 1px solid #c20b7e;
    }
    .select__indicator {
      color: #c20b7e !important;
    }
    .select__indicator-separator {
      display: none !important;
    }
  `,
};

CreateEditUsers.defaultProps = {
  selectedItem: undefined,
  studios: [],
};
CreateEditUsers.propTypes = {
  selectedItem: PropTypes.instanceOf(Object),
  onClose: PropTypes.func.isRequired,
  studios: PropTypes.instanceOf(Array),
};

export default CreateEditUsers;
