import { SET_LOADING, CLEAR_LOADING } from "./types";

export const showLoading = (isVisibleLoading) => ({
  type: SET_LOADING,
  payload: isVisibleLoading,
});

export const clearLoading = () => ({
  type: CLEAR_LOADING,
});
