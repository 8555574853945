import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import styled from "styled-components";
import CustomLabels from "layouts/bookings/pages/components/CustomLabels";
import TimeButtons from "layouts/bookings/pages/components/TimeButtons";
import Calendar from "react-calendar";
import { Button } from "@mui/material";
import Icon from "@mui/material/Icon";
import { useRef, useLayoutEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import Moment from "moment/moment";
import config from "configs/config.json";
import "./index.css";
import IconButton from "layouts/bookings/pages/components/IconButton";
import { callServicePostAuth, callServiceGetAuth } from "services/AuthServices";
import "react-phone-number-input/style.css";
// import e from "express";
// import Input, { isPossiblePhoneNumber } from "react-phone-number-input/input";

// import Calendar from "moedim";

const TAG = "NewBookingPage";
const convertData2Option = (data) =>
  data.map((item) => ({
    value: item.id,
    label: item.name,
  }));
const convertData2Option2 = (data) =>
  data.map((item) => ({
    value: item.pk,
    label: item.fields ? item.fields.name : item.name,
  }));

function NewBookingPage({ allServices, allStaffs, alertDispatch, showAlert, editBookingData }) {
  console.log("editBookingData = ", editBookingData);
  const playIconRef = useRef(null);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [openCalendarForDob, setOpenCalendarForDob] = useState(false);
  const [openTimeBtnPanel, setOpenTimeBtnPanel] = useState(false);

  // client infos
  const [clientName, setClientName] = useState(editBookingData ? editBookingData.client.name : "");
  const [clientSurName, setClientSurName] = useState(
    editBookingData ? editBookingData.client.surname : ""
  );
  const [phone, setPhone] = useState(editBookingData ? editBookingData.client.phone : "");
  const [email, setEmail] = useState(editBookingData ? editBookingData.client.email : "");
  const [dob, setDob] = useState(
    editBookingData ? Moment(editBookingData.client.dob).format("DD.MM.YYYY") : ""
  );
  const [identify, setIdentify] = useState(
    editBookingData ? editBookingData.client.personal_id : ""
  );
  const [city, setCity] = useState(editBookingData ? editBookingData.client.city : "");
  const [address, setAddress] = useState(editBookingData ? editBookingData.client.address : "");

  // booking infos
  const [selectedService, setSelectedService] = useState(
    editBookingData ? convertData2Option2(editBookingData.services[0]) : []
  );
  const [selectedStaff, setStaff] = useState(
    editBookingData ? convertData2Option2([editBookingData.staff])[0] : null
  );
  const [comment, setComment] = useState(editBookingData ? editBookingData.comment : "");
  const [bookingDate, setBookingDate] = useState(
    editBookingData ? Moment(editBookingData.booking_datetime).format("DD.MM.YYYY") : ""
  );
  const [selectedTime, setTime] = useState(0);
  const [selectedTime2, setTime2] = useState(0);
  const [isNewBooking, setIsNewBooking] = useState(true);

  console.log(selectedStaff, selectedService);

  useState(() => {
    if (editBookingData) setIsNewBooking(false);
  }, [editBookingData]);

  useLayoutEffect(() => {
    playIconRef.current.style.setProperty("font-size", "30px", "important");
  }, []);

  const convertNumber2Str = (num, len) => {
    let tmpRes = num.toFixed();
    while (tmpRes.length < len) {
      tmpRes = `0${tmpRes}`;
    }
    return tmpRes;
  };

  const getIdListFromData = (data) => data.map((item) => item.value).join(" | ");

  const handleClickClearBtn = () => {
    // client infos
    setClientName("");
    setClientSurName("");
    setPhone("");
    setEmail("");
    setDob("");
    setIdentify("");
    setCity("");
    setAddress("");

    // booking infos
    setSelectedService([]);
    setStaff(null);
    setComment("");
    setBookingDate("");
    setTime(0);
    setTime2(0);
  };

  const handleBookingResponse = async (res, isError) => {
    if (isError) {
      alertDispatch(showAlert({ type: "error", msg: res }));
      return;
    }

    alertDispatch(showAlert({ type: "success", msg: "Rezervimi u përfundua me sukses." }));
    handleClickClearBtn();
    setIsNewBooking(true);
  };
  const callApiToBook = async () => {
    const url = config.BASE_URL + config.NEW_BOOKING;
    console.log(TAG, "callApiToBook url = ", dob, url, getIdListFromData(selectedService));
    const dob2 = Moment(dob, "DD.MM.YYYY").format("YYYY-MM-DD");
    // const bookingdate2 = Moment(bookingDate, "DD.MM.YYYY").format("YYYY-MM-DD");
    const bookingDatetime = Moment(bookingDate, "DD.MM.YYYY")
      .set("hour", selectedTime)
      .set("minute", selectedTime2)
      .format("YYYY-MM-DD HH:mm");

    // console.log(bookingDatetime.format("YYYY-MM-DD"), bookingdate2, dob2, bookingDate, dob);
    const postData = {
      // client infos
      clientName,
      clientSurName,
      phone,
      email,
      dob: dob2,
      identify,
      city,
      address,
      // booking infos
      services: getIdListFromData(selectedService),
      staff: selectedStaff.value,
      comment,
      bookingDatetime,
    };
    console.log(TAG, "callApiToBook data = ", postData);

    callServicePostAuth(url, postData, handleBookingResponse);
  };
  const callApiToUpdateBook = () => {
    const url = config.BASE_URL + config.NEW_BOOKING;
    console.log(
      TAG,
      "callApiToUpdateBook url = ",
      dob,
      url,
      getIdListFromData(selectedService),
      selectedService
    );
    const dob2 = Moment(dob, "DD.MM.YYYY").format("YYYY-MM-DD");
    const bookingDatetime = Moment(bookingDate, "DD.MM.YYYY")
      .set("hour", selectedTime)
      .set("minute", selectedTime2)
      .format("YYYY-MM-DD HH:mm");

    // console.log(bookingDatetime.format("YYYY-MM-DD"), bookingdate2, dob2, bookingDate, dob);
    const postData = {
      bookedId: editBookingData.id,
      client_id: editBookingData.client.pk,
      oldServices: editBookingData.services.map((item) => ({
        serviceId: item.pk,
        bookedId: item.bookedId,
      })),
      clientName,
      clientSurName,
      phone,
      email,
      dob: dob2,
      identify,
      city,
      address,
      // booking infos
      services: getIdListFromData(selectedService),
      staff: selectedStaff.value,
      comment,
      bookingDatetime,
    };
    console.log(TAG, "callApiToBook data = ", postData);

    callServicePostAuth(url, postData, handleBookingResponse);
  };
  const handleClickSaveBtn = () => {
    if (
      !clientName ||
      clientName === "" ||
      !clientSurName ||
      clientSurName === "" ||
      !phone ||
      phone === "" ||
      selectedService.length === 0 ||
      selectedStaff === null
    ) {
      if (alertDispatch && showAlert)
        alertDispatch(showAlert({ type: "error", msg: "Ju lutemi plotësoni fushat." }));
      return;
    }

    if (isNewBooking) callApiToBook();
    else {
      if (selectedTime === 0 && alertDispatch && showAlert) {
        alertDispatch(showAlert({ type: "error", msg: "Ju lutemi plotësoni fushat." }));
        return;
      }
      callApiToUpdateBook();
    }
  };

  const handleCustomerResponse = async (res, isError) => {
    if (isError) {
      return;
    }

    if (res.length > 0) {
      setClientName(res[0].name);
      setClientSurName(res[0].surname);
      setEmail(res[0].email);
      setDob(Moment(res[0].dob).format("DD.MM.YYYY"));
      setIdentify(res[0].personal_id);
      setCity(res[0].city);
      setAddress(res[0].address);
    }
  };

  const callFindCustomerByPhone = async (value) => {
    const url = `${config.BASE_URL}${config.GET_ALL_CUSTOMERS}?phone=${value}`;
    callServiceGetAuth(url, handleCustomerResponse);
  };

  // const handleChangedPhone = (value) => {
  //   if (value && isPossiblePhoneNumber(value)) {
  //     console.log("search by phone", value);
  //     callFindCustomerByPhone(value);
  //   }
  //   setPhone(value);
  // };
  return (
    <S.MainContainer>
      <Grid container spacing={3} style={{ flex: 1 }}>
        <Grid item xs={12} md={6} lg={6}>
          <MDBox>
            <S.TitleContainer>Të dhenat e klientit</S.TitleContainer>
          </MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6} mt={1}>
              <CustomLabels fontSize={15} title="Emri:" important />
              <S.NoLabelTextField
                variant="filled"
                label={null}
                value={clientName}
                onChange={(val) => {
                  setClientName(val.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6} mt={1}>
              <CustomLabels fontSize={15} title="Mbiemri:" important />
              <S.NoLabelTextField
                variant="filled"
                label={null}
                value={clientSurName}
                onChange={(val) => setClientSurName(val.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6} mt={1}>
              <CustomLabels fontSize={15} title="Telefoni:" important />
              {/* <S.PhoneInput
                country="AL"
                placeholder="Telefoni"
                value={phone}
                onChange={(val) => handleChangedPhone(val)}
              /> */}
              <S.NoLabelTextField
                variant="filled"
                label={null}
                value={phone}
                onChange={(val) => {
                  if (val.target.value && val.target.value.length > 4)
                    callFindCustomerByPhone(val.target.value);
                  setPhone(val.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6} mt={1}>
              <CustomLabels fontSize={15} title="E-mail:" />
              <S.NoLabelTextField
                variant="filled"
                label={null}
                value={email}
                onChange={(val) => setEmail(val.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6} mt={1}>
              {/* <CustomLabels title="Data e lindjës:" />
              <S.NoLabelTextField
                variant="filled"
                label={null}
                value={dob}
                onChange={(val) => setDob(val.target.value)}
              /> */}
              <div style={{ position: "relative", width: "100%" }}>
                <div style={{ width: "100%" }}>
                  <CustomLabels fontSize={15} title="Data e lindjës:" />
                  <IconButton
                    onClick={() => setOpenCalendarForDob(!openCalendarForDob)}
                    title={dob}
                    icon="calendar_month_icon"
                    imageSize="20px"
                    fontSize={14}
                    iconColor="#C20B7E"
                    textColor="gray"
                  />
                </div>
                {openCalendarForDob && (
                  <div style={{ position: "relative" }}>
                    <div style={{ position: "absolute" }}>
                      <Calendar
                        useWeekdaysShort
                        onChange={(value) => {
                          setOpenCalendarForDob(false);
                          setDob(Moment(value).format("DD.MM.YYYY"));
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6} mt={1}>
              <CustomLabels fontSize={15} title="Numri personal:" />
              <S.NoLabelTextField
                variant="filled"
                label={null}
                value={identify}
                onChange={(val) => setIdentify(val.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6} mt={1}>
              <CustomLabels fontSize={15} title="Qyteti:" />
              <S.NoLabelTextField
                variant="filled"
                label={null}
                value={city}
                onChange={(val) => setCity(val.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6} mt={1}>
              <CustomLabels fontSize={15} title="Adresa:" />
              <S.NoLabelTextField
                variant="filled"
                label={null}
                value={address}
                onChange={(val) => setAddress(val.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <MDBox>
            <S.TitleContainer>Të dhenat e rezervimit</S.TitleContainer>
          </MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12} mt={1}>
              <CustomLabels fontSize={15} title="Sherbimi :" important />
              {/* <S.NoLabelTextField variant="filled" label={null} borderRadius={10} /> */}
              <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                {/* <S.NoLabelTextField variant="filled" label={null} /> */}
                <S.Select
                  className="basic-single"
                  classNamePrefix="select"
                  isMulti
                  placeholder="Zgjedh..."
                  isClearable
                  isSearchable
                  value={selectedService}
                  onChange={(value) => setSelectedService(value)}
                  options={convertData2Option(allServices)}
                  style={{ height: 50 }}
                />
                {/* <S.PlayIcon
                  ref={playIconRef}
                  fontSize="large"
                  style={{ color: "#C20B7E", fontSize: "30px !important" }}
                >
                  play_circle_outlined_icon
                </S.PlayIcon> */}
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6} mt={1}>
              <CustomLabels fontSize={15} title="Stafi:" important />
              {/* <S.NoLabelTextField variant="filled" label={null} /> */}
              <S.Select
                className="basic-single"
                classNamePrefix="select"
                isClearable
                placeholder="Zgjedh..."
                isSearchable
                value={selectedStaff}
                onChange={(value) => setStaff(value)}
                options={convertData2Option(allStaffs)}
                style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
              />
            </Grid>
            {/* <Grid item xs={12} md={6} lg={6} mt={1}>
              <CustomLabels title="Add Shërbimi:" />
              <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                
                <S.Select
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable
                  isSearchable
                  isMulti
                  options={convertData2Option(allServices)}
                />
                <S.PlayIcon
                  ref={playIconRef}
                  fontSize="large"
                  style={{ color: "#C20B7E", fontSize: "30px !important" }}
                >
                  play_circle_outlined_icon
                </S.PlayIcon>
              </div>
            </Grid> */}
            <Grid item xs={12} md={6} lg={6} mt={1}>
              <CustomLabels fontSize={15} title="Komenti:" />
              <S.NoLabelTextField
                variant="filled"
                label={null}
                value={comment}
                onChange={(val) => setComment(val.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6} mt={1}>
              <div style={{ position: "relative", width: "100%" }}>
                <div style={{ width: "100%" }}>
                  <CustomLabels fontSize={15} title="Data:" />
                  {/* <S.NoLabelTextField variant="filled" label={null} /> */}
                  {/* import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'; */}

                  <IconButton
                    onClick={() => setOpenCalendar(!openCalendar)}
                    title={bookingDate}
                    icon="calendar_month_icon"
                    imageSize="20px"
                    fontSize={14}
                    iconColor="#C20B7E"
                    textColor="gray"
                  />
                </div>
                {openCalendar && (
                  <div style={{ position: "relative" }}>
                    <div style={{ position: "absolute" }}>
                      <Calendar
                        useWeekdaysShort
                        minDate={new Date()}
                        onChange={(value) => {
                          setBookingDate(Moment(value).format("DD.MM.YYYY"));
                          setOpenCalendar(!openCalendar);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6} mt={1}>
              {/* <CustomLabels title="Ora:" />
              <S.NoLabelTextField variant="filled" label={null} /> */}
              <div style={{ position: "relative", width: "100%" }}>
                <div style={{ width: "100%" }}>
                  <CustomLabels fontSize={15} title="Time:" />
                  {/* <S.NoLabelTextField variant="filled" label={null} /> */}
                  {/* import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'; */}
                  <IconButton
                    onClick={() => setOpenTimeBtnPanel(!openTimeBtnPanel)}
                    title={`${convertNumber2Str(selectedTime, 2)}:${convertNumber2Str(
                      selectedTime2,
                      2
                    )}`}
                    icon="access_time_icon"
                    imageSize="20px"
                    fontSize={14}
                    iconColor="#C20B7E"
                    textColor="gray"
                  />
                </div>
                {openTimeBtnPanel && (
                  <div style={{ position: "absolute", left: 0, zIndex: 10, width: "100%" }}>
                    <TimeButtons
                      onSelectedHourItem={(hours) => {
                        setTime(hours);
                        setOpenTimeBtnPanel(false);
                      }}
                      onSelectedMinsItem={(mins) => {
                        setTime2(mins);
                        setOpenTimeBtnPanel(false);
                      }}
                    />
                  </div>
                )}
              </div>
            </Grid>
            {/* <Grid item xs={12} md={6} lg={6} mt={1} display="flex" justifyContent="center"> */}
            {/* <Calendar useWeekdaysShort /> */}
            {/* <S.Calendar /> */}
            {/* </Grid> */}
            {/* <Grid item xs={12} md={6} lg={6} mt={1}>
              <TimeButtons
                onSelectedHourItem={(data) => console.log(data)}
                onSelectedMinsItem={(mins) => console.log("mins", mins)}
              />
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
      <S.BottomContainer>
        <S.BookButton onClick={handleClickSaveBtn}>
          Rezervo{" "}
          <S.PlayIcon
            ref={playIconRef}
            fontSize="large"
            style={{ color: "white", marginLeft: 30, fontSize: "30px !important" }}
          >
            play_circle_outlined_icon
          </S.PlayIcon>
        </S.BookButton>

        <div style={{ position: "absolute", left: 10 }}>
          <S.ClearButton onClick={handleClickClearBtn}>
            Pastro
            <div
              style={{
                width: 25,
                height: 25,
                borderRadius: 25,
                marginLeft: 20,
                backgroundColor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                right: 10,
              }}
            >
              <S.TrashIcon fontSize="30px !important" style={{ color: "gray" }}>
                delete_outline_outlined_icon
              </S.TrashIcon>
            </div>
          </S.ClearButton>
        </div>
      </S.BottomContainer>
    </S.MainContainer>
  );
}

NewBookingPage.propTypes = {
  allServices: PropTypes.instanceOf(Array).isRequired,
  allStaffs: PropTypes.instanceOf(Array).isRequired,
  alertDispatch: PropTypes.func.isRequired,
  showAlert: PropTypes.func.isRequired,
  editBookingData: PropTypes.instanceOf(Object).isRequired,
};

const S = {
  MainContainer: styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
  `,
  TitleContainer: styled.div`
    color: #C20B7E;
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    padding: 10px;
  `,
  RowContainer: styled.div`
    display: flex;
    width: 100%;
    justify-content: space-around;
    flex-wrap: wrap;
  `,
  NoLabelTextField: styled(TextField)`
    width: 100%;
    .MuiFilledInput-root {
      padding: 5px !important;
    }
    input {
      padding: 0 !important;
      height: 40px;
      font-size: 13px;
    }
  `,
  BottomContainer: styled.div`
    width: 100%;
    min-height: 80px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    flex-direction: row;
    @media (max-width: 1024px) {
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center;
    }
  `,
  Calendar: styled(Calendar)`
    --moedim-primary: #C20B7E;
  `,
  ClearButton: styled(Button)`
    position: relative !important;
    width: 150px !important;
    height: 40px !important;
    border-radius: 30px !important;
    background-color: gray !important;
    text-transform: none !important;
    font-size: 15px !important;
    font-weight: 100 !important;
    color: white !important;
    :hover {
      background-color: lightgray;
      color: white !important;
    }
    left: 50px !important;

    @media (max-width: 1024px) {
      position: inherit !important;
      left: 0 !important;
      bottom: 0 !important;
      margin-top: 10px !important;
      width: 300px !important;
      height: 60px !important;
      left: 30px !important;
    }
  `,

  BookButton: styled(Button)`
    width: 260px !important;
    height: 60px !important;
    border-radius: 30px !important;
    background-color: #C20B7E !important;
    text-transform: none;
    position: relative;
    font-size: 16px !important;
    font-weight: 600 !important;
    color: white !important;
    :hover {
      background-color: #90085d !important;
      color: white !important;
    }
  `,
  TrashIcon: styled(Icon)`
    span {
      font-size: 16px !important;
    }
  `,
  PlayIcon: styled(Icon)`
    position: absolute;
    right: 30px;
    font-size: 30px !important;
    span {
      font-size: 30px !important;
    }

    .material-icons-round {
      font-size: 30px !important;
    }
  `,
  Select: styled(Select)`
    flex: 1;
    min-height: 50px !important;
    .select__control {
      background-color: rgba(0, 0, 0, 0.06);
      font-size: 14px;
      height: 50px;
    }
    .select__multi-value {
      border: 1px solid #C20B7E;
      font-size: 14px;
    }
  `,

  // PhoneInput: styled(PhoneInput)`
  // PhoneInput: styled(Input)`
  //   padding: 10px;
  //   width: 100%;
  //   min-height: 50px !important;
  //   background-color: rgba(0, 0, 0, 0.06);
  //   font-size: 14px;
  //   border-top-left-radius: 5px !important;
  //   border-top-right-radius: 5px !important;
  //   border: none;
  //   border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  //   :focus-visible {
  //     border-top-left-radius: 5px !important;
  //     border-top-right-radius: 5px !important;
  //     border: none !important;
  //     outline: none !important;
  //     border-bottom: solid 2px #C20B7E !important;
  //   }

  //   :focus-visible {
  //     border-radius: 5px !important;
  //     border: none !important;
  //     outline: none !important;
  //   }
  // `,
};

export default NewBookingPage;
