import Calendar from "react-calendar";
import PropTypes from "prop-types";
import "./index.css";

function CustomCalender({ onChange, ...rest }) {
  return (
    <Calendar useWeekdaysShort onChange={(value) => onChange(value)} {...rest} locale="sq-AL" />
  );
}

CustomCalender.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default CustomCalender;
