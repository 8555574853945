import { useState, useEffect } from "react";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import { FormControlLabel } from "@material-ui/core";
import MDBox from "components/MDBox";
// import Checkbox from "@mui/material/Checkbox";
import config from "configs/config.json";
import { useDispatch } from "react-redux";
import { showAlert } from "actions/alert";
import { showLoading } from "actions/loading";
import { callServiceGetAuth, callServicePostAuth } from "services/AuthServices";
import Card from "@mui/material/Card";
// import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import styled from "styled-components";
import Icon from "@mui/material/Icon";
import Select from "react-select";
import DataTable from "components/DataTable";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import logoImg from "assets/images/main-logo-color.png";
import CreateEditService from "./createeditservice";

const TAG = "SalesReport";

// const optionReports = [
//   { value: "pdf", label: "PDF" },
//   { value: "xls", label: "XLS" },
// ];

const columns = [
  { Header: "Emri ", accessor: "name", align: "center", fontSize: "1rem" },
  // { Header: "Price (€)", accessor: "price", align: "center", fontSize: "1rem" },
  { Header: "KOHA E SHËRBIMIT (mins)", accessor: "servicetime", align: "center", fontSize: "1rem" },
  {
    Header: "Edito",
    accessor: "edit",
    align: "center",
    fontSize: "1rem",
    width: 30,
    padding: 0,
    px: 1,
  },
  {
    Header: "Fshij",
    accessor: "delete",
    align: "center",
    fontSize: 20,
    width: 30,
    padding: 0,
    px: 1,
  },
];

function Services() {
  const [dataRows, setDataRows] = useState([]);

  const [initialServices, setInitialServices] = useState();

  const dispatch = useDispatch();
  // const [reportFileType, setReportFileType] = useState("pdf");

  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  const [deleteDlgOpen, setDeleteDlgOpen] = useState(false);
  const [selectedId2Delete, setSelectedId2Delete] = useState();

  const handleGetAllServicesResponse = async (res, isErr) => {
    dispatch(showLoading(false));
    if (isErr) {
      dispatch(showAlert({ type: "error", msg: res }));
      return;
    }

    console.log("data", res);

    if (res) {
      setInitialServices(res);
    }
  };

  const callAPIGetAllServices = async () => {
    const url = config.BASE_URL + config.GET_ALL_SERVICE;
    console.log(TAG, "callAPIGetAllServices url = ", url);

    dispatch(showLoading(true));

    callServiceGetAuth(url, handleGetAllServicesResponse);
  };
  const renderEditBtn = ({ id, onClicked }) => (
    <div
      onClick={() => onClicked(id)}
      onKeyDown={() => onClicked(id)}
      role="button"
      tabIndex="0"
      style={{
        width: 20,
        height: 20,
        cursor: "pointer",
      }}
    >
      <Icon fontSize="medium">edit_note</Icon>
    </div>
  );

  const renderDeleteBtn = ({ id, onClicked }) => (
    <div
      onClick={() => onClicked(id)}
      onKeyDown={() => onClicked(id)}
      role="button"
      tabIndex="0"
      style={{
        borderRadius: 5,
        width: 20,
        height: 20,
        cursor: "pointer",
      }}
    >
      <Icon fontSize="medium">delete_outline_icon</Icon>
    </div>
  );

  const callbackEditBtn = (id) => {
    const service = initialServices.filter((item) => item.id === id);

    console.log("edit", id, service[0]);
    setSelectedService(service[0]);
    setOpenEditModal(true);
  };

  const handleDeleteServiceResponse = async (res, isErr) => {
    dispatch(showLoading(false));
    if (isErr) {
      dispatch(showAlert({ type: "error", msg: res }));
      return;
    }

    if (res.result === "failed") {
      dispatch(showAlert({ type: "error", msg: res }));
    }

    callAPIGetAllServices();
    setDeleteDlgOpen(false);
    setSelectedId2Delete();
  };

  const callApiDeleteService = async () => {
    const url = config.BASE_URL + config.DELETE_SERVICE;

    const postData = {
      id: selectedId2Delete,
    };

    dispatch(showLoading(true));
    callServicePostAuth(url, postData, handleDeleteServiceResponse);
  };

  const handleClickDeleteBtn = () => {
    callApiDeleteService();
  };

  const callbackDeleteBtn = (id) => {
    // callApiDeleteService(id);
    setDeleteDlgOpen(true);
    setSelectedId2Delete(id);
  };

  //
  useEffect(() => {
    if (!initialServices) callAPIGetAllServices();
  }, []);

  useEffect(() => {
    if (initialServices && initialServices.length > 0) {
      let rows = initialServices.map((item) => {
        if (!item) return null;
        const row = {};
        row.name = item.name;
        // row.price = item.price;
        row.servicetime = item.service_time;
        row.edit = renderEditBtn({ id: item.id, onClicked: callbackEditBtn });
        row.delete = renderDeleteBtn({ id: item.id, onClicked: callbackDeleteBtn });

        return row;
      });
      rows = rows.filter((item) => item != null);
      console.log(rows);
      setDataRows(rows);
    }
    //   }, [initialServices, visibleMode, selectedStudioFilter]);
  }, [initialServices]);

  const handleCloseEditModal = () => {
    callAPIGetAllServices();
    setOpenEditModal(false);
  };

  const handleCloseDlg = () => {
    setDeleteDlgOpen(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Dialog
        open={deleteDlgOpen}
        onClose={handleCloseDlg}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Konfirmo fshirjen</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Jeni i sigurt që dëshironi ta fshini këtë? Ky veprim nuk mund të zhbëhet.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDlg}>Anulo</Button>
          <S.Button
            variant="contained"
            color="success"
            onClick={handleClickDeleteBtn}
            autoFocus
            width={100}
          >
            Fshije
          </S.Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={openEditModal}
        onClose={handleCloseEditModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CreateEditService selectedItem={selectedService} onClose={handleCloseEditModal} />
      </Modal>
      <MDBox
        pt={1}
        pb={1}
        px={1}
        style={{
          minHeight: "80vh",
          height: "calc(100% - 80px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "auto",
        }}
      >
        <S.Card>
          <S.HeaderContainer>
            <img src={logoImg} alt="logo" />
            <div
              style={{
                width: "100%",
                color: "#C20B7E",
                marginTop: 20,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span style={{ color: "#C20B7E", marginTop: 20 }}>Shërbimet</span>
              <div>
                <S.Button
                  onClick={() => {
                    setSelectedService(null);
                    setOpenEditModal(true);
                  }}
                  width={100}
                  height={30}
                >
                  Krijo
                </S.Button>
              </div>
            </div>
          </S.HeaderContainer>
          <S.ServicesContainer>
            <S.GradientBg />

            <S.ServicesContent>
              <S.DataTable
                id="table"
                table={{ rows: dataRows, columns }}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={false}
              />
            </S.ServicesContent>
          </S.ServicesContainer>
        </S.Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

const S = {
  Card: styled(Card)`
    width: 95%;
    min-height: 95%;
    overflow: auto;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    @media (max-width: 1024px) {
      width: 90%;
      min-height: 95%;
    }
  `,
  Conainer: styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
  `,
  CloseBtnDiv: styled.div`
    position: absolute;
    top: 0px;
    right: 20px;
  `,

  HeaderContainer: styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
    img {
      width: 330px;
      height: 100px;
      @media (max-width: 768px) {
        width: 200px;
        height: 60px;
      }
    }
  `,
  CustomerInfoArea: styled.div`
    display: grid !important;
    grid-template-columns: repeat(7, 1fr) !important;
    margin-right: 20px !important;
    grid-gap: 10px !important;
    border-bottom: none !important;
    max-height: none !important;
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
    @media (max-width: 1024px) {
      grid-template-columns: repeat(2, 1fr) !important;
    }
  `,
  CustomerInfoCell: styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    align-items: center;
  `,
  CustomerInfoCelData: styled.div`
    height: 40px;
    border-radius: ${(props) => (props.borderRadius ? `${props.borderRadius}px` : "40px")};
    background-color: rgba(0, 0, 0, 0.06);
    width: ${(props) => (props.auto ? "auto" : "100%")};

    color: #C20B7E;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
  `,
  ServicesContainer: styled.div`
    position: relative;
    padding-top: 20px;
    flex: 1;
    min-height: 400px;
    backgroundcolor: transparent;
    overflow: auto;
  `,

  SearchFilters: styled.div`
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
  `,
  ServicesContent: styled.div`
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    overflow: auto;
  `,

  GradientBg: styled.div`
    position: absolute;
    background: white;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0.03) 20%, white 50%);
  `,
  SearchLeftPan: styled.div`
    display: flex;
    flex: 1;
  `,
  SearchRightPan: styled.div`
    display: flex;
    flex: 1;
    @media (max-width: 1024px) {
      display: none;
    }
  `,

  SelectFilter: styled(Select)`
    flex: 1;

    border-radius: 10px;
    font-size: 1rem !important;

    .select__control {
      box-shadow: unset;
      background-color: rgba(0, 0, 0, 0.06);
      border-radius: 10px;
      min-height: 30px !important;
      font-size: 1rem;
      border: none;
      :hover {
        border: none;
      }
      :focuse {
        border: none;
      }
      :actived {
        border: none;
      }
    }
    .select__multi-value {
      border: 1px solid #C20B7E;
    }
    .select__indicator {
      color: #C20B7E !important;
    }
    .select__indicator-separator {
      display: none !important;
    }
  `,

  Select: styled(Select)`
    flex: 1;
    min-height: 50px !important;
    border-radius: 10px;
    font-size: 1rem !important;

    .select__control {
      box-shadow: unset;
      background-color: rgba(0, 0, 0, 0.06);
      border-radius: 10px;
      min-height: 50px !important;
      border: none;
      :hover {
        border: none;
      }
      :focuse {
        border: none;
      }
      :actived {
        border: none;
      }
    }
    .select__multi-value {
      border: 1px solid #C20B7E;
    }
    .select__indicator {
      color: #C20B7E !important;
    }
    .select__indicator-separator {
      display: none !important;
    }
  `,
  Button: styled(Button)`
    height: ${(props) => (props.height ? `${props.height}px` : "50px")} !important;
    width: ${(props) => (props.width ? `${props.width}px` : "100%")} !important;
    border-radius: 10px !important;
    background-color: ${(props) => (props.dark ? "gray !important" : "#C20B7E !important")};
    text-transform: none !important;
    font-size: 1rem !important;
    font-weight: 100 !important;
    color: white !important;
    margin-bottom: 2px;
    :hover {
      background-color: ${(props) => (props.dark ? "lightgray !important" : "#E560B3 !important")};
      color: white !important;
    }
  `,
  BottomContainer: styled.div`
    height: 80px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
  `,
  DataTable: styled(DataTable)`
    .MuiTableContainer-root {
      table {
        min-width: 1200px !important;
      }
    }
    .MuiTable-root {
      thead {
        background-color: #C20B7E !important;
        color: white !important;
      }
      table {
        min-width: 800px !important;
      }
    }
  `,
  FilterCellContainer: styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
  `,
};

export default Services;
