import { useEffect, useState } from "react";

import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import Select from "react-select";
import IconButton from "layouts/bookings/pages/components/IconButton";
import { useDispatch } from "react-redux";
import { showAlert } from "actions/alert";
import { showLoading } from "actions/loading";
import { callServicePostAuth, callServiceGetAuth } from "services/AuthServices";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CustomLabels from "layouts/bookings/pages/components/CustomLabels";
import Moment from "moment/moment";
import CustomCalender from "layouts/bookings/pages/components/CustomCalendars/CustomCalender";
import config from "configs/config.json";

// import Moment from "moment/moment";

const TAG = "CreateEditService";

const convertData2Option = (data) =>
  data.map((item) => ({
    value: item.fields.name,
    label: item.fields.name,
    id: item.pk,
  }));

function CreateEditService({ selectedItem, onClose }) {
  const [price, setPrice] = useState(selectedItem ? selectedItem.fields.price : "");
  const [description, setDescription] = useState(selectedItem ? selectedItem.fields.desc : "");
  const [openCalendar, setOpenCalendar] = useState(false);
  const [selectedStudio, setSelectedStudio] = useState(0);
  const [initialStudios, setInitialStudios] = useState();
  const [selectedDate, setSelectedDate] = useState(
    selectedItem
      ? Moment(selectedItem.fields.date).utc().format("DD.MM.YYYY")
      : Moment().format("DD.MM.YYYY")
  );
  const dispatch = useDispatch();

  const handleGetAllIndivitualResponse = async (res, isErr) => {
    dispatch(showLoading(false));
    if (isErr) {
      dispatch(showAlert({ type: "error", msg: res }));
      return;
    }

    if (res.result === "success" && res.data) {
      setInitialStudios(convertData2Option(res.data.studios));
    }
  };

  const callAPIGetAllIndividuals = async () => {
    const url = config.BASE_URL + config.GET_ALL_INDIVITIAL_INFOS;
    console.log(TAG, "callAPIGetAllIndividuals url = ", url);

    dispatch(showLoading(true));

    callServiceGetAuth(url, handleGetAllIndivitualResponse);
  };

  useEffect(() => {
    if (!initialStudios) callAPIGetAllIndividuals();
  }, [initialStudios]);

  const handleAddExpencesResponse = async (res, isErr) => {
    dispatch(showLoading(false));
    if (isErr) {
      dispatch(showAlert({ type: "error", msg: res }));
      return;
    }
    dispatch(showAlert({ type: "success", msg: "" }));
    onClose();
  };

  const callApiAddExpences = async () => {
    const url = config.BASE_URL + config.ADD_EXPENCES;

    const postData = {
      description,
      price: parseFloat(price),
      //   date: Moment(selectedDate, "DD.MM.YYYY").format("YYYY-MM-DD"),
      date: Moment(selectedDate, "DD.MM.YYYY").format("YYYY-MM-DD"),
      studioId: selectedStudio,
    };

    console.log(TAG, "callApiAddExpences url", url);
    console.log(TAG, "callApiAddExpences data = ", JSON.stringify(postData));

    callServicePostAuth(url, postData, handleAddExpencesResponse);
  };

  const callApiEditExpence = () => {
    const url = config.BASE_URL + config.UPDATE_EXPENCES;

    const postData = {
      id: selectedItem.pk,
      description,
      price: parseFloat(price),

      date: Moment(selectedDate, "DD.MM.YYYY").format("YYYY-MM-DD"),
    };

    console.log(TAG, "callApiEditExpence url", url, selectedItem);
    console.log(TAG, "callApiEditExpence data = ", JSON.stringify(postData));

    callServicePostAuth(url, postData, handleAddExpencesResponse);
  };

  const handleClickedRegister = () => {
    if (!selectedItem) {
      callApiAddExpences();
    } else {
      callApiEditExpence();
    }
  };

  return (
    <S.Card>
      <S.Header>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          Daljet
        </div>

        <S.CloseBtnContainer>
          <Button
            aria-label="delete"
            size="large"
            onClick={onClose}
            width={20}
            height={20}
            style={{ width: "20px", height: "20px", borderRadius: "20px", padding: 0, margin: 0 }}
          >
            <CloseIcon fontSize="large" color="white" />
          </Button>
        </S.CloseBtnContainer>
      </S.Header>
      <S.Container>
        <Grid container spacing={3} height="100%">
          <Grid item xs={12} md={12} lg={12}>
            <CustomLabels title="Emri :" fontSize={16} fontWeight={600} />
            <IconButton
              onClick={() => setOpenCalendar(!openCalendar)}
              title={selectedDate}
              icon="calendar_month_icon"
              imageSize="30px"
              fontSize="24px"
              iconColor="#C20B7E"
              textColor="gray"
            />
            <div style={{ position: "relative", width: "100%" }}>
              {openCalendar && (
                <div style={{ width: "100%", position: "absolute", zIndex: 10 }}>
                  <CustomCalender
                    onChange={(value) => {
                      setSelectedDate(Moment(value).format("DD.MM.YYYY"));
                      setOpenCalendar(false);
                    }}
                    useWeekdaysShort
                  />
                </div>
              )}
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <CustomLabels title="Shuma (€):" fontSize={16} fontWeight={600} />
            <S.NoLabelTextField
              variant="filled"
              label={null}
              value={price}
              onChange={(val) => {
                setPrice(val.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <CustomLabels title="Pershkrimi:" fontSize={16} fontWeight={600} />
            <S.NoLabelTextField
              variant="filled"
              label={null}
              multiline
              maxRows={4}
              value={description}
              onChange={(val) => {
                setDescription(val.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <CustomLabels title="Studio:" fontSize={16} fontWeight={600} />
            <S.Select
              className="basic-single"
              classNamePrefix="select"
              placeholder="Zgjedh..."
              isClearable
              isSearchable
              onChange={(value) => setSelectedStudio(value ? value.id : null)}
              options={initialStudios}
              style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
            />
          </Grid>
        </Grid>
      </S.Container>
      <S.Bottom>
        <S.Button onClick={() => handleClickedRegister()} width={200}>
          Regjistro
        </S.Button>
      </S.Bottom>
    </S.Card>
  );
}

const S = {
  Card: styled(Card)`
    width: 60%;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;

    @media (max-width: 1024px) {
      width: 90%;
    }
  `,
  Header: styled.div`
    height: 50px;
    color: white;
    background-color: #C20B7E;
    font-size: 1.5rem;
    font-weight: 400;
    width: 100%;
    position: relative;

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  `,
  CloseBtnContainer: styled.div`
    position: absolute;
    right: 10px;
    top: 2px;
    color: white;
  `,
  Container: styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    overflow: visible;
    @media (max-width: 1024px) {
      padding: 40px 20px;
    }
    @media (max-width: 768px) {
      padding: 20px;
    }
  `,
  Bottom: styled.div`
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 10px;
  `,
  Button: styled(Button)`
    height: ${(props) => (props.height ? `${props.height}px` : "50px")} !important;
    width: ${(props) => (props.width ? `${props.width}px` : "100%")} !important;
    border-radius: 10px !important;
    background-color: ${(props) => (props.dark ? "gray !important" : "#C20B7E !important")};
    text-transform: none !important;
    font-size: 1rem !important;
    font-weight: 100 !important;
    color: white !important;
    margin-bottom: 2px;
    :hover {
      background-color: ${(props) => (props.dark ? "lightgray !important" : "#E560B3 !important")};
      color: white !important;
    }
  `,
  NoLabelTextField: styled(TextField)`
    width: 100%;
    .MuiFilledInput-root {
      padding: 5px !important;
    }
    input {
      padding: 0 !important;
      height: 40px;
    }
    textarea {
      padding: 0 !important;
      min-height: 40px !important;
    }
  `,

  Select: styled(Select)`
    flex: 1;

    border-radius: 0px;
    font-size: 1rem !important;

    .select__menu {
      z-index: 1;
    }

    .select__control {
      box-shadow: unset;
      background-color: rgba(0, 0, 0, 0.06);
      border-radius: 0px;
      min-height: 50px !important;
      font-size: 1rem;
      border: none;
      border-bottom: solid 1px #000000de;
      :hover {
        border: none;
        border-bottom: solid 1px #000000de;
      }
      :focuse {
        border: none;
      }
      :actived {
        border: none;
      }
    }
    .select__multi-value {
      border: 1px solid #C20B7E;
    }
    .select__indicator {
      color: #C20B7E !important;
    }
    .select__indicator-separator {
      display: none !important;
    }
  `,
};

CreateEditService.propTypes = {
  selectedItem: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
};
export default CreateEditService;
