import Card from "@mui/material/Card";
import { useMemo, useState, useEffect } from "react";
import MDBox from "components/MDBox";
import { useDispatch } from "react-redux";
import { showAlert } from "actions/alert";
import PropTypes from "prop-types";
import { Line } from "react-chartjs-2";
import MDTypography from "components/MDTypography";
// import Icon from "@mui/material/Icon";
import configs from "layouts/dashboard/components/SalesAndBookingOfStaffs/configs";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import config from "configs/config.json";
// import { Radio } from "@mui/material";
import Radio from "@mui/material/Radio";
import { callServicePostAuth } from "services/AuthServices";
import Moment from "moment/moment";

const TAG = "SalesAndBookingOfStaffs";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      marginLeft: -30,
    },
  },
};

const monthList = [
  { label: "Janar", value: 1 },
  { label: "Shkurt", value: 2 },
  { label: "Mars", value: 3 },
  { label: "Prill", value: 4 },
  { label: "Maj", value: 5 },
  { label: "Qershor", value: 6 },
  { label: "Korrik", value: 7 },
  { label: "Gusht", value: 8 },
  { label: "Shtator", value: 9 },
  { label: "Tetor", value: 10 },
  { label: "Nëntor", value: 11 },
  { label: "Dhjetor", value: 12 },
];

function SalesAndBookingOfStaffs({ title, subTitle, color, txtColor, chart }) {
  console.log(TAG, "SalesAndBookingOfStaffs started ", chart, monthList);
  const dispatch = useDispatch();
  const [selectedMonth, setMonth] = useState(Number(new Moment(new Date()).format("M")));
  const [allData, setAllData] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(6);
  const [chartData, setChartData] = useState();
  // const { data, options } = configs(
  //   (chart && chart.labels) || [],
  //   (chart && chart.datasets) || {},
  //   txtColor,
  //   color
  // );
  const [filteredStaffDataByMonth, setFilteredStaffDataByMonth] = useState([]);

  const getStaffsDataCurrentMonth = () =>
    allData.map((item) => {
      const monthsData = item.month_data;
      const currentMonthData = monthsData.filter((m) => m.month === selectedMonth)[0];
      const salaryPercent = item.fields.salary_percent;

      let totalBookings = 0;
      if (
        currentMonthData &&
        currentMonthData.booking_cnt &&
        currentMonthData.booking_cnt.length > 0
      ) {
        currentMonthData.booking_cnt.forEach((item2) => {
          totalBookings += item2.total;
        });
      }

      let staffIncomes = 0;
      if (
        currentMonthData &&
        currentMonthData.saff_incomes &&
        currentMonthData.saff_incomes.length > 0
      ) {
        // staffIncomes = currentMonthData.saff_incomes[0].total;
        currentMonthData.saff_incomes.forEach((item2) => {
          staffIncomes += item2.total;
        });
      }

      let totalIncomes = 0;
      if (
        currentMonthData &&
        currentMonthData.total_incomes &&
        currentMonthData.total_incomes.length > 0
      ) {
        currentMonthData.total_incomes.forEach((item2) => {
          totalIncomes += item2.total;
        });
      }

      if (totalIncomes === 0) totalIncomes = 1;

      const realIncome = (staffIncomes * salaryPercent) / 100;

      const percentage = (staffIncomes / totalIncomes) * 100;

      return {
        id: item.pk,
        name: item.fields.name,
        totalBookings,
        staffIncomes,
        salaryPercent,
        percentage,
        realIncome,
      };
    });

  const handleStaffDataPerMonthResponse = async (res, isErr) => {
    if (isErr) {
      dispatch(
        showAlert({
          type: "error",
          msg: "Dështim në marrjen e informacioneve të rezervimeve mujore",
        })
      );
      return;
    }
    if (res.data) {
      setAllData(() => res.data);
      setFilteredStaffDataByMonth(() => getStaffsDataCurrentMonth());
    }
  };

  const callApiStaffsDataPerMonth = async () => {
    setFilteredStaffDataByMonth(() => []);
    setAllData(() => []);
    const url = config.BASE_URL + config.DASHBOARD_STAFFS_DATA_MONTH;
    callServicePostAuth(url, { month: selectedMonth }, handleStaffDataPerMonthResponse);
  };

  useEffect(() => {
    callApiStaffsDataPerMonth();
  }, []);

  useEffect(() => {
    if (allData.length > 0) setFilteredStaffDataByMonth(getStaffsDataCurrentMonth());
  }, [selectedMonth, allData]);

  const handleRadioSelected = (e) => {
    console.log("radio changed = ", e.target.value, selectedStaff);
    setSelectedStaff(parseInt(e.target.value, 10));

    const selectedStaffObj = allData.filter((item) => item.pk === parseInt(e.target.value, 10))[0];
    // const percent = selectedStaffObj.fields.salary_percent;
    const monthlyData = selectedStaffObj.month_data.map((item) => {
      let totalIncomes = 0;
      item.total_incomes.forEach((item2) => {
        totalIncomes += item2.total;
      });

      if (totalIncomes === 0) return 0;
      let staffIncomes = 0;
      if (item.saff_incomes.length > 0) {
        // staffIncomes = item.saff_incomes[0].total;
        item.saff_incomes.forEach((item2) => {
          staffIncomes += item2.total;
        });
      }
      return (staffIncomes / totalIncomes) * 100;
    });

    const cartData = {
      labels: [
        "Jan",
        "Feb",
        "March",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: { label: "Mobile apps", data: monthlyData },
    };

    setChartData(cartData);
  };

  return (
    <Card style={{ height: "100%" }}>
      <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
        <MDBox lineHeight={1.25} display="flex" justifyContent="space-between" width="100%">
          <MDBox display="flex" flexDirection="column">
            <MDTypography
              variant="button"
              fontWeight="light"
              color="text"
              textwrap="wrap"
              style={{ fontSize: 20 }}
            >
              {title}
            </MDTypography>
            <MDTypography
              variant="button"
              fontWeight="light"
              color="text"
              textwrap="wrap"
              mt={1}
              style={{ color: txtColor, fontSize: 14 }}
            >
              {subTitle}
            </MDTypography>
          </MDBox>
          {/* <MDTypography variant="h4">{count}</MDTypography> */}
          <MDBox
            variant="contained"
            px="5px"
            borderRadius="5px"
            display="flex"
            alignItems="center"
            height="3rem"
            width="5rem"
          >
            {/* <Icon fontSize="medium" style={{ color: txtColor, fontSize: 40 }}>
              {icon}
            </Icon> */}

            <Select
              labelId="demo-simple-select-clabel"
              id="demo-simple-select-standard"
              value={selectedMonth}
              fullWidth
              height="40px"
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              onChange={(val) => setMonth(val.target.value)}
              style={{ height: 40, fontWeight: 600 }}
              MenuProps={MenuProps}
            >
              {monthList.map((item) => (
                <MenuItem value={item.value} key={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox
        justifyContent="space-between"
        pt={1}
        px={2}
        height="32rem"
        overflow="scroll"
        fontSize="1rem"
      >
        <div style={{ width: "100%", display: "flex", marginBottom: 10 }}>
          <div style={{ width: 40 }} />
          <div style={{ display: "flex", flex: 1 }}>
            <span style={{ flex: 1, marginRight: 10 }}>
              <b>Stafi</b>
            </span>
            <span style={{ flex: 1, marginRight: 10 }}>
              <b>Përqindja</b>
            </span>
            <span style={{ flex: 1 }}>
              <b>Rezervimet</b>
            </span>
          </div>
        </div>
        {filteredStaffDataByMonth.map((item) => (
          <div
            key={item.id}
            style={{ width: "100%", display: "flex", marginBottom: 10, alignItems: "center" }}
          >
            <Radio
              checked={selectedStaff === item.id}
              onChange={handleRadioSelected}
              inputProps={{ "aria-label": item.id }}
              value={item.id}
              name="radio-buttons"
            />
            <span style={{ flex: 1, marginRight: 10 }}>{item.name}</span>
            <span style={{ flex: 1, marginRight: 10, color: "gray" }}>
              {item.percentage.toFixed(2)}%
            </span>
            <span style={{ flex: 1, color: "gray" }}>{item.totalBookings}</span>
          </div>
        ))}
      </MDBox>
      <MDBox display="flex" justifyContent="space-between" pt={1} px={2} width="100%" flex="1">
        {useMemo(
          () => (
            // style={{ height: "4rem" }}
            <div style={{ width: "100%" }}>
              <Line
                data={
                  configs(
                    (chartData && chartData.labels) || [],
                    (chartData && chartData.datasets) || {},
                    txtColor,
                    color
                  ).data
                }
                options={
                  configs(
                    (chartData && chartData.labels) || [],
                    (chartData && chartData.datasets) || {},
                    txtColor,
                    color
                  ).options
                }
              />
            </div>
          ),
          [chartData]
        )}
      </MDBox>
    </Card>
  );
}

SalesAndBookingOfStaffs.propTypes = {
  chart: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])).isRequired,
  color: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  // icon: PropTypes.node.isRequired,
  txtColor: PropTypes.string.isRequired,
  // txtRightBtn: PropTypes.string.isRequired,
};

export default SalesAndBookingOfStaffs;
