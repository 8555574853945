/* eslint-disable import/no-unresolved */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// import CircularProgress from "@mui/material/CircularProgress";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
// import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
// import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
// import Projects from "layouts/dashboard/components/Projects";
// import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import RecentBookings from "layouts/dashboard/components/RecentBookings";

import MyCircularProgress from "layouts/dashboard/components/MyCircularProgress";

import SalesAndBookingOfStaffs from "layouts/dashboard/components/SalesAndBookingOfStaffs";
// eslint-disable-next-line import/no-unresolved
import Tasks from "layouts/dashboard/components/Tasks";
import SalesAccordingMonth from "layouts/dashboard/components/SalesAccordingMonth";
import Moment from "moment/moment";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { showAlert } from "actions/alert";
import config from "configs/config.json";
import { callServicePostAuth } from "services/AuthServices";

const TAG = "Dashboard";

function Dashboard() {
  // const { sales, tasks } = reportsLineChartData;
  const { sales } = reportsLineChartData;
  const dispatch = useDispatch();
  const [currentMonthBooking, setCurrentMonthBooking] = useState();
  const [prevMonthBooking, setPrevMonthBooking] = useState();
  const [nextMonthBooking, setNextBookingMonth] = useState();
  const [wholeMonthlyBookings, setWholeMonthlyBookings] = useState([]);
  const [newCustomers, setNewCustomers] = useState(0);
  const [existedCustomers, setExistedCustomers] = useState(0);
  const [todayBookings, setTodayBookings] = useState([]);

  const calcPercentThanPrevMonth = (currentMonth) => {
    if (!currentMonth) return 0;

    const prevData = wholeMonthlyBookings.filter((item) => {
      console.log(
        "currentMonth = ddddddddddddd",
        Math.floor(Moment(item.month).diff(Moment(currentMonth.month), "month"))
      );
      return Math.floor(Moment(item.month).diff(Moment(currentMonth.month), "month")) === -1;
    });
    let cntLast = 0;
    if (prevData.length > 0) {
      cntLast = prevData[0].count;
    }

    console.log("currentMonth = ", currentMonth, prevData, cntLast, cntLast || 1);

    return (((currentMonth.count - cntLast) / (cntLast || 1)) * 100).toFixed(2);
  };

  const handleStatisticsResponse = (res, isErr) => {
    console.log(TAG, "handleStatisticsResponse = ", res, isErr);

    if (isErr) {
      dispatch(
        showAlert({
          type: "error",
          msg: "Dështim në marrjen e informacioneve të rezervimeve mujore",
        })
      );
      return;
    }

    if (res.data) {
      const { data } = res;
      const monthlyData = data.map((item) => {
        const tempData = { count: item.total, month: item.month };

        const monthDiff = Moment(item.month).diff(Moment(new Date()), "month");
        if (monthDiff === 0) {
          setCurrentMonthBooking(tempData);
        } else if (monthDiff === -1) {
          setPrevMonthBooking(tempData);
        } else if (monthDiff === 1) {
          setNextBookingMonth(tempData);
        }
        return tempData;
      });

      setWholeMonthlyBookings(monthlyData);
      console.log("monthly data = ", wholeMonthlyBookings);
    }
  };
  const callApiStatistics = async () => {
    const url = config.BASE_URL + config.DASHBARD_STATISTICS;
    console.log(url);
    callServicePostAuth(url, {}, handleStatisticsResponse);
  };

  const handleTodayCustomersResponse = async (res, isErr) => {
    if (isErr) {
      dispatch(
        showAlert({ type: "error", msg: "Marrja e të dhënave të rezervimit për sot dështoi" })
      );
      // Failed to get booking data for today
      return;
    }

    if (res.data) {
      console.log("trter ert ert ert ert s", res);
      let cntExited = 0;
      let cntNews = 0;
      res.data.forEach((item) => {
        const diffDays = Moment(new Date()).diff(Moment(item.fields.client.created_at), "days");
        console.log(
          "trter ert ert ert ert s",
          diffDays,
          item.fields.client.created_at,
          Moment(item.fields.client.created_at)
        );
        if (diffDays === 0) cntNews += 1;
        if (diffDays > 0) cntExited += 1;
      });
      setNewCustomers(cntNews);
      setExistedCustomers(cntExited);
      setTodayBookings(res.data);
    }
  };

  const callApiTodayCustomers = async () => {
    const url = config.BASE_URL + config.DASHBOARD_TODAY_BOOKING_CNT;
    callServicePostAuth(url, {}, handleTodayCustomersResponse);
  };
  useState(() => {
    callApiStatistics();
    callApiTodayCustomers();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} ml={3} mr={3} mt={3}>
        <MDBox>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ fontSize: 22 }}>Ballina</span>
            {/* <span style={{ fontSize: 16, color: "darkgray", marginTop: 5, marginBottom: 10 }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </span> */}
          </div>
        </MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8} lg={8}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} lg={4}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="#F0FBE5"
                    txtColor="#6DD400"
                    icon={
                      calcPercentThanPrevMonth(prevMonthBooking) >= 0
                        ? "arrow_drop_up"
                        : "arrow_drop_down"
                    }
                    title="Rezervimet muajin e kaluar"
                    count={prevMonthBooking?.count || "0"}
                    percent={calcPercentThanPrevMonth(prevMonthBooking)}
                    chart={sales}
                    percentage={{
                      color: "success",
                      amount: "+55%",
                      label: "than lask week",
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    icon={
                      calcPercentThanPrevMonth(currentMonthBooking) >= 0
                        ? "arrow_drop_up"
                        : "arrow_drop_down"
                    }
                    color="#FBE6F3"
                    txtColor="#C20B7E"
                    title="Rezervimet këtë muaj"
                    count={currentMonthBooking?.count || "0"}
                    percent={calcPercentThanPrevMonth(currentMonthBooking)}
                    chart={sales}
                    percentage={{
                      color: "success",
                      amount: "+3%",
                      label: "than last month",
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="#FFF4E5"
                    txtColor="#FF9500"
                    icon={
                      calcPercentThanPrevMonth(nextMonthBooking) >= 0
                        ? "arrow_drop_up"
                        : "arrow_drop_down"
                    }
                    title="Rezervimet për muajin e ardhshëm"
                    count={nextMonthBooking?.count || "0"}
                    percent={calcPercentThanPrevMonth(nextMonthBooking)}
                    chart={sales}
                    // percentage={{
                    //   color: "success",
                    //   amount: "+1%",
                    //   label: "than yesterday",
                    // }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={7} lg={7}>
                <RecentBookings bookingList={todayBookings} />
              </Grid>
              <Grid item xs={12} md={5} lg={5}>
                <MDBox
                  mb={1.5}
                  style={{
                    height: "100%",
                    width: "100%",
                    position: "relative",
                  }}
                >
                  <Card
                    style={{
                      height: "100%",
                      width: "100%",
                      position: "relative",
                      display: "flex",
                      padding: "20px",
                    }}
                  >
                    <MDBox pt={3} px={3} mb={3}>
                      <MDTypography variant="h5" style={{ fontWeight: 400, fontSize: 24 }}>
                        Rezervimet ditore
                      </MDTypography>
                    </MDBox>
                    <MDBox
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height="20rem"
                      position="relative"
                    >
                      <MyCircularProgress
                        mysize={200}
                        color="#C20B7E"
                        variant="determinate"
                        value={newCustomers}
                        thickness={2}
                      />

                      <MyCircularProgress
                        mysize={150}
                        color="#6DD400"
                        variant="determinate"
                        value={existedCustomers}
                        thickness={2}
                      />
                      <MDTypography variant="h5" style={{ fontWeight: 400, fontSize: 30 }}>
                        {newCustomers}
                      </MDTypography>
                    </MDBox>
                    <MDBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      style={{ width: "100%" }}
                    >
                      <MDBox alignItems="center">
                        <MDBox display="flex" alignItems="center">
                          <MDBox
                            justifyContent="center"
                            alignItems="center"
                            bgColor="#C20B7E"
                            color="white"
                            width="1rem"
                            height="1rem"
                            borderRadius="50%"
                            top="8%"
                            left="2px"
                            zIndex={2}
                            sx={{ fontSize: ({ typography: { size } }) => size.sm }}
                          />
                          <MDTypography
                            ml={1}
                            variant="h5"
                            style={{ fontWeight: 400, fontSize: 16 }}
                          >
                            Klientë të ri
                          </MDTypography>
                        </MDBox>
                        <MDBox
                          display="flex"
                          alignItems="center"
                          width="100%"
                          justifyContent="center"
                        >
                          <MDTypography
                            ml={1}
                            variant="h5"
                            style={{ fontWeight: 400, fontSize: 16 }}
                          >
                            {newCustomers}
                          </MDTypography>
                        </MDBox>
                      </MDBox>

                      <MDBox alignItems="center">
                        <MDBox display="flex" alignItems="center">
                          <MDBox
                            justifyContent="center"
                            alignItems="center"
                            bgColor="#6DD400"
                            color="white"
                            width="1rem"
                            height="1rem"
                            borderRadius="50%"
                            top="8%"
                            left="2px"
                            zIndex={2}
                            sx={{ fontSize: ({ typography: { size } }) => size.sm }}
                          />
                          <MDTypography
                            ml={1}
                            variant="h5"
                            style={{ fontWeight: 400, fontSize: 16 }}
                          >
                            Klientë aktual
                          </MDTypography>
                        </MDBox>
                        <MDBox
                          display="flex"
                          alignItems="center"
                          width="100%"
                          justifyContent="center"
                        >
                          <MDTypography
                            ml={1}
                            variant="h5"
                            style={{ fontWeight: 400, fontSize: 16 }}
                          >
                            {existedCustomers}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                    {/* <MDBox
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height="20rem"
                      position="ab"
                    >
                      <MyCircularProgress
                        mysize={100}
                        color="#C20B7E"
                        variant="determinate"
                        value={60}
                        thickness={2}
                      />
                    </MDBox> */}

                    {/* <CircularProgress
                      variant="determinate"
                      value={25}
                      style={{ position: "absolute" }}
                      classes={{
                        circle: {
                          strokeLinecap: "round",
                        },
                      }}
                    /> */}
                  </Card>
                </MDBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <SalesAndBookingOfStaffs
              style={{ height: "100%" }}
              title="Shitjet & rezervimet sipas stafit"
              subTitle="Përqindje (%) & numër"
              color="#FBE6F3"
              txtColor="#C20B7E"
              txtRightBtn="Prill"
              icon="arrow_drop_down"
              chart={sales}
            />
          </Grid>
        </Grid>

        {/* <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="website views"
                  description="Last Campaign Performance"
                  date="campaign sent 2 days ago"
                  chart={reportsBarChartData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="daily sales"
                  description={
                    <>
                      (<strong>+15%</strong>) increase in today sales.
                    </>
                  }
                  date="updated 4 min ago"
                  chart={sales}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="completed tasks"
                  description="Last Campaign Performance"
                  date="just updated"
                  chart={tasks}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox> */}
        <MDBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4.7}>
              <Tasks title="Lista e puneve" />
            </Grid>
            <Grid item xs={12} md={6} lg={7.3}>
              <SalesAccordingMonth
                title="Shitjet sipas muajve"
                color="#FBE6F3"
                txtColor="#C20B7E"
                icon="arrow_drop_down"
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
