/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import BookingPage from "layouts/bookings";
// import Billing from "layouts/billing";
import UserIncomePage from "layouts/incomes/users/UserIncomePage";
import UserExpencesPage from "layouts/expences/user/UserExpencesPage";

// import RTL from "layouts/rtl";
// import Notifications from "layouts/notifications";
// import Profile from "layouts/profile";
import Customers from "layouts/customers";

// @mui icons
import Icon from "@mui/material/Icon";
// import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import SalesReport from "layouts/admin/SalesReport";
import IndividualReport from "layouts/admin/individualreports";
import Users from "layouts/admin/users";
import Staffs from "layouts/admin/staffs";
// import SMSViewer from "layouts/admin/sms";
import Studios from "layouts/admin/studios";
import Services from "layouts/admin/services";
import AdminIncomePage from "layouts/admin/incomes/AdminIncomePage";
import AdminExpences from "layouts/admin/expences/AdminExpences";

// import Tables from "layouts/tables";

const routes = [
  {
    type: "collapse",
    name: "Ballina",
    key: "ballina",
    icon: <Icon fontSize="small">account_balance</Icon>,
    route: "/ballina",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Rezervimet",
    key: "rezervimet",
    icon: <Icon fontSize="small">date_range</Icon>,
    route: "rezervimet",
    component: <BookingPage />,
  },
  {
    // import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
    // import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
    type: "collapse",
    name: "Hyrje",
    key: "hyrje",
    icon: <Icon fontSize="small">money_outlined</Icon>,
    route: "hyrje",
    component: <UserIncomePage />,
  },
  {
    type: "collapse",
    name: "Dalje",
    key: "dalje",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "dalje",
    component: <UserExpencesPage />,
  },
  {
    type: "collapse",
    name: "Klientët",
    key: "klientet",
    icon: <Icon fontSize="small">groups2_outlined</Icon>,
    route: "klientet",
    component: <Customers />,
  },
  // {
  //   type: "collapse",
  //   name: "Sign In",
  //   key: "sign-in",
  //   icon: <Icon fontSize="small">login</Icon>,
  //   route: "authentication/sign-in",
  //   component: <SignIn />,
  // },
  // {
  //   type: "collapse",
  //   name: "Sign Up",
  //   key: "sign-up",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "authentication/sign-up",
  //   component: <SignUp />,
  // },
];

// import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined';
const adminRoutes = [
  {
    type: "collapse",
    name: "Ballina",
    key: "ballina",
    icon: <Icon fontSize="small">account_balance</Icon>,
    route: "/ballina",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Rezervimet",
    key: "rezervimet",
    icon: <Icon fontSize="small">date_range</Icon>,
    route: "rezervimet",
    component: <BookingPage />,
  },
  {
    // import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
    // import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
    type: "collapse",
    name: "Hyrje",
    key: "hyrje",
    icon: <Icon fontSize="small">money_outlined</Icon>,
    route: "hyrje",
    component: <AdminIncomePage />,
  },
  {
    type: "collapse",
    name: "Dalje",
    key: "dalje",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "dalje",
    component: <AdminExpences />,
  },
  {
    type: "collapse",
    name: "Klientët",
    key: "klientet",
    icon: <Icon fontSize="small">groups2_outlined</Icon>,
    route: "klientet",
    component: <Customers />,
  },
  {
    type: "collapse",
    name: "Raporti i shitjëve",
    key: "salesreport",
    icon: <Icon fontSize="small">auto_graph_outlined_icon</Icon>,
    route: "/salesreport",
    component: <SalesReport />,
  },
  // / import CardGiftcardOutlinedIcon from '@mui/icons-material/CardGiftcardOutlined';
  {
    type: "collapse",
    name: "Shitjet individuale",
    key: "individualreport",
    icon: <Icon fontSize="small">card_giftcard_outlined_icon</Icon>,
    route: "individualreport",
    component: <IndividualReport />,
  },
  {
    // import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
    // import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
    type: "collapse",
    name: "Përdoruesit",
    key: "users",
    icon: <Icon fontSize="small">person</Icon>,
    route: "users",
    component: <Users />,
  },

  // import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
  {
    type: "collapse",
    name: "Stafi",
    key: "staff",
    icon: <Icon fontSize="small">groups_outlined_icon</Icon>,
    route: "staff",
    component: <Staffs />,
  },
  // import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';

  {
    type: "collapse",
    name: "Studios",
    key: "studios",
    icon: <Icon fontSize="small">add_homework_icon</Icon>,
    route: "studios",
    component: <Studios />,
  },
  {
    type: "collapse",
    name: "Shërbimet",
    key: "services",
    icon: <Icon fontSize="small">library_add_outlined_icon</Icon>,
    route: "services",
    component: <Services />,
  },
  // {
  //   type: "collapse",
  //   name: "SMS",
  //   key: "sms",
  //   icon: <Icon fontSize="small">sms_outlined_icon</Icon>,
  //   route: "sms",
  //   component: <SMSViewer />,
  // },
  // {
  //   type: "collapse",
  //   name: "notification",
  //   key: "notification",
  //   icon: <Icon fontSize="small">sms_outlined_icon</Icon>,
  //   route: "notification",
  //   component: <Notifications />,
  // },
  // {
  //   type: "collapse",
  //   name: "Dashboard-admin",
  //   key: "dashboard",
  //   icon: <Icon fontSize="small">dashboard</Icon>,
  //   route: "dashboard",
  //   component: <Dashboard />,
  // },
  // {
  //   type: "collapse",
  //   name: "Tables",
  //   key: "tables",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "tables",
  //   component: <Tables />,
  // },
  // {
  //   type: "collapse",
  //   name: "Billing",
  //   key: "billing",
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: "billing",
  //   component: <Billing />,
  // },
  // {
  //   type: "collapse",
  //   name: "RTL",
  //   key: "rtl",
  //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
  //   route: "rtl",
  //   component: <RTL />,
  // },
  // {
  //   type: "collapse",
  //   name: "Notifications",
  //   key: "notifications",
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "notifications",
  //   component: <Notifications />,
  // },
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "profile",
  //   component: <Profile />,
  // },
  // // {
  // //   type: "collapse",
  // //   name: "Sign In",
  // //   key: "sign-in",
  // //   icon: <Icon fontSize="small">login</Icon>,
  // //   route: "authentication/sign-in",
  // //   component: <SignIn />,
  // // },
  // // {
  // //   type: "collapse",
  // //   name: "Sign Up",
  // //   key: "sign-up",
  // //   icon: <Icon fontSize="small">assignment</Icon>,
  // //   route: "authentication/sign-up",
  // //   component: <SignUp />,
  // // },
];

export { routes, adminRoutes };
