import styled from "styled-components";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import CustomLabels from "layouts/bookings/pages/components/CustomLabels";
import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import Calendar from "react-calendar";
import Moment from "moment/moment";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import MDBox from "components/MDBox";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "layouts/bookings/pages/components/IconButton";
import { useDispatch } from "react-redux";
import { showAlert } from "actions/alert";
import { showLoading } from "actions/loading";
import { callServicePostAuth } from "services/AuthServices";

import config from "configs/config.json";

const todayListDayilyOptions = [
  {
    id: 1,
    label: "Sot",
    key: "Today",
    value: 1,
    color: "primary",
    checked: true,
  },
  {
    id: 2,
    label: "Çdo ditë",
    key: "Everyday",
    value: 2,
    color: "secondary",
    checked: false,
  },
  // {
  //   id: 3,
  //   label: "Monday",
  //   key: "Everyday",
  //   value: 1,
  //   color: "error",
  //   checked: false,
  // },
  // {
  //   id: 4,
  //   label: "Tuesday",
  //   key: "Everyday",
  //   value: 2,
  //   color: "warning",
  //   checked: false,
  // },
  // {
  //   id: 5,
  //   label: "Wednesday",
  //   key: "Everyday",
  //   value: 4,
  //   color: "info",
  //   checked: false,
  // },
  // {
  //   id: 6,
  //   label: "Thursday",
  //   key: "Everyday",
  //   value: 8,
  //   color: "success",
  //   checked: false,
  // },
  // {
  //   id: 7,
  //   label: "Friday",
  //   key: "Everyday",
  //   value: 16,
  //   color: "default ",
  //   checked: false,
  // },
  // {
  //   id: 8,
  //   label: "Saturday",
  //   key: "Everyday",
  //   value: 32,
  //   checked: false,
  // },
  // {
  //   id: 9,
  //   label: "Sunday",
  //   key: "Everyday",
  //   value: 64,
  //   color: "error",
  //   checked: false,
  // },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 100,
    },
  },
};

const TAG = "AddTodoListModal";

function AddTodoListModal({ onClose }) {
  const [title, setTitle] = useState("");
  const [openCalendar, setOpenCalendar] = useState(false);
  const [taskDate, setTaskDate] = useState("");
  const [description, setDescription] = useState("");
  const [stateDailyOptions, setStateDailyOptions] = useState(todayListDayilyOptions);
  const [selectedHour, setSelectedHour] = useState(0);
  const [selectMins, setSelectMins] = useState(0);
  const [disableDatePicker, setDisableDatePicker] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const filteredArr = stateDailyOptions.filter((item) => item.checked === true);
    if (filteredArr.length > 0) setDisableDatePicker(true);
    else setDisableDatePicker(false);
  }, [stateDailyOptions]);

  const handleChangedDailyOptions = (id) => {
    setOpenCalendar(false);
    const tmpData = stateDailyOptions.map((item) => {
      // eslint-disable-next-line no-param-reassign
      if (item.id === id) item.checked = !item.checked;
      else {
        // eslint-disable-next-line no-param-reassign
        if (id === 1) item.checked = false;
        // eslint-disable-next-line no-param-reassign
        if (id === 2) item.checked = false;
      }

      if (id >= 3 && item.id < 3) {
        // eslint-disable-next-line no-param-reassign
        item.checked = false;
      }
      return item;
    });

    const filteredArr = stateDailyOptions.filter((item) => item.checked === true);
    if (filteredArr.length > 0) setDisableDatePicker(true);
    else setDisableDatePicker(false);
    setStateDailyOptions(tmpData);
  };

  const handleAddTodolistResponse = (res, isErr) => {
    dispatch(showLoading(false));
    if (isErr) {
      dispatch(showAlert({ type: "error", msg: res }));
      return;
    }
    dispatch(showAlert({ type: "success", msg: "Success to add a new todo" }));

    setTitle();
    setDescription();
    setTaskDate();

    onClose(true);
    console.log(res);
  };

  const handleClickedRegister = () => {
    if (!title || title === "" || !description || description === "") {
      dispatch(showAlert({ type: "error", msg: "Ju lutemi plotësoni fushat." }));
      return;
    }

    const url = config.BASE_URL + config.TODOLIST_ADD;

    let dailyOption = 0;
    for (let i = 0; i < stateDailyOptions.length; i += 1) {
      const item = stateDailyOptions[i];
      if (item.checked === true) {
        if (item.id === 1 || item.id === 2) {
          dailyOption = item.value;
          break;
        } else {
          // eslint-disable-next-line no-bitwise
          dailyOption |= item.value;
        }
      }
    }

    const strTime = `${selectedHour}:${selectMins}`;

    if (dailyOption === 0 && (!taskDate || taskDate === "")) return;

    const postData = {
      title,
      description,
      dailyOption,
      planDate: dailyOption === 0 ? Moment(taskDate, "DD.MM.YYYY").format("YYYY-MM-DD") : undefined,
      time: strTime,
    };

    // console.log(TAG, url, postData);

    // console.log(callServicePostAuth, handleAddTodolistResponse);

    console.log(TAG, "handleClickedRegister url = ", url);
    console.log(TAG, "handleClickedRegister postData = ", JSON.stringify(postData));

    dispatch(showLoading(true));

    callServicePostAuth(url, postData, handleAddTodolistResponse);
  };
  return (
    <S.Card>
      <S.Header>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          Shto detyrë të re
        </div>

        <S.CloseBtnContainer>
          <Button
            aria-label="delete"
            size="large"
            onClick={onClose}
            width={20}
            height={20}
            style={{ width: "20px", height: "20px", borderRadius: "20px", padding: 0, margin: 0 }}
          >
            <CloseIcon fontSize="large" color="white" />
          </Button>
        </S.CloseBtnContainer>
      </S.Header>
      <S.Container>
        <Grid container spacing={3} height="100%">
          <Grid item xs={12} md={12} lg={12}>
            <CustomLabels title="Titulli :" fontSize={16} fontWeight={600} important />
            <S.NoLabelTextField
              variant="filled"
              label={null}
              value={title}
              onChange={(val) => {
                setTitle(val.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <CustomLabels title="Përshkrim :" fontSize={16} fontWeight={600} important />
            <S.NoLabelTextField
              variant="filled"
              label={null}
              value={description}
              onChange={(val) => {
                setDescription(val.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={2}>
            <CustomLabels title="Ora :" fontSize={16} fontWeight={600} important />
            <div style={{ width: "100%", display: "flex" }}>
              <MDBox
                variant="contained"
                px="5px"
                borderRadius="5px"
                display="flex"
                alignItems="center"
                height="3rem"
                flex={1}
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select-standard"
                  value={selectedHour}
                  fullWidth
                  height="40px"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={(val) => setSelectedHour(val.target.value)}
                  style={{ height: 40, fontWeight: 600 }}
                  MenuProps={MenuProps}
                >
                  {Array.from(Array(24).keys()).map((item) => (
                    <MenuItem value={item} key={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </MDBox>
              <MDBox
                variant="contained"
                px="5px"
                borderRadius="5px"
                display="flex"
                alignItems="center"
                height="3rem"
                flex={1}
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select-standard"
                  value={selectMins}
                  fullWidth
                  height="40px"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={(val) => setSelectMins(val.target.value)}
                  style={{ height: 40, fontWeight: 600 }}
                  MenuProps={MenuProps}
                >
                  {Array.from(Array(12).keys()).map((item) => (
                    <MenuItem value={item * 5} key={item * 5}>
                      {item * 5}
                    </MenuItem>
                  ))}
                </Select>
              </MDBox>
            </div>
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            <CustomLabels title="Zgjedh datën :" fontSize={16} fontWeight={600} important />
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-start",
                flexWrap: "wrap",
              }}
            >
              {stateDailyOptions.map((item) => (
                <FormControlLabel
                  key={item.value}
                  value={item.value}
                  control={<Checkbox />}
                  label={item.label}
                  labelPlacement="end"
                  color={item.color ? item.color : "default"}
                  checked={item.checked}
                  onChange={() => handleChangedDailyOptions(item.id)}
                />
              ))}
              <div>
                {!disableDatePicker && (
                  <IconButton
                    onClick={() => setOpenCalendar(!disableDatePicker && !openCalendar)}
                    title={taskDate}
                    icon="calendar_month_icon"
                    imageSize="20px"
                    fontSize={14}
                    iconColor="#C20B7E"
                    textColor="gray"
                    style={{ width: "300px" }}
                  />
                )}

                {openCalendar && (
                  <div style={{ position: "relative" }}>
                    <div style={{ position: "absolute" }}>
                      <Calendar
                        useWeekdaysShort
                        onChange={(value) => {
                          setOpenCalendar(false);
                          setTaskDate(Moment(value).format("DD.MM.YYYY"));
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </S.Container>
      <S.Bottom>
        <S.Button onClick={() => handleClickedRegister()} width={200}>
          Krijo
        </S.Button>
      </S.Bottom>
    </S.Card>
  );
}

const S = {
  Card: styled(Card)`
    width: 60%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;

    @media (max-width: 1024px) {
      width: 90%;
    }
  `,
  Header: styled.div`
    height: 50px;
    color: white;
    background-color: #C20B7E;
    font-size: 1.5rem;
    font-weight: 400;
    width: 100%;
    position: relative;

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  `,
  CloseBtnContainer: styled.div`
    position: absolute;
    right: 10px;
    top: 2px;
    color: white;
  `,
  Container: styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    overflow: visible;
    @media (max-width: 1024px) {
      padding: 40px 20px;
    }
    @media (max-width: 768px) {
      padding: 20px;
    }
  `,
  NoLabelTextField: styled(TextField)`
    width: 100%;
    .MuiFilledInput-root {
      padding: 5px !important;
    }
    input {
      padding: 0 !important;
      height: 40px;
    }
    textarea {
      padding: 0 !important;
      min-height: 40px !important;
    }
  `,
  Bottom: styled.div`
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 10px;
  `,
  Button: styled(Button)`
    height: ${(props) => (props.height ? `${props.height}px` : "50px")} !important;
    width: ${(props) => (props.width ? `${props.width}px` : "100%")} !important;
    border-radius: 10px !important;
    background-color: ${(props) => (props.dark ? "gray !important" : "#C20B7E !important")};
    text-transform: none !important;
    font-size: 1rem !important;
    font-weight: 100 !important;
    color: white !important;
    margin-bottom: 2px;
    :hover {
      background-color: ${(props) => (props.dark ? "lightgray !important" : "#E560B3 !important")};
      color: white !important;
    }
  `,
};

AddTodoListModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default AddTodoListModal;
