import axios from "axios";
import config from "configs/config.json";

const FILE_TAG = "AuthServices.js";

export const getAccessToken = () => localStorage.getItem("access_token");
export const getRefreshToken = () => localStorage.getItem("refresh_token");
export const isAuthenticated = () => {
  const token = getAccessToken();
  if (token) return true;
  return false;
};

// export const refreshTokens = async () => {
//   // var url = config.BASE_URL + config.REFRESH_TOKEN;
//   return { access_token: "", refresh_token: "" };
// };
export const refreshTokens = async () => ({ access_token: "", refresh_token: "" });

export const authenticate = async () => {
  if (getRefreshToken()) {
    try {
      const tokens = await refreshTokens(); // call an API, returns tokens

      localStorage.setItem("access_token", tokens.access_token);
      localStorage.setItem("refresh_token", tokens.refresh_token);

      return true;
    } catch (error) {
      return false;
    }
  }
  return false;
};

export const getToken = async (username, password) => {
  const url = config.BASE_URL + config.GET_ACCESS_TOKEN;
  const sendData = { username, password };
  const axiosConfig = {
    method: "post",
    url,
    headers: {
      "Content-Type": "application/json",
    },
    data: sendData,
  };
  const { data } = await axios(axiosConfig);
  console.log(FILE_TAG, "tokens = ", data);
  return data;
};

// login with username and password
export const login = async (username, password) => {
  const formData = new FormData();
  formData.append("username", username);
  formData.append("password", password);

  const url = config.BASE_URL + config.LOGIN_URL;

  const axiosConf = {
    headers: { "content-type": "multipart/form-data" },
  };

  try {
    const { data } = await axios.post(url, formData, axiosConf);

    return data;
  } catch (e) {
    console.log(FILE_TAG, "loginService", e.message);
  }

  return null;
};

export const doLogout = () => {
  console.log(FILE_TAG, "doLogout");
};

export const callServiceGet = async (URL, callBackFunction) => {
  const axiosConfig = {
    method: "get",
    URL,
    headers: {
      "Content-type": "application/json",
    },
  };

  try {
    const { data } = await axios(axiosConfig);
    callBackFunction(data, false);
  } catch (e) {
    callBackFunction(e.message, true);
  }
};

export const callServicePostAuth = async (url, postData, callback) => {
  const token = localStorage.getItem("access_token");
  const authStr = `Bearer ${token}`;
  const axiosConfig = {
    method: "post",
    url,
    data: postData,
    headers: {
      Authorization: authStr,
      "Content-type": "application/json",
    },
  };

  try {
    const { data } = await axios(axiosConfig);
    callback(data, false);
  } catch (e) {
    callback(e.message, true);
  }
};

export const callServiceGetAuth = async (url, callBackFunction) => {
  const token = localStorage.getItem("access_token");
  const authStr = `Bearer ${token}`;
  const axiosConfig = {
    method: "get",
    url,
    headers: {
      Authorization: authStr,
      "Content-type": "application/json",
    },
  };

  console.log(JSON.stringify(axiosConfig));

  try {
    const { data } = await axios(axiosConfig);
    callBackFunction(data, false);
  } catch (e) {
    callBackFunction(e.message, true);
  }
};

export const callServicePostWithDataAuth = async (URL, apiBody, callBackFunction) => {
  const token = localStorage.getItem("access_token");
  const authStr = `Bearer ${token}`;
  const axiosConfig = {
    method: "post",
    headers: {
      Authorization: authStr,
    },
  };

  try {
    const { data } = await axios.post(URL, apiBody, axiosConfig);
    callBackFunction(data, false);
  } catch (e) {
    callBackFunction(e.message, true);
  }
};

// get user info by token
export const getUserInfo = async () => {
  const token = localStorage.getItem("access_token");

  const url = config.BASE_URL + config.USER_INFO;
  const authStr = `Bearer ${token}`;
  const axiosConfig = {
    method: "post",
    url,
    headers: {
      Authorization: authStr,
    },
  };

  try {
    const { data } = await axios(axiosConfig);
    console.log(FILE_TAG, "getUserInfo = ", data);
    return data;
  } catch (e) {
    console.log(FILE_TAG, "getUserInfo error", e.message);
  }

  return null;
};

// login with username and password
export const loginService = async (username, password) => {
  const formData = new FormData();
  formData.append("usernam  e", username);
  formData.append("password", password);

  const url = config.BASE_URL + config.LOGIN_URL;

  const axiosConf = {
    headers: { "content-type": "multipart/form-data" },
  };

  try {
    const { data } = await axios.post(url, formData, axiosConf);

    if (data.msg === "success") {
      const tokens = await getToken(username, password);
      if (tokens) {
        localStorage.setItem("access_token", tokens.access);
        localStorage.setItem("refresh_token", tokens.refresh);
      }
      return { msg: "success" };
    }
    return { msg: "failed", error: data.error };
  } catch (e) {
    console.log(FILE_TAG, "loginService", e.message);
  }

  return { msg: "failed", error: "unknown" };
};
