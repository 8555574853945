/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

function configs(labels, datasets, color, bgColor) {
  return {
    data: {
      labels,
      datasets: [
        {
          // label: datasets.label,
          // tension: 0,
          // // pointRadius: 5,
          // // pointBorderColor: "transparent",
          // // pointBackgroundColor: color != null ? color : "rgba(255, 255, 255, .8)",
          // borderColor: color != null ? color : "rgba(255, 255, 255, .8)",
          borderWidth: 1,
          backgroundColor: bgColor != null ? bgColor : "transparent",

          fill: "origin",
          borderColor: color != null ? color : "rgba(255, 255, 255, .8)",
          data: datasets.data,
          // maxBarThickness: 6,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      bezierCurve: true,
      elements: {
        point: {
          radius: 0,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        filler: {
          propagate: true,
        },
      },
      interaction: {
        intersect: false,
        mode: "index",
      },
      scales: {
        y: {
          type: "linear",
          grid: {
            drawBorder: false,
            display: true,
            drawOnChartArea: true,
            drawTicks: true,
            borderDash: [5, 4],
            color: "lightgray",
          },
          // ticks: [
          //   {
          //     display: true,
          //     color: color != null ? color : "#f8f9fa",
          //     padding: 10,

          //     font: {
          //       size: 14,
          //       weight: 300,
          //       family: "Poppins",
          //       style: "normal",
          //       lineHeight: 2,
          //     },
          //   },
          // ],
          ticks: {
            display: true,
            color: color != null ? color : "#f8f9fa",
            padding: 10,

            font: {
              size: 14,
              weight: 300,
              family: "Poppins",
              style: "normal",
              lineHeight: 2,
            },
            callback: (value, index, ticks) => {
              console.log(value, index, ticks);
              // if (value <= 25) return "0~25%";
              // if (value <= 50) return "25~50%";
              // if (value <= 75) return "50~75%";
              // if (value <= 100) return "75~100%";

              return `${value}%`;
            },
          },
        },
        x: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
            borderDash: [5, 5],
          },
          ticks: {
            display: true,
            color: color != null ? color : "#f8f9fa",
            padding: 10,
            font: {
              size: 14,
              weight: 300,
              family: "Poppins",
              style: "normal",
              lineHeight: 2,
            },
          },
        },
      },
    },
  };
}

export default configs;
