import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import Select from "react-select";
import styled from "styled-components";

function TimeSelecter({ initTime, onChanged }) {
  const hours = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22];
  const mins = [0, 15, 30, 45];
  const [currentHour, setCurrentHour] = useState(8);
  const [currentMin, setCurrentMin] = useState(0);

  const doubleDigitNumber = (num) => {
    let strNum = num.toFixed();
    if (strNum.length < 2) strNum = `0${strNum} `;
    return strNum;
  };
  useEffect(() => {
    if (initTime) {
      const hm = initTime.split(":");
      setCurrentHour(parseInt(hm[0], 10));
      setCurrentMin(parseInt(hm[1], 10));
    }
  }, initTime);
  return (
    <div style={{ display: "flex" }}>
      <S.Select
        className="basic-single"
        classNamePrefix="select"
        isClearable
        isSearchable
        placeholder="Zgjedh..."
        dropdown
        menuPlacement="top"
        value={hours
          .map((item) => ({ value: item, label: doubleDigitNumber(item) }))
          .filter((item) => item.value === currentHour)}
        onChange={(value) => {
          setCurrentHour(value ? value.value : 8);
          onChanged(
            `${doubleDigitNumber(value ? value.value : 8)}:${doubleDigitNumber(currentMin)}`
          );
        }}
        options={hours.map((item) => ({ value: item, label: doubleDigitNumber(item) }))}
        style={{ backgroundColor: "rgba(0, 0, 0, 0.2)", marginRight: 10 }}
      />{" "}
      :
      <S.Select
        className="basic-single"
        classNamePrefix="select"
        isClearable
        placeholder="Zgjedh..."
        isSearchable
        dropdown
        menuPlacement="top"
        value={mins
          .map((item) => ({ value: item, label: doubleDigitNumber(item) }))
          .filter((item) => item.value === currentMin)}
        onChange={(value) => {
          setCurrentMin(value ? value.value : 0);
          onChanged(
            `${doubleDigitNumber(currentHour)}:${doubleDigitNumber(value ? value.value : 8)}`
          );
        }}
        options={mins.map((item) => ({ value: item, label: doubleDigitNumber(item) }))}
        style={{ backgroundColor: "rgba(0, 0, 0, 0.2)", marginRight: 10 }}
      />
    </div>
  );
}

TimeSelecter.propTypes = {
  initTime: PropTypes.number.isRequired,
  onChanged: PropTypes.func.isRequired,
};

const S = {
  Select: styled(Select)`
    flex: 1;

    border-radius: 0px;
    font-size: 1rem !important;

    .select__control {
      box-shadow: unset;
      background-color: rgba(0, 0, 0, 0.06);
      border-radius: 0px;
      min-height: 50px !important;
      font-size: 1rem;
      border: none;
      border-bottom: solid 1px #000000de;
      :hover {
        border: none;
        border-bottom: solid 1px #000000de;
      }
      :focuse {
        border: none;
      }
      :actived {
        border: none;
      }
    }
    .select__multi-value {
      border: 1px solid #c20b7e;
    }
    .select__indicator {
      color: #c20b7e !important;
    }
    .select__indicator-separator {
      display: none !important;
    }
  `,
};
export default TimeSelecter;
