import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import styled from "styled-components";
// import IconButton from "layouts/bookings/pages/components/IconButton";
import Calendar from "react-calendar";
import { useState, useEffect } from "react";
import Moment from "moment/moment";
import Select from "react-select";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CustomLabels from "layouts/bookings/pages/components/CustomLabels";
import config from "configs/config.json";
import { callServiceGetAuth, callServicePostAuth } from "services/AuthServices";
import { useDispatch } from "react-redux";
import { showAlert } from "actions/alert";

const TAG = "UserIncomePage";

function UserIncomePage() {
  const dispatch = useDispatch();

  //   const [openCalendar, setOpenCalendar] = useState(false);
  //   const [selectedDate, setSelectedDate] = useState("");
  const [staff, setStaff] = useState();
  const [client, setClient] = useState();
  const [price, setPrice] = useState();
  const [allStaffs, setAllStaffs] = useState([]);
  const [allCustomers, setAllCustomers] = useState([]);

  const handleGetAllStaffResponse = async (res, isError) => {
    if (isError) {
      dispatch(showAlert({ type: "error", msg: res }));
      return;
    }
    setAllStaffs(res);
  };

  const callAPIGetAllStaffs = () => {
    const url = config.BASE_URL + config.GET_ALL_STAFFS;
    console.log(TAG, "callAPIGetAllStaffs url = ", url);
    callServiceGetAuth(url, handleGetAllStaffResponse);
  };

  const convertData2Option = (data) =>
    data.map((item) => ({
      value: item.id,
      label: `${item.name} ${item.surname ? item.surname : ""}`,
    }));

  useEffect(() => {
    callAPIGetAllStaffs();

    console.log(TAG, "tmpt", staff, client);
  }, []);

  const handleAddIncomeResponse = async (res, isErr) => {
    if (isErr) {
      dispatch(showAlert({ type: "error", msg: res }));
      return;
    }
    if (res.result === "success") {
      dispatch(showAlert({ type: "success", msg: res.msg }));
    }
    if (res.result !== "success") {
      dispatch(showAlert({ type: "error", msg: res.msg }));
    }
  };
  const callApiAddIncoms = async () => {
    const url = config.BASE_URL + config.ADD_INCOMES;
    const postData = {
      staff: staff.value,
      client: client.value,
      date: Moment(new Date()).format("YYYY-MM-DD"),
      price: parseFloat(price),
    };
    console.log(TAG, "callApiAddIncoms url = ", url);
    console.log(TAG, "callApiAddIncoms postData = ", JSON.stringify(postData));

    callServicePostAuth(url, postData, handleAddIncomeResponse);
  };

  const handleClickedAddIncome = () => {
    if (!staff || !client || !price || price.length === 0 || parseFloat(price) <= 0) {
      dispatch(showAlert({ type: "error", msg: "Ju lutemi plotësoni fushat" }));
      return;
    }
    callApiAddIncoms();
  };

  const handleGetClientByStaffResponse = async (res, isErr) => {
    if (isErr) {
      dispatch(showAlert({ type: "error", msg: res }));
      return;
    }

    if (res.result !== "success") {
      dispatch(showAlert({ type: "errpr", msg: res.msg }));
      return;
    }

    if (res.data && res.data.length > 0) {
      const bookings = res.data;
      const clients = bookings.map((item) => ({
        id: item.fields.client.pk,
        name: `${item.fields.client.name} ${item.fields.client.surname}`,
      }));

      const uniqClients = [...new Set(clients)];

      const tmpData = convertData2Option(uniqClients);
      setClient(tmpData[0]);
      setAllCustomers(tmpData);
    }
  };

  const callApiGetClientByStaff = async (s) => {
    const url = config.BASE_URL + config.SEARCH_CLIENT_FOR_STAFF_TODAY;

    const postData = {
      staff_id: s.value,
      date: Moment(new Date()).format("YYYY-MM-DD"),
    };

    callServicePostAuth(url, postData, handleGetClientByStaffResponse);
  };

  const handleChangedStaff = (value) => {
    if (value) {
      // call clients related to staf today
      callApiGetClientByStaff(value);
    }
    setStaff(value);
  };
  return (
    <DashboardLayout style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <DashboardNavbar />
      <MDBox
        py={3}
        ml={3}
        mr={3}
        mt={3}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <S.MainContainer>
          <S.Header>
            <Icon fontSize="large">money_outlined</Icon>
            Hyrje
          </S.Header>
          <S.Container>
            <S.BottomContainer
              style={{ position: "relative", overflow: "visible", flexDirection: "column" }}
            >
              {/* <IconButton
                onClick={() => setOpenCalendar(!openCalendar)}
                title={selectedDate}
                icon="calendar_month_icon"
                imageSize="30px"
                fontSize="24px"
                iconColor="#c20b7e"
                textColor="gray"
              />
              <div style={{ position: "relative", width: "100%" }}>
                {openCalendar && (
                  <S.Calendar
                    useWeekdaysShort
                    onChange={(value) => {
                      setSelectedDate(Moment(value).format("DD.MM.YYYY"));
                      setOpenCalendar(!openCalendar);
                    }}
                  />
                )}
              </div> */}
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderColor: "lightgray",
                  alignItems: "center",
                  borderRadius: 5,
                  backgroundColor: "rgba(0,0,0,0.06)",
                }}
              >
                <div style={{ color: "#c20b7e", display: "flex", alignItems: "center" }}>
                  <Icon fontSize="large">calendar_month_icon</Icon>
                </div>
                <div
                  style={{
                    color: "#c20b7e",
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  {Moment(new Date()).format("DD.MM.YYYY")}
                </div>
              </div>
            </S.BottomContainer>
            <S.BottomContainer>
              <CustomLabels title="Stafi" fontSize={16} important />
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderColor: "lightgray",
                  alignItems: "center",
                  borderRadius: 5,
                  backgroundColor: "rgba(0,0,0,0.06)",
                }}
              >
                <div style={{ color: "#c20b7e", display: "flex", alignItems: "center" }}>
                  <Icon fontSize="large">person</Icon>
                </div>
                <S.Select
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable
                  placeholder="Zgjedh..."
                  isSearchable
                  onChange={(val) => handleChangedStaff(val)}
                  options={convertData2Option(allStaffs)}
                  style={{ height: 50 }}
                />
              </div>
            </S.BottomContainer>
            <S.BottomContainer>
              <CustomLabels title="klientit" fontSize={16} important />
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderColor: "lightgray",
                  borderRadius: 5,
                  backgroundColor: "rgba(0,0,0,0.06)",
                  paddingLeft: 10,
                  paddingRight: 10,
                  alignItems: "center",
                }}
              >
                <div style={{ color: "#c20b7e", display: "flex", alignItems: "center" }}>
                  <Icon fontSize="large">group</Icon>
                </div>
                <S.Select
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable
                  placeholder="Zgjedh..."
                  isSearchable
                  value={client}
                  onChange={(val) => setClient(val)}
                  options={allCustomers}
                  style={{ height: 50 }}
                />
              </div>
            </S.BottomContainer>
            <S.BottomContainer>
              <S.NoLabelTextField
                id="filled-basic-username"
                variant="filled"
                label={null}
                onChange={(e) => setPrice(e.target.value)}
                style={{
                  width: "100%",
                }}
              />
            </S.BottomContainer>
            <S.BottomContainer>
              <S.Button variant="contained" onClick={handleClickedAddIncome}>
                Regjistro
              </S.Button>
            </S.BottomContainer>
          </S.Container>
        </S.MainContainer>
      </MDBox>
    </DashboardLayout>
  );
}

const S = {
  MainContainer: styled(Card)`
    width: 400px;
    height: 600px;
    @media (max-width: 1024px) {
      width: 90%;
      height: 100%;
    }
  `,
  Header: styled.div`
    height: 50px;
    background-color: #c20b7e;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: relative;
    display: flex;
    align-items: center;
    color: white;
    font-size: 16px;
    font-weight: 600;
    padding-left: 20px;
  `,
  Container: styled.div`
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    flex: 1;
  `,
  BottomContainer: styled.div`
    height: 80px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
  `,
  Calendar: styled(Calendar)`
    margin: 0px !important;
    position: absolute !important;
    .react-calendar {
      margin: 0px !important;
    }
  `,
  Select: styled(Select)`
    flex: 1;
    min-height: 50px !important;
    width: 100%;
    border: none;
    font-size: 14px;
    display: flex;
    alignitems: center;
    justify-content: space-between;
    .select__control {
      box-shadow: unset;
      width: 100%;
      background-color: transparent;
      border: none;
      :hover {
        border: none;
      }
      :focuse {
        border: none;
      }
      :actived {
        border: none;
      }
    }
    .select__multi-value {
      border: 1px solid #c20b7e;
    }
    .select__indicator {
      color: #c20b7e !important;
    }
    .select__indicator-separator {
      display: none !important;
    }
  `,
  NoLabelTextField: styled(TextField)`
    width: 100%;
    .MuiFilledInput-root {
      padding: 5px !important;
    }
    input {
      padding: 0 !important;
      text-align: center;
      color: #c20b7e;
      height: 50px;
      font-size: 16px;
    }
  `,
  Button: styled(Button)`
    width: 100%;
    border-radius: 50px !important;
    height: 50px !important;
    background-color: #ff0c8c !important;
    font-size: 16px !important;
    font-family: Rubik !important;
    @media (max-width: 1024px) {
      font-size: 16px !important;
    }
    color: white !important;
  `,
};
export default UserIncomePage;
