import { useState, useEffect } from "react";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import { FormControlLabel } from "@material-ui/core";
import MDBox from "components/MDBox";
// import Checkbox from "@mui/material/Checkbox";
import config from "configs/config.json";
import { useDispatch } from "react-redux";
import { showAlert } from "actions/alert";
import { showLoading } from "actions/loading";
import { callServiceGetAuth, callServicePostAuth } from "services/AuthServices";
import Card from "@mui/material/Card";
// import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import styled from "styled-components";
import Icon from "@mui/material/Icon";
import Select from "react-select";
import DataTable from "components/DataTable";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import logoImg from "assets/images/main-logo-color.png";
import CreateEditStudio from "./createeditstudio";

const TAG = "SalesReport";

// const optionReports = [
//   { value: "pdf", label: "PDF" },
//   { value: "xls", label: "XLS" },
// ];

const columns = [
  { Header: "Emri ", accessor: "name", align: "center", fontSize: "1rem" },
  { Header: "Adresa", accessor: "address", align: "center", fontSize: "1rem" },
  { Header: "Koha e hapjes", accessor: "openingtime", align: "center", fontSize: "1rem" }, // Opening Time
  { Header: "Koha e mbylljes", accessor: "closingtime", align: "center", fontSize: "1rem" }, // Closed Time
  { Header: "Status", accessor: "status", align: "center", fontSize: "1rem" }, // normal, maintenance, closed,
  {
    Header: "Edito",
    accessor: "edit",
    align: "center",
    fontSize: "1rem",
    width: 30,
    padding: 0,
    px: 1,
  },
  // {
  //   Header: "Fshij",
  //   accessor: "delete",
  //   align: "center",
  //   fontSize: 20,
  //   width: 30,
  //   padding: 0,
  //   px: 1,
  // },
];

function Studios() {
  const [dataRows, setDataRows] = useState([]);

  const [initialStudios, setInitialStudio] = useState();

  const dispatch = useDispatch();
  // const [reportFileType, setReportFileType] = useState("pdf");

  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedStudio, setSelectedStudio] = useState(null);

  const [deleteDlgOpen, setDeleteDlgOpen] = useState(false);
  const [selectedId2Delete, setSelectedId2Delete] = useState();

  const handleGetAllIndivitualResponse = async (res, isErr) => {
    dispatch(showLoading(false));
    if (isErr) {
      dispatch(showAlert({ type: "error", msg: res }));
      return;
    }

    console.log("data", res.data);

    if (res.result === "success" && res.data) {
      console.log(">>>>>", res.data.studios);
      setInitialStudio(res.data.studios);
    }
  };

  const callAPIGetAllIndividuals = async () => {
    const url = config.BASE_URL + config.GET_ALL_INDIVITIAL_INFOS;
    console.log(TAG, "callAPIGetAllIndividuals url = ", url);

    dispatch(showLoading(true));

    callServiceGetAuth(url, handleGetAllIndivitualResponse);
  };
  const renderEditBtn = ({ id, onClicked }) => (
    <div
      onClick={() => onClicked(id)}
      onKeyDown={() => onClicked(id)}
      role="button"
      tabIndex="0"
      style={{
        width: 20,
        height: 20,
        cursor: "pointer",
      }}
    >
      <Icon fontSize="medium">edit_note</Icon>
    </div>
  );

  const renderDeleteBtn = ({ id, onClicked }) => (
    <div
      onClick={() => onClicked(id)}
      onKeyDown={() => onClicked(id)}
      role="button"
      tabIndex="0"
      style={{
        borderRadius: 5,
        width: 20,
        height: 20,
        cursor: "pointer",
      }}
    >
      <Icon fontSize="medium">delete_outline_icon</Icon>
    </div>
  );

  // const handleClickedPrint = () => {
  //   if (reportFileType === "pdf") {
  //     console.log("print");
  //   } else {
  //     console.log("print2222");
  //   }
  // };

  const callbackEditBtn = (id) => {
    const studio = initialStudios.filter((item) => item.pk === id);

    console.log("edit", id, studio[0]);
    setSelectedStudio(studio[0]);
    setOpenEditModal(true);
  };

  const handleDeleteStudioResponse = async (res, isErr) => {
    dispatch(showLoading(false));
    if (isErr) {
      dispatch(showAlert({ type: "error", msg: res }));
      return;
    }

    if (res.result === "failed") {
      dispatch(showAlert({ type: "error", msg: res }));
    }

    callAPIGetAllIndividuals();
    setDeleteDlgOpen(false);
    setSelectedId2Delete();
  };

  const callApiDeleteStudio = async () => {
    const url = config.BASE_URL + config.DELETE_STUDIO;

    const postData = {
      id: selectedId2Delete,
    };

    dispatch(showLoading(true));
    callServicePostAuth(url, postData, handleDeleteStudioResponse);
  };

  const handleClickDeleteBtn = () => {
    callApiDeleteStudio();
  };

  const callbackDeleteBtn = (id) => {
    // callApiDeleteStudio(id);
    setDeleteDlgOpen(true);
    setSelectedId2Delete(id);
  };

  //
  useEffect(() => {
    // import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
    if (!initialStudios) callAPIGetAllIndividuals();
  }, []);

  const getStatusStr = (s) => {
    switch (s) {
      case 0:
        return "Mbyllur";
      case 1:
        return "Hapur";

      case 2:
        return "Mirëmbajtja";

      default:
        return "i panjohur";
    }
  };

  useEffect(() => {
    if (initialStudios && initialStudios.length > 0) {
      //     { Header: "Emri ", accessor: "name", align: "center", fontSize: "1rem" },
      // { Header: "Address", accessor: "address", align: "center", fontSize: "1rem" },
      // { Header: "OpeningTime", accessor: "openingtime", align: "center", fontSize: "1rem" },
      // { Header: "ClosingTime", accessor: "closingtime", align: "center", fontSize: "1rem" },
      // { Header: "Status", accessor: "status", align: "center", fontSize: "1rem" },
      let rows = initialStudios.map((item) => {
        console.log("item", item);
        if (!item) return null;
        const row = {};
        row.name = <div style={{ wordSpacing: "break", width: 150 }}>{item.fields.name}</div>;
        row.address = <div style={{ wordSpacing: "break", width: 150 }}>{item.fields.address}</div>;
        row.openingtime = item.fields.openingtime;
        row.closingtime = item.fields.closingtime;
        row.status = getStatusStr(item.fields.status);
        row.edit = renderEditBtn({ id: item.pk, onClicked: callbackEditBtn });
        row.delete = renderDeleteBtn({ id: item.pk, onClicked: callbackDeleteBtn });

        return row;
      });
      rows = rows.filter((item) => item != null);
      console.log(rows);
      setDataRows(rows);
    }
    //   }, [initialStudios, visibleMode, selectedStudioFilter]);
  }, [initialStudios]);

  const handleCloseDlg = () => {
    setDeleteDlgOpen(false);
  };

  const handleCloseEditModal = () => {
    callAPIGetAllIndividuals();
    setOpenEditModal(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Dialog
        open={deleteDlgOpen}
        onClose={handleCloseDlg}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Konfirmo fshirjen</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Jeni i sigurt që dëshironi ta fshini këtë? Ky veprim nuk mund të zhbëhet.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDlg}>Anulo</Button>
          <S.Button
            variant="contained"
            color="success"
            onClick={handleClickDeleteBtn}
            autoFocus
            width={100}
          >
            Fshije
          </S.Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={openEditModal}
        onClose={handleCloseEditModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CreateEditStudio selectedItem={selectedStudio} onClose={handleCloseEditModal} />
      </Modal>
      <MDBox
        pt={1}
        pb={1}
        px={1}
        style={{
          minHeight: "80vh",
          height: "calc(100% - 80px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "auto",
        }}
      >
        <S.Card>
          <S.HeaderContainer>
            <img src={logoImg} alt="logo" />
            <div
              style={{
                width: "100%",
                color: "#c20b7e",
                marginTop: 20,
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <span style={{ color: "#c20b7e", marginTop: 20 }}>Studios</span>
              {/* <div>
                <S.Button
                  onClick={() => {
                    setSelectedStudio(null);
                    setOpenEditModal(true);
                  }}
                  width={100}
                  height={30}
                >
                  Krijo
                </S.Button>
              </div> */}
            </div>
          </S.HeaderContainer>
          <S.ServicesContainer>
            <S.GradientBg />
            {/* <S.SearchFilters>
              <Grid container space={3}>
                <Grid item xs={12} md={6} lg={3}>
                  <S.FilterCellContainer>
                    <FormControlLabel
                      label="Shfaq punëtorët aktiv:"
                      control={
                        <Checkbox
                          sx={{
                            color: "#C20B7E80",
                            "&.Mui-checked": {
                              color: "#c20b7e",
                            },
                          }}
                          checked={visibleMode === 1}
                          onChange={handleClickCheckShowOnlyActived}
                        />
                      }
                      labelPlacement="start"
                    />
                  </S.FilterCellContainer>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <S.FilterCellContainer>
                    <FormControlLabel
                      label="Shfaq punëtorët jo aktiv:"
                      control={
                        <Checkbox
                          sx={{
                            color: "#C20B7E80",
                            "&.Mui-checked": {
                              color: "#c20b7e",
                            },
                          }}
                          checked={visibleMode === 2}
                          onChange={handleClickCheckShowOnlyNotActived}
                        />
                      }
                      labelPlacement="start"
                    />
                  </S.FilterCellContainer>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <S.FilterCellContainer>
                    <FormControlLabel
                      label="Shfaqtë gjithë punëtorët:"
                      control={
                        <Checkbox
                          sx={{
                            color: "#C20B7E80",
                            "&.Mui-checked": {
                              color: "#c20b7e",
                            },
                          }}
                          checked={visibleMode === 0}
                          onChange={handleClickCheckShowAll}
                        />
                      }
                      labelPlacement="start"
                    />
                  </S.FilterCellContainer>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <S.FilterCellContainer>
                    <FormControlLabel
                      label="Kërko sipas studios:  "
                      control={
                        <div style={{ paddingLeft: 10 }}>
                          <S.SelectFilter
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable
                            isSearchable
                            dropdown
                            onChange={(value) =>
                              setSelectedStudioFilter(value ? value.value : null)
                            }
                            options={initialStudio}
                            style={{
                              backgroundColor: "rgba(0, 0, 0, 0.2)",
                              marginLeft: 10,
                              height: "1rem",
                              fontSize: "0.7rem",
                            }}
                          />
                        </div>
                      }
                      labelPlacement="start"
                    />
                  </S.FilterCellContainer>
                </Grid>
              </Grid>
            </S.SearchFilters> */}
            <S.ServicesContent>
              <S.DataTable
                id="table"
                table={{ rows: dataRows, columns }}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={false}
              />
            </S.ServicesContent>
          </S.ServicesContainer>
          {/* <S.BottomContainer>
            <div
              style={{
                width: 350,
                height: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                position: "relative",
              }}
            >
              <S.Select
                className="basic-single"
                classNamePrefix="select"
                isClearable
                isSearchable
                dropdown
                menuPlacement="top"
                value={optionReports.filter((item) => item.value === reportFileType)[0]}
                onChange={(value) => setReportFileType(value ? value.value : null)}
                options={optionReports}
                style={{ backgroundColor: "rgba(0, 0, 0, 0.2)", marginRight: 10 }}
              />
              <div style={{ width: 10 }} />
              <S.Button onClick={handleClickedPrint} width={200}>
                Report
              </S.Button>
            </div>
          </S.BottomContainer> */}
        </S.Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

const S = {
  Card: styled(Card)`
    width: 95%;
    min-height: 95%;
    overflow: auto;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    @media (max-width: 1024px) {
      width: 90%;
      min-height: 95%;
    }
  `,
  Conainer: styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
  `,
  CloseBtnDiv: styled.div`
    position: absolute;
    top: 0px;
    right: 20px;
  `,

  HeaderContainer: styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
    img {
      width: 330px;
      height: 100px;
      @media (max-width: 768px) {
        width: 200px;
        height: 60px;
      }
    }
  `,
  CustomerInfoArea: styled.div`
    display: grid !important;
    grid-template-columns: repeat(7, 1fr) !important;
    margin-right: 20px !important;
    grid-gap: 10px !important;
    border-bottom: none !important;
    max-height: none !important;
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
    @media (max-width: 1024px) {
      grid-template-columns: repeat(2, 1fr) !important;
    }
  `,
  CustomerInfoCell: styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    align-items: center;
  `,
  CustomerInfoCelData: styled.div`
    height: 40px;
    border-radius: ${(props) => (props.borderRadius ? `${props.borderRadius}px` : "40px")};
    background-color: rgba(0, 0, 0, 0.06);
    width: ${(props) => (props.auto ? "auto" : "100%")};

    color: #c20b7e;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
  `,
  ServicesContainer: styled.div`
    position: relative;
    padding-top: 20px;
    flex: 1;
    min-height: 400px;
    backgroundcolor: transparent;
    overflow: auto;
  `,

  SearchFilters: styled.div`
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
  `,
  ServicesContent: styled.div`
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
  `,

  GradientBg: styled.div`
    position: absolute;
    background: white;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0.03) 20%, white 50%);
  `,
  SearchLeftPan: styled.div`
    display: flex;
    flex: 1;
  `,
  SearchRightPan: styled.div`
    display: flex;
    flex: 1;
    @media (max-width: 1024px) {
      display: none;
    }
  `,

  SelectFilter: styled(Select)`
    flex: 1;

    border-radius: 10px;
    font-size: 1rem !important;

    .select__control {
      box-shadow: unset;
      background-color: rgba(0, 0, 0, 0.06);
      border-radius: 10px;
      min-height: 30px !important;
      font-size: 1rem;
      border: none;
      :hover {
        border: none;
      }
      :focuse {
        border: none;
      }
      :actived {
        border: none;
      }
    }
    .select__multi-value {
      border: 1px solid #c20b7e;
    }
    .select__indicator {
      color: #c20b7e !important;
    }
    .select__indicator-separator {
      display: none !important;
    }
  `,

  Select: styled(Select)`
    flex: 1;
    min-height: 50px !important;
    border-radius: 10px;
    font-size: 1rem !important;

    .select__control {
      box-shadow: unset;
      background-color: rgba(0, 0, 0, 0.06);
      border-radius: 10px;
      min-height: 50px !important;
      border: none;
      :hover {
        border: none;
      }
      :focuse {
        border: none;
      }
      :actived {
        border: none;
      }
    }
    .select__multi-value {
      border: 1px solid #c20b7e;
    }
    .select__indicator {
      color: #c20b7e !important;
    }
    .select__indicator-separator {
      display: none !important;
    }
  `,
  Button: styled(Button)`
    height: ${(props) => (props.height ? `${props.height}px` : "50px")} !important;
    width: ${(props) => (props.width ? `${props.width}px` : "100%")} !important;
    border-radius: 10px !important;
    background-color: ${(props) => (props.dark ? "gray !important" : "#c20b7e !important")};
    text-transform: none !important;
    font-size: 1rem !important;
    font-weight: 100 !important;
    color: white !important;
    margin-bottom: 2px;
    :hover {
      background-color: ${(props) => (props.dark ? "lightgray !important" : "#E560B3 !important")};
      color: white !important;
    }
  `,
  BottomContainer: styled.div`
    height: 80px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
  `,
  DataTable: styled(DataTable)`
    .MuiTableContainer-root {
      table {
        min-width: 1200px !important;
      }
    }
    .MuiTable-root {
      thead {
        background-color: #c20b7e !important;
        color: white !important;
      }
      table {
        min-width: 800px !important;
      }
    }
  `,
  FilterCellContainer: styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
  `,
};

export default Studios;
