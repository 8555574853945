import { useState, useEffect } from "react";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import FormControlLabel from "@mui/material/FormControlLabel";
import { FormControlLabel } from "@material-ui/core";
import MDBox from "components/MDBox";
import Checkbox from "@mui/material/Checkbox";
import config from "configs/config.json";
import { useDispatch } from "react-redux";
import { showAlert } from "actions/alert";
import { showLoading } from "actions/loading";
import { callServiceGetAuth, callServicePostAuth } from "services/AuthServices";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import styled from "styled-components";
import Icon from "@mui/material/Icon";
import Select from "react-select";
import DataTable from "components/DataTable";
import logoImg from "assets/images/main-logo-color.png";
import Moment from "moment/moment";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import XLSX from "sheetjs-style";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import CreateEditStaff from "./createeditstaff";

const TAG = "SalesReport";

const optionReports = [
  { value: "pdf", label: "PDF" },
  { value: "xls", label: "XLS" },
];

const columns = [
  { Header: "Emri & mbiemri", accessor: "name", align: "center", fontSize: "1rem" },
  { Header: "Telefoni", accessor: "phone", align: "center", fontSize: "1rem" },
  { Header: "Pozita", accessor: "position", align: "center", fontSize: "1rem" },
  { Header: "Studio", accessor: "studio", align: "center", fontSize: "1rem" },
  { Header: "Data e fillimit", accessor: "startedDate", align: "center", fontSize: "1rem" },
  {
    Header: "Data e mbarimi",
    accessor: "endedDate",
    align: "center",
    fontSize: "1rem",
  },
  {
    Header: "Pagesa",
    accessor: "payment",
    align: "center",
    fontSize: "1rem",
    width: 30,
  },
  {
    Header: "Aktiv",
    accessor: "active",
    align: "center",
    fontSize: "1rem",
    width: 30,
    px: 1,
  },
  {
    Header: "Edito",
    accessor: "edit",
    align: "center",
    fontSize: "1rem",
    width: 30,
    padding: 0,
    px: 1,
  },
  {
    Header: "Fshij",
    accessor: "delete",
    align: "center",
    fontSize: 20,
    width: 30,
    padding: 0,
    px: 1,
  },
];

function Staffs() {
  const [dataRows, setDataRows] = useState([]);
  const [initialStaffs, setInitialStaffs] = useState();
  const [initialStudio, setInitialStudio] = useState();

  const dispatch = useDispatch();
  const [reportFileType, setReportFileType] = useState("pdf");
  const [visibleMode, setVisibleMode] = useState(0);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [selectedStudioFilter, setSelectedStudioFilter] = useState();

  const [deleteDlgOpen, setDeleteDlgOpen] = useState(false);
  const [selectedId2Delete, setSelectedId2Delete] = useState();

  const convertData2Option = (data) => {
    console.log("-------------->", data);
    return data.map((item) => ({
      value: item.fields.name,
      label: item.fields.name,
      id: item.pk,
    }));
  };
  const handleGetAllIndivitualResponse = async (res, isErr) => {
    dispatch(showLoading(false));
    if (isErr) {
      dispatch(showAlert({ type: "error", msg: res }));
      return;
    }

    console.log("data", res.data);

    if (res.result === "success" && res.data) {
      // setIndividualData(res.data);
      // console.log(TAG, "response = ", res.data);
      // setInitialService(convertData2Option(res.data.services));
      setInitialStaffs(res.data.staffs);
      setInitialStudio(convertData2Option(res.data.studios));
    }
  };

  const callAPIGetAllIndividuals = async () => {
    const url = config.BASE_URL + config.GET_ALL_INDIVITIAL_INFOS;
    console.log(TAG, "callAPIGetAllIndividuals url = ", url);

    dispatch(showLoading(true));

    callServiceGetAuth(url, handleGetAllIndivitualResponse);
  };

  const renderActiveBtn = ({ id, actived, onClicked }) => (
    <Checkbox
      sx={{
        color: "#C20B7E80",
        "&.Mui-checked": {
          color: "#C20B7E",
        },
      }}
      checked={actived}
      onChange={(e) => onClicked(id, e)}
    />
  );
  const renderEditBtn = ({ id, onClicked }) => (
    <div
      onClick={() => onClicked(id)}
      onKeyDown={() => onClicked(id)}
      role="button"
      tabIndex="0"
      style={{
        width: 20,
        height: 20,
        cursor: "pointer",
      }}
    >
      <Icon fontSize="medium">edit_note</Icon>
    </div>
  );

  const renderDeleteBtn = ({ id, onClicked }) => (
    <div
      onClick={() => onClicked(id)}
      onKeyDown={() => onClicked(id)}
      role="button"
      tabIndex="0"
      style={{
        borderRadius: 5,
        width: 20,
        height: 20,
        cursor: "pointer",
      }}
    >
      <Icon fontSize="medium">delete_outline_icon</Icon>
    </div>
  );

  const handleClickedPrint = () => {
    if (reportFileType === "pdf") {
      const customerHeader = [
        {
          name: "EMRI & MBIEMRI",
          phone: "TELEFONI",
          position: "POZITA",
          studio: "STUDIO",
          startedDate: "DATA E FILLIMIT",
          endedDate: "DATA E MBARIMI",
          salary: "PAGESA",
          actived: "AKTIV",
        },
      ];

      const customData = initialStaffs.map((item) => {
        let payment = "";
        if (
          item.fields.salary_fixed &&
          item.fields.salary_fixed > 0 &&
          item.fields.salary_percent &&
          item.fields.salary_percent > 0
        ) {
          payment = `${item.fields.salary_fixed}€ + ${item.fields.salary_percent}%`;
        } else if (item.fields.salary_fixed && item.fields.salary_fixed > 0) {
          payment = `${item.fields.salary_fixed}€(fikse)`;
        } else if (item.fields.salary_percent && item.fields.salary_percent > 0) {
          payment = `${item.fields.salary_percent}%(të qarkullimit individual)`;
        }

        return {
          name: item.fields.name,
          phone: item.fields.phone,
          position: item.fields.position,
          studio: item.fields.studio.name,
          startedDate: item.fields.started_date,
          endedDate: item.fields.ended_date,
          salary: payment,
          actived: item.fields.actived ? "Actived" : "",
        };
      });

      const doc = new jsPDF("l", "mm", "a4");

      const img = new Image();
      img.src = logoImg;
      doc.addImage(img, "png", 120, 10, 65, 20);

      doc.setFontSize(16);
      // doc.setFontStyle("arial");

      doc.text("Stafi", 10, 30);
      const dim = doc.getTextDimensions("Staff details.");
      doc.setFontSize(12);
      autoTable(doc, {
        head: customerHeader,
        body: customData,
        startY: dim.h + 30,
        showFoot: "everyPage",
        margin: {
          bottom: 20,
        },
        headStyles: {
          backgroundColor: "#C20B7E",
          fillColor: "#C20B7E",
        },
        /* apply styling to table body */
        bodyStyles: {
          valign: "top",
        },
        /* apply global styling */
        styles: {
          cellWidth: "wrap",
          rowPageBreak: "auto",
          halign: "justify",
        },
        /* apply styling specific to table columns */
        columnStyles: {
          text: {
            cellWidth: "auto",
          },
        },
      });

      const pageCount = doc.internal.getNumberOfPages();
      console.log("page count = ", pageCount);
      for (let i = 1; i <= pageCount; i += 1) {
        // Go to page i
        doc.setPage(i);
        // Print Page 1 of 4 for example
        doc.setFontSize(7);
        doc.text(
          ` Të gjitha të drejtat e këtij dokumenti janë të rezervuara nga Passion. Nuk lejohet kopjimi, duplikimi, citimi i tij në asnjë rast pa lejen me shkrim.`,
          doc.internal.pageSize.width / 2,
          198,
          {
            align: "center",
          }
        );

        const dim2 = doc.getTextDimensions("Staff details.");

        doc.text(`www.bepassion.com`, doc.internal.pageSize.width / 2, 198 + dim2.h, {
          align: "center",
        });
      }

      doc.save(`stafis - ${Moment(new Date()).format("DD.MM.YYYY")}.pdf`);
    } else {
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet([]);
      XLSX.utils.book_append_sheet(wb, ws, "sheet1");

      const clientDetail = initialStaffs.map((item) => {
        let payment = "";
        if (
          item.fields.salary_fixed &&
          item.fields.salary_fixed > 0 &&
          item.fields.salary_percent &&
          item.fields.salary_percent > 0
        ) {
          payment = `${item.fields.salary_fixed}€ + ${item.fields.salary_percent}%`;
        } else if (item.fields.salary_fixed && item.fields.salary_fixed > 0) {
          payment = `${item.fields.salary_fixed}€(fikse)`;
        } else if (item.fields.salary_percent && item.fields.salary_percent > 0) {
          payment = `${item.fields.salary_percent}%(të qarkullimit individual)`;
        }
        return {
          "EMRI & MBIEMRI": item.fields.name,
          TELEFONI: item.fields.phone,
          POZITA: item.fields.position,
          STUDIO: item.fields.studio.name,
          "DATA E FILLIMIT": item.fields.started_date,
          "DATA E MBARIMI": item.fields.ended_date,
          PAGESA: payment,
          AKTIV: item.fields.actived ? "Actived" : "",
        };
      });
      XLSX.utils.sheet_add_json(ws, clientDetail);
      XLSX.writeFile(wb, `stafis - ${Moment(new Date()).format("DD.MM.YYYY")}.xls`);
    }
  };

  const handleActiveStaffResponse = async (res, isErr) => {
    dispatch(showLoading(false));
    if (isErr) {
      dispatch(showAlert({ type: "error", msg: res }));
      return;
    }

    if (res.result === "failed") {
      dispatch(showAlert({ type: "error", msg: res }));
    }

    callAPIGetAllIndividuals();
  };

  const callApiActiveStaff = async (id, actived) => {
    const url = config.BASE_URL + config.ACTIVE_STAFF;

    const postData = {
      id,
      actived,
    };

    dispatch(showLoading(true));
    callServicePostAuth(url, postData, handleActiveStaffResponse);
  };

  const callbackActiveBtn = (id, e) => {
    console.log(id, e.target.checked);
    callApiActiveStaff(id, e.target.checked);
  };

  const callbackEditBtn = (id) => {
    const staff = initialStaffs.filter((item) => item.pk === id);

    console.log("edit", id, staff[0]);
    setSelectedStaff(staff[0]);
    setOpenEditModal(true);
  };

  const handleDeleteStaffResponse = async (res, isErr) => {
    dispatch(showLoading(false));
    if (isErr) {
      dispatch(showAlert({ type: "error", msg: res }));
      return;
    }

    if (res.result === "failed") {
      dispatch(showAlert({ type: "error", msg: res }));
    }

    callAPIGetAllIndividuals();

    setDeleteDlgOpen(false);
    setSelectedId2Delete();
  };

  const callApiDeleteStaff = async () => {
    const url = config.BASE_URL + config.DELETE_STAFF;

    const postData = {
      id: selectedId2Delete,
    };

    dispatch(showLoading(true));
    callServicePostAuth(url, postData, handleDeleteStaffResponse);
  };

  const handleClickDeleteBtn = () => {
    callApiDeleteStaff();
  };

  const callbackDeleteBtn = (id) => {
    // callApiDeleteStaff(id);
    setDeleteDlgOpen(true);
    setSelectedId2Delete(id);
  };

  const handleCloseDlg = () => {
    setDeleteDlgOpen(false);
  };

  //
  useEffect(() => {
    // import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
    if (!initialStaffs) callAPIGetAllIndividuals();
  }, []);

  useEffect(() => {
    if (initialStaffs && initialStaffs.length > 0) {
      let rows = initialStaffs.map((item) => {
        let payment = "";
        if (
          item.fields.salary_fixed &&
          item.fields.salary_fixed > 0 &&
          item.fields.salary_percent &&
          item.fields.salary_percent > 0
        ) {
          payment = `${item.fields.salary_fixed}€ + ${item.fields.salary_percent}%`;
        } else if (item.fields.salary_fixed && item.fields.salary_fixed > 0) {
          payment = `${item.fields.salary_fixed}€(fikse)`;
        } else if (item.fields.salary_percent && item.fields.salary_percent > 0) {
          payment = `${item.fields.salary_percent}%(të qarkullimit individual)`;
        }

        const row = {};
        row.name = item.fields.name;
        row.phone = item.fields.phone;
        row.position = item.fields.position;
        row.studio = (
          <div style={{ wordSpacing: "break", width: 100 }}>
            {item.fields.studio ? item.fields.studio.name : "Unknown"}
          </div>
        );

        if (selectedStudioFilter && selectedStudioFilter !== row.studio) return null;
        row.startedDate = item.fields.started_date;
        row.endedDate = item.fields.ended_date;
        row.payment = payment;
        row.active = renderActiveBtn({
          id: item.pk,
          actived: item.fields.actived,
          onClicked: callbackActiveBtn,
        });
        row.edit = renderEditBtn({ id: item.pk, onClicked: callbackEditBtn });
        row.delete = renderDeleteBtn({ id: item.pk, onClicked: callbackDeleteBtn });
        if (visibleMode === 1 && item.fields.actived) return row;
        if (visibleMode === 2 && !item.fields.actived) return row;
        if (visibleMode === 0) return row;
        return null;
      });
      rows = rows.filter((item) => item != null);
      setDataRows(rows);
    }
  }, [initialStaffs, visibleMode, selectedStudioFilter]);

  const handleClickCheckShowOnlyActived = (e) => {
    console.log(visibleMode);
    if (e.target.checked) {
      setVisibleMode(1);
    }
  };

  const handleClickCheckShowOnlyNotActived = (e) => {
    if (e.target.checked) {
      setVisibleMode(2);
    }
  };

  const handleClickCheckShowAll = (e) => {
    if (e.target.checked) {
      setVisibleMode(0);
    }
  };

  const handleCloseEditModal = () => {
    callAPIGetAllIndividuals();
    setOpenEditModal(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Dialog
        open={deleteDlgOpen}
        onClose={handleCloseDlg}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Konfirmo fshirjen</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Jeni i sigurt që dëshironi ta fshini këtë? Ky veprim nuk mund të zhbëhet.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDlg}>Anulo</Button>
          <S.Button
            variant="contained"
            color="success"
            onClick={handleClickDeleteBtn}
            autoFocus
            width={100}
          >
            Fshije
          </S.Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={openEditModal}
        onClose={handleCloseEditModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CreateEditStaff
          selectedItem={selectedStaff}
          studios={initialStudio}
          onClose={handleCloseEditModal}
        />
      </Modal>
      <MDBox
        pt={1}
        pb={1}
        px={1}
        style={{
          minHeight: "80vh",
          height: "calc(100% - 80px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "auto",
        }}
      >
        <S.Card>
          <S.HeaderContainer>
            <img src={logoImg} alt="logo" />
            <div
              style={{
                width: "100%",
                color: "#C20B7E",
                marginTop: 20,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span style={{ color: "#C20B7E", marginTop: 20 }}>Stafi</span>
              <div>
                <S.Button
                  onClick={() => {
                    setSelectedStaff(null);
                    setOpenEditModal(true);
                  }}
                  width={100}
                  height={30}
                >
                  Krijo
                </S.Button>
              </div>
            </div>
          </S.HeaderContainer>
          <S.ServicesContainer>
            <S.GradientBg />
            <S.SearchFilters>
              <Grid container space={3}>
                <Grid item xs={12} md={6} lg={3}>
                  <S.FilterCellContainer>
                    <FormControlLabel
                      label="Shfaq punëtorët aktiv:"
                      control={
                        <Checkbox
                          sx={{
                            color: "#C20B7E80",
                            "&.Mui-checked": {
                              color: "#C20B7E",
                            },
                          }}
                          checked={visibleMode === 1}
                          onChange={handleClickCheckShowOnlyActived}
                        />
                      }
                      labelPlacement="start"
                    />
                  </S.FilterCellContainer>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <S.FilterCellContainer>
                    <FormControlLabel
                      label="Shfaq punëtorët jo aktiv:"
                      control={
                        <Checkbox
                          sx={{
                            color: "#C20B7E80",
                            "&.Mui-checked": {
                              color: "#C20B7E",
                            },
                          }}
                          checked={visibleMode === 2}
                          onChange={handleClickCheckShowOnlyNotActived}
                        />
                      }
                      labelPlacement="start"
                    />
                  </S.FilterCellContainer>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <S.FilterCellContainer>
                    <FormControlLabel
                      label="Shfaqtë gjithë punëtorët:"
                      control={
                        <Checkbox
                          sx={{
                            color: "#C20B7E80",
                            "&.Mui-checked": {
                              color: "#C20B7E",
                            },
                          }}
                          checked={visibleMode === 0}
                          onChange={handleClickCheckShowAll}
                        />
                      }
                      labelPlacement="start"
                    />
                  </S.FilterCellContainer>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <S.FilterCellContainer>
                    <FormControlLabel
                      label="Kërko sipas studios:  "
                      control={
                        <div style={{ paddingLeft: 10 }}>
                          <S.SelectFilter
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable
                            isSearchable
                            placeholder="Zgjedh..."
                            dropdown
                            onChange={(value) =>
                              setSelectedStudioFilter(value ? value.value : null)
                            }
                            options={initialStudio}
                            style={{
                              backgroundColor: "rgba(0, 0, 0, 0.2)",
                              marginLeft: 10,
                              height: "1rem",
                              fontSize: "0.7rem",
                            }}
                          />
                        </div>
                      }
                      labelPlacement="start"
                    />
                  </S.FilterCellContainer>
                </Grid>
              </Grid>
            </S.SearchFilters>
            <S.ServicesContent>
              <S.DataTable
                id="table"
                table={{ rows: dataRows, columns }}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={false}
              />
            </S.ServicesContent>
          </S.ServicesContainer>
          <S.BottomContainer>
            <div
              style={{
                width: 350,
                height: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                position: "relative",
              }}
            >
              <S.Select
                className="basic-single"
                classNamePrefix="select"
                isClearable
                placeholder="Zgjedh..."
                isSearchable
                dropdown
                menuPlacement="top"
                value={optionReports.filter((item) => item.value === reportFileType)[0]}
                onChange={(value) => setReportFileType(value ? value.value : null)}
                options={optionReports}
                style={{ backgroundColor: "rgba(0, 0, 0, 0.2)", marginRight: 10 }}
              />
              <div style={{ width: 10 }} />
              <S.Button onClick={handleClickedPrint} width={200}>
                Report
              </S.Button>
            </div>
          </S.BottomContainer>
        </S.Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

const S = {
  Card: styled(Card)`
    width: 95%;
    height: 95%;
    overflow: auto;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    @media (max-width: 1024px) {
      width: 90%;
      min-height: 95%;
    }
  `,
  Conainer: styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
  `,
  CloseBtnDiv: styled.div`
    position: absolute;
    top: 0px;
    right: 20px;
  `,

  HeaderContainer: styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
    img {
      width: 330px;
      height: 100px;
      @media (max-width: 768px) {
        width: 200px;
        height: 60px;
      }
    }
  `,
  CustomerInfoArea: styled.div`
    display: grid !important;
    grid-template-columns: repeat(7, 1fr) !important;
    margin-right: 20px !important;
    grid-gap: 10px !important;
    border-bottom: none !important;
    max-height: none !important;
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
    @media (max-width: 1024px) {
      grid-template-columns: repeat(2, 1fr) !important;
    }
  `,
  CustomerInfoCell: styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    align-items: center;
  `,
  CustomerInfoCelData: styled.div`
    height: 40px;
    border-radius: ${(props) => (props.borderRadius ? `${props.borderRadius}px` : "40px")};
    background-color: rgba(0, 0, 0, 0.06);
    width: ${(props) => (props.auto ? "auto" : "100%")};

    color: #c20b7e;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
  `,
  ServicesContainer: styled.div`
    position: relative;
    padding-top: 20px;
    flex: 1;
    min-height: 400px;
    backgroundcolor: transparent;
    overflow: auto;
  `,

  SearchFilters: styled.div`
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
  `,
  ServicesContent: styled.div`
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    overflow: auto;
  `,

  GradientBg: styled.div`
    position: absolute;
    background: white;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0.03) 20%, white 50%);
  `,
  SearchLeftPan: styled.div`
    display: flex;
    flex: 1;
  `,
  SearchRightPan: styled.div`
    display: flex;
    flex: 1;
    @media (max-width: 1024px) {
      display: none;
    }
  `,

  SelectFilter: styled(Select)`
    flex: 1;

    border-radius: 10px;
    font-size: 1rem !important;

    .select__control {
      box-shadow: unset;
      background-color: rgba(0, 0, 0, 0.06);
      border-radius: 10px;
      min-height: 30px !important;
      font-size: 1rem;
      border: none;
      :hover {
        border: none;
      }
      :focuse {
        border: none;
      }
      :actived {
        border: none;
      }
    }
    .select__multi-value {
      border: 1px solid #c20b7e;
    }
    .select__indicator {
      color: #c20b7e !important;
    }
    .select__indicator-separator {
      display: none !important;
    }
  `,

  Select: styled(Select)`
    flex: 1;
    min-height: 50px !important;
    border-radius: 10px;
    font-size: 1rem !important;

    .select__control {
      box-shadow: unset;
      background-color: rgba(0, 0, 0, 0.06);
      border-radius: 10px;
      min-height: 50px !important;
      border: none;
      :hover {
        border: none;
      }
      :focuse {
        border: none;
      }
      :actived {
        border: none;
      }
    }
    .select__multi-value {
      border: 1px solid #c20b7e;
    }
    .select__indicator {
      color: #c20b7e !important;
    }
    .select__indicator-separator {
      display: none !important;
    }
  `,
  Button: styled(Button)`
    height: ${(props) => (props.height ? `${props.height}px` : "50px")} !important;
    width: ${(props) => (props.width ? `${props.width}px` : "100%")} !important;
    border-radius: 10px !important;
    background-color: ${(props) => (props.dark ? "gray !important" : "#C20B7E !important")};
    text-transform: none !important;
    font-size: 1rem !important;
    font-weight: 100 !important;
    color: white !important;
    margin-bottom: 2px;
    :hover {
      background-color: ${(props) => (props.dark ? "lightgray !important" : "#E560B3 !important")};
      color: white !important;
    }
  `,
  BottomContainer: styled.div`
    height: 80px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
  `,
  DataTable: styled(DataTable)`
    .MuiTableContainer-root {
      table {
        min-width: 600px !important;
      }
    }
    .MuiTable-root {
      thead {
        background-color: #c20b7e !important;
        color: white !important;
      }
      table {
        min-width: 600px !important;
      }
    }
  `,
  FilterCellContainer: styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
  `,
};

export default Staffs;
