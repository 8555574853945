import { Button } from "@mui/material";
import PropTypes from "prop-types";
import styled from "styled-components";

function TimeButtons({ onSelectedHourItem, onSelectedMinsItem }) {
  const times = [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21];

  return (
    <div>
      <S.GridContainer>
        {times.map((item) => (
          <S.Button key={item} onClick={() => onSelectedHourItem(item)}>{`${item}:00`}</S.Button>
        ))}
      </S.GridContainer>
      <S.GridContainer2>
        <S.Button2 onClick={() => onSelectedMinsItem(0)}>00:00</S.Button2>
        <S.Button2 onClick={() => onSelectedMinsItem(15)}>15:00</S.Button2>
        <S.Button2 onClick={() => onSelectedMinsItem(30)}>30:00</S.Button2>
        <S.Button2 onClick={() => onSelectedMinsItem(45)}>45:00</S.Button2>
      </S.GridContainer2>
    </div>
  );
}

const S = {
  GridContainer: styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 10px;
    margin-top: 10px;
  `,
  GridContainer2: styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    margin-top: 10px;
  `,
  Button: styled(Button)`
    padding: 0px !important;
    min-width: 30px !important;
    background-color: #C20B7E !important;
    color: white !important;
    hover {
    }
  `,
  Button2: styled(Button)`
    padding: 0px !important;
    min-width: 30px !important;
    background-color: gray !important;
    color: white !important;
    hover {
    }
  `,
};
TimeButtons.propTypes = {
  onSelectedHourItem: PropTypes.func.isRequired,
  onSelectedMinsItem: PropTypes.func.isRequired,
};

export default TimeButtons;
