/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import authorsTableData from "layouts/customers/data/authorsTableData";
import config from "configs/config.json";
import { useDispatch } from "react-redux";
import { showAlert } from "actions/alert";

import { showLoading } from "actions/loading";
import { callServiceGetAuth, callServicePostAuth } from "services/AuthServices";

import { useLocation } from "react-router-dom";

import EditCustomerDialog from "./component/EditCustomerDialog";
import CustomerReporter from "./component/CustomerReporter";

const TAG = "Customers";

function Customers() {
  const dispatch = useDispatch();
  const [allClients, setClients] = useState([]);
  const [tableData, setTableData] = useState();
  const [isDeleting, setDeleteing] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openReportModal, setOpenReportModal] = useState(false);
  const [name2Find, setName2Find] = useState("");
  const location = useLocation();
  const handleOpenEditModal = () => setOpenEditModal(true);
  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setDeleteing(false);
  };
  const [selectedCustomerId, setSelectedCustomerId] = useState(-1);

  useEffect(() => {
    if (location.state) {
      const searchClientName = location.state?.name;
      if (searchClientName) {
        console.log("asdfasdfa asdf asdf asd", searchClientName, tableData);
        location.state = undefined;
        setName2Find(() => searchClientName);

        const filteredData = allClients.filter(
          (item) => `${item.name} ${item.surname}` === searchClientName
        );

        if (filteredData.length > 0) {
          setSelectedCustomerId(filteredData[0].id);
          setOpenReportModal(true);
        }
      }
    }
  });

  const handleCloseReportModal = () => {
    setOpenReportModal(false);
  };

  //   const [rows, setRows] = useState([]);
  //   const [columns, setColumns] = useState([]);
  //   const { columns, rows } = authorsTableData();

  console.log(TAG, allClients);

  const getCustomerById = (id) => allClients.filter((item) => item.id === id)[0];

  const handleShowDeleteCustomer = (id) => {
    console.log(TAG, "handleShowDeleteCustomer ", id);
    setSelectedCustomerId(id);
    setDeleteing(true);
    handleOpenEditModal();
  };

  const handleShowEditCustomer = (id) => {
    console.log(TAG, "handleShowEditCustomer ", id);
    setSelectedCustomerId(id);
    setDeleteing(false);
    handleOpenEditModal();
  };

  const handleShowReportCustomer = (id) => {
    console.log(TAG, "handleShowReportCustomer ", id);
    setSelectedCustomerId(id);
    setOpenReportModal(true);
  };

  const handleReportCustomer = (id) => {
    setOpenEditModal(false);
    handleShowReportCustomer(id);
  };

  const handleGetAllCustomersResponse = async (res, isError) => {
    dispatch(showLoading(false));

    if (isError) {
      dispatch(showAlert({ type: "error", msg: res }));
      return;
    }
    setClients(res);
    setTableData(
      authorsTableData(
        res,
        handleShowEditCustomer,
        handleShowDeleteCustomer,
        handleShowReportCustomer
      )
    );

    console.log(name2Find);

    console.log(tableData);

    // setRows(trows);
    // setColumns;
  };

  const callAPIGetAllCustomers = () => {
    const url = config.BASE_URL + config.GET_ALL_CUSTOMERS;
    console.log(TAG, "callAPIGetAllStaffs url = ", url);

    dispatch(showLoading(true));
    callServiceGetAuth(url, handleGetAllCustomersResponse);
  };

  const handleUpdateCustomerReponse = async (res, isError) => {
    dispatch(showLoading(false));
    if (isError) {
      dispatch(showAlert({ type: "error", msg: res }));
      return;
    }

    if (res.result === "success") {
      dispatch(showAlert({ type: "success", msg: res.msg }));
      callAPIGetAllCustomers();
      setOpenEditModal(false);
    } else dispatch(showAlert({ type: "error", msg: res.msg }));
  };
  const callApiUpdateCustomer = async (data) => {
    const url = config.BASE_URL + config.UPDATE_CUSTOMER;
    console.log(TAG, "callApiUpdateCustomer url = ", url);
    console.log(TAG, "callApiUpdateCustomer data = ", JSON.stringify(data));

    dispatch(showLoading(true));
    callServicePostAuth(url, data, handleUpdateCustomerReponse);
  };
  const handleEditCustomer = (data) => {
    callApiUpdateCustomer(data);
  };

  const handleDeleteCustomerResponse = async (res, isError) => {
    dispatch(showLoading(false));

    if (isError) {
      dispatch(showAlert({ type: "error", msg: res }));
      return;
    }

    if (res.result === "success") {
      dispatch(showAlert({ type: "success", msg: res.msg }));
      callAPIGetAllCustomers();
      setOpenEditModal(false);
    } else dispatch(showAlert({ type: "error", msg: res.msg }));
  };
  const callApiDeletCustomer = async (id) => {
    const url = config.BASE_URL + config.DEL_CUSTOMER;
    const postData = {
      id,
    };
    console.log(TAG, "callApiDeletCustomer url = ", url);

    dispatch(showLoading(true));
    callServicePostAuth(url, postData, handleDeleteCustomerResponse);
  };
  const handleDeleteCustomer = (id) => {
    console.log(id);
    callApiDeletCustomer(id);
  };

  useEffect(() => {
    if (allClients.length === 0) callAPIGetAllCustomers();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Modal
        open={openEditModal}
        onClose={handleCloseEditModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <EditCustomerDialog
          onClose={handleCloseEditModal}
          onEdit={(data) => handleEditCustomer(data)}
          onDelete={(id) => handleDeleteCustomer(id)}
          onReport={(id) => handleReportCustomer(id)}
          customer={getCustomerById(selectedCustomerId)}
          isDelete={isDeleting}
        />
      </Modal>
      <Modal
        open={openReportModal}
        onClose={handleCloseReportModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CustomerReporter
          onClose={handleCloseReportModal}
          customerId={selectedCustomerId}
          isVisible={openReportModal}
        />
      </Modal>
      <MDBox pt={6} pb={3} px={3} style={{ height: "100%", display: "flex" }}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card style={{ height: "100%" }}>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                style={{ background: "#C20B7E" }}
              >
                <MDTypography variant="h6" color="white">
                  Kartela
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {tableData && (
                  <DataTable
                    table={{ rows: tableData.rows, columns: tableData.columns }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      {/* <Dialog
        open={delDlgOpen}
        onClose={handleCloseDelDlg}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure to delete this customer card?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous
            location data to Google, even when no apps are running.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog> */}
    </DashboardLayout>
  );
}

export default Customers;
