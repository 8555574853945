/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

import styled from "styled-components";

import { useNavigate } from "react-router-dom";

// redux
import { useDispatch } from "react-redux";

// actions
import { doLogin } from "actions/auth";
import { showAlert, clearAlert } from "actions/alert";

// mui
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

// Images
import logoImg from "assets/images/main-logo.png";
import bgImg from "assets/images/leftbg.png";

// import BasicLayout from "layouts/authentication/components/BasicLayout";

const TAG = "LoginPage.js";

function Basic() {
  const [isLoading, setLoading] = useState(false);
  const [userName, setUserName] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const dispatch = useDispatch();
  const navigation = useNavigate();
  console.log(TAG, bgImg);
  const handleClickLogin = () => {
    if (userName === "" || userPassword === "") {
      // error
      dispatch(showAlert({ type: "error", msg: "Ju lutemi plotësoni formularin e kyçjes" }));
      // Please fill login form
      return;
    }
    dispatch(doLogin(userName, userPassword))
      .then(() => {
        setLoading(false);
        dispatch(clearAlert());
        navigation.push("/");
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <S.Container>
      {/* {isLoading && <LoadingBlankPage />} */}
      {isLoading && <div>ssdfsdf</div>}
      <S.LoginForm>
        <S.LeftPanel>
          <S.ImageBg />
          <S.LogoSection>
            <img src={logoImg} alt="logo" />
          </S.LogoSection>
          <S.WelcomeContainer>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <S.WelcomeSpan>MIRË SË VINI</S.WelcomeSpan>
            </div>
            <S.SmallDivider
              style={{
                width: 50,
                height: 5,
                backgroundColor: "white",
                borderRadius: 5,
                marginTop: 10,
              }}
            />
          </S.WelcomeContainer>
          <S.BottomContainer>www.bepassion.com</S.BottomContainer>
        </S.LeftPanel>
        <S.RightPanel>
          <div style={{ width: "100%", textAlign: "center", marginBottom: 30 }}>
            <span
              style={{
                fontFamily: "Rubik",
                color: "#C20B7E",
                letterSpacing: "1",
                fontSize: 20,
                fontWeight: "bold",
              }}
            >
              Llogaria
            </span>
          </div>
          <div style={{ width: "80%" }}>
            <span
              style={{ fontFamily: "Rubik", color: "#8598AD", letterSpacing: "1", fontSize: 15 }}
            >
              Përdoruesi:{" "}
            </span>
            <div
              style={{
                width: "100%",
                borderLeftColor: "#C20B7E",
                borderLeftWidth: 3,
                borderLeftStyle: "solid",
              }}
            >
              <S.NoLabelTextField
                id="filled-basic-username"
                variant="filled"
                label={null}
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                style={{
                  width: "100%",
                }}
              />
            </div>
          </div>
          <div style={{ width: "80%", marginTop: 10 }}>
            <span style={{ fontFamily: "Rubik", color: "#8598AD", fontSize: 15 }}>
              Fjalëkalimi:{" "}
            </span>
            <div
              style={{
                width: "100%",
                borderLeftColor: "#C20B7E",
                borderLeftWidth: 3,
                borderLeftStyle: "solid",
              }}
            >
              <S.NoLabelTextField
                id="filled-basic-password"
                variant="filled"
                label={null}
                value={userPassword}
                onChange={(e) => setUserPassword(e.target.value)}
                type="password"
                style={{
                  width: "100%",
                }}
              />
            </div>
          </div>
          <div style={{ width: "80%", marginTop: 30 }}>
            <S.Button variant="contained" onClick={handleClickLogin}>
              kyçu{" "}
            </S.Button>
          </div>
        </S.RightPanel>
      </S.LoginForm>
    </S.Container>
  );
}

const S = {
  Container: styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;

    justify-content: center;
    align-items: center;
    background-color: #f5f6fa;
  `,
  LoginForm: styled.div`
    width: 50%;
    height: 55%;
    min-width: 660px;
    color: white;
    display: flex;
    box-shadow: 5px 5px 8px #888888;
    @media (max-width: 1024px) {
      flex-direction: column;
      width: 90%;
      height: 60%;
      min-width: 250px;
    }
  `,
  LeftPanel: styled.div`
    flex: 1;
    background-color: #d72896;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    @media (max-width: 1024px) {
      display: flex;
    }
  `,
  ImageBg: styled.div`
    position: absolute;
    background-image: url(${bgImg});
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
  `,
  BottomContainer: styled.div`
    height: 50px;
    z-index: 1;
    font-size: 16px;
  `,

  LogoSection: styled.div`
    z-index: 1;
    width: 100%;
    height: 40%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 180px;
      height: 60px;
    }
    span {
      font-size: 28px;
    }
    @media (max-width: 1024px) {
      height: 100%;

      img {
        width: 180px;
        height: 50px;
      }
      span {
        font-size: 30px;
      }
    }
    @media (max-width: 768px) {
      height: 100%;
      justify-content: center;
      margin-left: 0px;
      img {
        width: 220px;
        height: 100px;
      }
      span {
        font-size: 30px;
        margin-left: 20px;
      }
    }
  `,
  WelcomeContainer: styled.div`
    z-index: 1;
    display: flex;
    width: 100%;
    flex: 1;
    flex-direction: column;
    align-items: center;
    @media (max-width: 1024px) {
      flex: 1
      align-items: flex-end;
      
    }
    @media (max-width: 768px) {
      display: none;
    }
  `,
  WelcomeSpan: styled.span`
    color: white;
    font-size: 35px;
    letter-spacing: 5px;
    font-family: Rubik;
  `,
  SmallDivider: styled.div`
    width: 50;
    height: 5;
    background-color: white;
    border-radius: 5;
    margin-top: 10;
    @media (max-width: 1024px) {
      display: none;
    }
  `,
  RightPanel: styled.div`
    flex: 1;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media (max-width: 1024px) {
      flex: 2;
    }
  `,
  Button: styled(Button)`
    width: 100%;
    border-radius: 50px !important;
    height: 50px !important;
    background-color: #ff0c8c !important;
    font-size: 15px !important;
    font-family: Rubik !important;
    @media (max-width: 1024px) {
      font-size: 15px !important;
    }
    color: white !important;
  `,
  NoLabelTextField: styled(TextField)`
    .MuiFilledInput-root {
      padding: 5px !important;
    }
    input {
      padding: 0 !important;
      font-size: 12px !important;
      height: 30px !important;
    }
  `,
};

export default Basic;
