import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import styled from "styled-components";
// import IconButton from "layouts/bookings/pages/components/IconButton";
import Calendar from "react-calendar";
import { useState } from "react";
import Moment from "moment/moment";
import Select from "react-select";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CustomLabels from "layouts/bookings/pages/components/CustomLabels";
import config from "configs/config.json";
import { callServicePostAuth } from "services/AuthServices";
import { useDispatch } from "react-redux";
import { showAlert } from "actions/alert";

const TAG = "UserExpencesPage";

function UserExpencesPage() {
  const dispatch = useDispatch();

  //   const [openCalendar, setOpenCalendar] = useState(false);
  //   const [selectedDate, setSelectedDate] = useState("");

  const [price, setPrice] = useState();
  const [description, setDescription] = useState("");

  const handleAddIncomeResponse = async (res, isErr) => {
    if (isErr) {
      dispatch(showAlert({ type: "error", msg: res }));
      return;
    }
    if (res.result === "success") {
      dispatch(showAlert({ type: "success", msg: res.msg }));
    }
    if (res.result !== "success") {
      dispatch(showAlert({ type: "errpr", msg: res.msg }));
    }
  };
  const callApiAddIncoms = async () => {
    const url = config.BASE_URL + config.ADD_EXPENCES;
    const postData = {
      description,
      price: parseFloat(price),
      //   date: Moment(selectedDate, "DD.MM.YYYY").format("YYYY-MM-DD"),
      date: Moment(new Date()).format("YYYY-MM-DD"),
    };
    console.log(TAG, "callApiAddIncoms url = ", url);
    console.log(TAG, "callApiAddIncoms postData = ", JSON.stringify(postData));

    callServicePostAuth(url, postData, handleAddIncomeResponse);
  };
  const handleClickedAddIncome = () => {
    if (
      //   !selectedDate ||
      //   selectedDate.length === 0 ||
      !price ||
      price.length === 0 ||
      parseFloat(price) <= 0 ||
      !description ||
      description.length === 0
    ) {
      dispatch(showAlert({ type: "error", msg: "Ju lutemi plotësoni fushat" }));
      return;
    }
    callApiAddIncoms();
  };
  return (
    <DashboardLayout style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <DashboardNavbar />
      <MDBox
        py={3}
        ml={3}
        mr={3}
        mt={3}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <S.MainContainer>
          <S.Header>
            <Icon fontSize="large">receipt_long</Icon>
            Dalje
          </S.Header>
          <S.Container>
            <S.BottomContainer
              style={{ position: "relative", overflow: "visible", flexDirection: "column" }}
            >
              {/* <CustomLabels title="Data" /> */}
              {/* <IconButton
                onClick={() => setOpenCalendar(!openCalendar)}
                title={selectedDate}
                icon="calendar_month_icon"
                imageSize="30px"
                fontSize="16dpx"
                iconColor="#C20B7E"
                textColor="gray"
                height="60px"
                padding-right: colium
              />
              <div style={{ position: "relative", width: "100%" }}>
                {openCalendar && (
                  <S.Calendar
                    useWeekdaysShort
                    onChange={(value) => {
                      setSelectedDate(Moment(value).format("DD.MM.YYYY"));
                      setOpenCalendar(!openCalendar);
                    }}
                  />
                )}
              </div> */}
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderColor: "lightgray",
                  alignItems: "center",
                  borderRadius: 5,
                  backgroundColor: "rgba(0,0,0,0.06)",
                }}
              >
                <div style={{ color: "#C20B7E", display: "flex", alignItems: "center" }}>
                  <Icon fontSize="large">calendar_month_icon</Icon>
                </div>
                <div
                  style={{
                    color: "#C20B7E",
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  {Moment(new Date()).format("DD.MM.YYYY")}
                </div>
              </div>
            </S.BottomContainer>
            <S.BottomContainer>
              <CustomLabels title="Pershkrimi" fontSize={16} />
              <S.NoLabelTextField
                id="filled-basic-username"
                variant="filled"
                label={null}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                fontSize={14}
                black
                style={{
                  width: "100%",
                  textAlign: "left",
                  color: "black",
                }}
              />
            </S.BottomContainer>
            <S.BottomContainer>
              <CustomLabels title="Shuma" fontSize={16} />
              <S.NoLabelTextField
                id="filled-basic-username"
                variant="filled"
                label={null}
                value={price}
                fontSize={16}
                onChange={(e) => setPrice(e.target.value)}
                style={{
                  width: "100%",
                }}
              />
            </S.BottomContainer>
            <S.BottomContainer>
              <S.Button variant="contained" onClick={handleClickedAddIncome}>
                Regjistro
              </S.Button>
            </S.BottomContainer>
          </S.Container>
        </S.MainContainer>
      </MDBox>
    </DashboardLayout>
  );
}

const S = {
  MainContainer: styled(Card)`
    width: 400px;
    height: 600px;
    @media (max-width: 1024px) {
      width: 90%;
      height: 100%;
    }
  `,
  Header: styled.div`
    height: 50px;
    background-color: #C20B7E;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: relative;
    display: flex;
    align-items: center;
    color: white;
    font-size: 16px;
    font-weight: 40 !important;
    padding-left: 20px;
  `,
  Container: styled.div`
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    flex: 1;
  `,
  BottomContainer: styled.div`
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 80px;
  `,
  Calendar: styled(Calendar)`
    margin: 0px !important;
    position: absolute !important;
    .react-calendar {
      margin: 0px !important;
    }
  `,
  Select: styled(Select)`
    flex: 1;
    min-height: 50px !important;
    width: 100%;
    border: none;

    .select__control {
      box-shadow: unset;
      background-color: transparent;
      border: none;
      :hover {
        border: none;
      }
      :focuse {
        border: none;
      }
      :actived {
        border: none;
      }
    }
    .select__multi-value {
      border: 1px solid #C20B7E;
    }
    .select__indicator {
      color: #C20B7E !important;
    }
    .select__indicator-separator {
      display: none !important;
    }
  `,
  NoLabelTextField: styled(TextField)`
    width: 100%;
    .MuiFilledInput-root {
      padding: 5px !important;
    }
    input {
      padding: 0 !important;
      text-align: ${(props) => (props.black ? "left" : "center")};
      color: ${(props) => (props.black ? "black" : "#C20B7E")};
      height: 60px;
      font-size: ${(props) => (props.fontSize ? props.fontSize : "30px")};
    }
  `,
  Button: styled(Button)`
    width: 100%;
    border-radius: 50px !important;
    height: 50px !important;
    background-color: #ff0c8c !important;
    font-size: 15px !important;
    font-family: Rubik !important;
    @media (max-width: 1024px) {
      font-size: 16px !important;
    }
    color: white !important;
  `,
};
export default UserExpencesPage;
