// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";
// import MDAvatar from "components/MDAvatar";
// import MDBadge from "components/MDBadge";

// // Images
// import team2 from "assets/images/team-2.jpg";
// import team3 from "assets/images/team-3.jpg";
// import team4 from "assets/images/team-4.jpg";
// import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
// import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
// import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
function Actions({ onEdit, onDelete, onReport, id }) {
  return (
    <MDBox lineHeight={1} textAlign="center" display="flex" justifyContent="space-arrount">
      <Tooltip title="Ndrysho">
        <MDTypography
          component="span"
          color="secondary"
          onClick={() => {
            if (onEdit) onEdit(id);
          }}
          style={{ cursor: "pointer", margin: 5 }}
        >
          <Icon color="red">create_outlined_icon</Icon>
        </MDTypography>
      </Tooltip>
      <Tooltip title="Raporti">
        <MDTypography
          component="span"
          color="success"
          style={{ cursor: "pointer", margin: 5 }}
          onClick={() => {
            if (onReport) onReport(id);
          }}
        >
          <Icon color="red">remove_red_eye_icon</Icon>
        </MDTypography>
      </Tooltip>
      <Tooltip title="Fshij">
        <MDTypography
          component="span"
          color="error"
          style={{ cursor: "pointer", margin: 5 }}
          onClick={() => {
            if (onDelete) onDelete(id);
          }}
        >
          <Icon color="red">delete_outline_outlined_icon</Icon>
        </MDTypography>
      </Tooltip>
    </MDBox>
  );
}

Actions.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onReport: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  // children: PropTypes.node.isRequired,
  // noBorder: PropTypes.bool,
  // align: PropTypes.oneOf(["left", "right", "center"]),
};

function CustomCell({ text }) {
  return (
    <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
      {text}
    </MDTypography>
  );
}

CustomCell.propTypes = {
  text: PropTypes.string.isRequired,
};

export default function data(clientdata, handleEdit, handleDelete, handleReport) {
  // const Author = ({ image, name, email }) => (
  //   <MDBox display="flex" alignItems="center" lineHeight={1}>
  //     <MDAvatar src={image} name={name} size="sm" />
  //     <MDBox ml={2} lineHeight={1}>
  //       <MDTypography display="block" variant="button" fontWeight="medium">
  //         {name}
  //       </MDTypography>
  //       <MDTypography variant="caption">{email}</MDTypography>
  //     </MDBox>
  //   </MDBox>
  // );

  // const Job = ({ title, description }) => (
  //   <MDBox lineHeight={1} textAlign="left">
  //     <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
  //       {title}
  //     </MDTypography>
  //     <MDTypography variant="caption">{description}</MDTypography>
  //   </MDBox>
  // );

  const initRow = {
    name: "",
    surname: "",
    phone: "",
    email: "",
    dob: "",
    identity: "",
    city: "",
    address: "",
    action: "",
  };
  const rows = [];
  if (clientdata.length > 0) {
    clientdata.forEach((item) => {
      const tmpData = { ...initRow };
      tmpData.name = <CustomCell text={item.name} />;
      tmpData.surname = <CustomCell text={item.surname} />;
      tmpData.phone = <CustomCell text={item.phone} />;
      tmpData.email = <CustomCell text={item.email} />;
      tmpData.dob = <CustomCell text={item.dob} />;
      tmpData.identity = <CustomCell text={item.personal_id} />;
      tmpData.city = <CustomCell text={item.city} />;
      tmpData.address = <CustomCell text={item.address} />;
      tmpData.action = (
        <Actions onEdit={handleEdit} onDelete={handleDelete} id={item.id} onReport={handleReport} />
      );
      rows.push(tmpData);
    });
  }

  return {
    columns: [
      { Header: "Emri", accessor: "name", align: "center", fontSize: 20 },
      { Header: "Mbiemri", accessor: "surname", align: "center", fontSize: 20 },
      { Header: "Telefoni", accessor: "phone", align: "center", fontSize: 20 },
      { Header: "E-mail", accessor: "email", align: "center", fontSize: 20 },
      { Header: "Data e lindjës", accessor: "dob", align: "center", fontSize: 20 },
      { Header: "Numri personal", accessor: "identity", align: "center", fontSize: 20 },
      { Header: "Qyteti", accessor: "city", align: "center", fontSize: 20 },
      { Header: "Adresa", accessor: "address", align: "center", fontSize: 20 },
      { Header: "action", accessor: "action", align: "center", fontSize: 20 },
    ],

    rows,
    // [
    //   {
    //     name: (
    //       <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
    //         23/04/18
    //       </MDTypography>
    //     ),
    //     surname: (
    //       <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
    //         23/04/18
    //       </MDTypography>
    //     ),
    //     status: (
    //       <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
    //         23/04/18
    //       </MDTypography>
    //     ),
    //     email: (
    //       <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
    //         23/04/18
    //       </MDTypography>
    //     ),
    //     dob: (
    //       <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
    //         23/04/18
    //       </MDTypography>
    //     ),
    //     identify: (
    //       <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
    //         23/04/18
    //       </MDTypography>
    //     ),
    //     city: (
    //       <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
    //         23/04/18
    //       </MDTypography>
    //     ),
    //     address: (
    //       <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
    //         23/04/18
    //       </MDTypography>
    //     ),
    //     action: <Actions onEdit={handleEdit} onDelete={handleDelete} />,
    //   },
    // ],
  };
}
