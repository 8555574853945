import Card from "@mui/material/Card";
import styled from "styled-components";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import CustomLabels from "layouts/bookings/pages/components/CustomLabels";
import { useState } from "react";
// const style = {
//     position: 'absolute' as 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 400,
//     bgcolor: 'background.paper',
//     border: '2px solid #000',
//     boxShadow: 24,
//     p: 4,
//   };

function EditCustomerDialog({ onClose, onDelete, onEdit, onReport, customer, isDelete }) {
  const [name, setName] = useState(customer.name);
  const [surname, setSurname] = useState(customer.surname);
  const [phone, setPhone] = useState(customer.phone);
  const [email, setEmail] = useState(customer.email);
  const [dob, setDob] = useState(customer.dob);
  const [nid, setNid] = useState(customer.personal_id);
  const [city, setCity] = useState(customer.city);
  const [address, setAddress] = useState(customer.address);

  const handleEdit = () => {
    const c = {
      id: customer.id,
      name,
      surname,
      phone,
      email,
      dob,
      nid,
      city,
      address,
    };
    onEdit(c);
  };
  return (
    <S.Card>
      <S.Conainer>
        <S.CloseBtnDiv>
          <MDTypography
            component="span"
            color="secondary"
            onClick={onClose}
            style={{ cursor: "pointer" }}
          >
            <Icon color="red">close</Icon>
          </MDTypography>
        </S.CloseBtnDiv>

        <div
          style={{
            width: "100%",
            textAlign: "center",
            color: "#C20B7E",
            fontSize: 30,
            marginBottom: 20,
          }}
        >
          {isDelete ? "Jeni i sigurt që do ta fshini këtë kartë klienti?" : "Kartela e klientit"}
        </div>
        <Grid container spacing={3} style={{ flex: 1 }}>
          <Grid item xs={12} md={6} lg={6}>
            <CustomLabels title="Emri:" />
            <S.NoLabelTextField
              id="filled-basic-username"
              variant="filled"
              disabled={isDelete}
              label={null}
              value={name}
              onChange={(e) => setName(e.target.value)}
              black
              style={{
                width: "100%",
                textAlign: "left",
                color: "black",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <CustomLabels title="Mbiemri:" />
            <S.NoLabelTextField
              id="filled-basic-username"
              variant="filled"
              disabled={isDelete}
              label={null}
              value={surname}
              onChange={(e) => setSurname(e.target.value)}
              black
              style={{
                width: "100%",
                textAlign: "left",
                color: "black",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <CustomLabels title="Telefoni:" />
            <S.NoLabelTextField
              id="filled-basic-username"
              variant="filled"
              disabled={isDelete}
              label={null}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              black
              style={{
                width: "100%",
                textAlign: "left",
                color: "black",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <CustomLabels title="E-mail:" />
            <S.NoLabelTextField
              id="filled-basic-username"
              variant="filled"
              disabled={isDelete}
              label={null}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              black
              style={{
                width: "100%",
                textAlign: "left",
                color: "black",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <CustomLabels title="Data e lindjës:" />
            <S.NoLabelTextField
              id="filled-basic-username"
              variant="filled"
              disabled={isDelete}
              label={null}
              value={nid}
              onChange={(e) => setNid(e.target.value)}
              black
              style={{
                width: "100%",
                textAlign: "left",
                color: "black",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <CustomLabels title="Numri personal:" />
            <S.NoLabelTextField
              id="filled-basic-username"
              variant="filled"
              disabled={isDelete}
              label={null}
              value={dob}
              onChange={(e) => setDob(e.target.value)}
              black
              style={{
                width: "100%",
                textAlign: "left",
                color: "black",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <CustomLabels title="Qyteti:" />
            <S.NoLabelTextField
              id="filled-basic-username"
              variant="filled"
              disabled={isDelete}
              label={null}
              value={city}
              onChange={(e) => setCity(e.target.value)}
              black
              style={{
                width: "100%",
                textAlign: "left",
                color: "black",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <CustomLabels title="Adresa:" />
            <S.NoLabelTextField
              id="filled-basic-username"
              variant="filled"
              disabled={isDelete}
              label={null}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              black
              style={{
                width: "100%",
                textAlign: "left",
                color: "black",
              }}
            />
          </Grid>
        </Grid>
        <S.BottomContainer>
          <Grid container spacing={1} style={{ flex: 1 }}>
            {isDelete ? (
              <Grid item xs={12} md={12} lg={12}>
                <S.Button onClick={() => onDelete(customer.id)}>Fshij </S.Button>
              </Grid>
            ) : (
              <>
                <Grid item xs={12} md={6} lg={6}>
                  <S.Button onClick={handleEdit}>Edito</S.Button>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <S.Button dark onClick={() => onReport(customer.id)}>
                    Raporti i klientit
                  </S.Button>
                </Grid>
              </>
            )}
          </Grid>
        </S.BottomContainer>
      </S.Conainer>
    </S.Card>
  );
}

const S = {
  Card: styled(Card)`
    width: 40%;
    height: 90%;
    max-height: 1024px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 40px;
    @media (max-width: 1024px) {
      width: 90%;
      height: 90%;
      padding: 20px;
    }
  `,
  Conainer: styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `,
  CloseBtnDiv: styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
  `,
  NoLabelTextField: styled(TextField)`
    width: 100%;
    .MuiFilledInput-root {
      padding: 5px !important;
    }
    input {
      padding: 0 !important;
      text-align: ${(props) => (props.black ? "left" : "center")};
      color: ${(props) => (props.black ? "black" : "#C20B7E")};
      height: 50x;
      font-size: ${(props) => (props.black ? "24px" : "30px")};
    }
  `,
  BottomContainer: styled.div`
    height: 60px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  `,
  Button: styled(Button)`
    height: 50px !important;
    width: 100% !important;
    border-radius: 5px !important;
    background-color: ${(props) => (props.dark ? "gray !important" : "#C20B7E !important")};
    text-transform: none !important;
    font-size: 20px !important;
    font-weight: 100 !important;
    color: white !important;
    :hover {
      background-color: ${(props) => (props.dark ? "lightgray !important" : "#E560B3 !important")};
      color: white !important;
    }
  `,
};

EditCustomerDialog.defaultProps = {
  isDelete: false,
};
EditCustomerDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onReport: PropTypes.func.isRequired,
  customer: PropTypes.instanceOf(Object).isRequired,
  isDelete: PropTypes.bool,
};

export default EditCustomerDialog;
