/* eslint-disable import/no-unresolved */
import styled from "styled-components";
import Grid from "@mui/material/Grid";
import Calendar from "react-calendar";
import IconButton from "layouts/bookings/pages/components/IconButton";
import { useState } from "react";
import Select from "react-select";
import Moment from "moment/moment";
import { Button } from "@mui/material";
import LiveClock from "layouts/bookings/pages/BookingsPage/LiveClock";
import config from "configs/config.json";
import PropTypes from "prop-types";
import { callServiceGetAuth } from "services/AuthServices";
// import { DataGrid } from "@mui/x-data-grid";
import MaterialReactTable from "material-react-table";
import Icon from "@mui/material/Icon";
import "./index.css";

const TAG = "BookingPage";

const convertData2Option2 = (data) =>
  data.map((item) => ({
    value: item.pk,
    label: item.fields.name,
  }));

// const tmpdata = [
//   {
//     staff: "John",
//     name: 30,
//     time: "John",
//     service: 30,
//     comment: "John",
//     date: 30,
//     phone: "John",
//   },
// ];
const tmpcolumns = [
  {
    accessorKey: "no", // simple recommended way to define a column
    header: "Nr",
    muiTableHeadCellProps: { sx: { color: "#FFF" } }, // custom props
    size: 50, // increase the width of this column
    headerStyle: {
      backgroundColor: "#039be5",
    },
  },
  {
    accessorKey: "staff", // simple recommended way to define a column
    header: "Stafi",
    muiTableHeadCellProps: { sx: { color: "#FFF" } }, // custom props
    size: 120, // increase the width of this column
    headerStyle: {
      backgroundColor: "#039be5",
    },
  },
  {
    accessorKey: "name", // simple recommended way to define a column
    header: "Emri & Mbiemri",
    muiTableHeadCellProps: { sx: { color: "#FFF" } }, // custom props
    size: 120, // increase the width of this column
  },
  {
    accessorKey: "time", // simple recommended way to define a column
    header: "Ora",
    muiTableHeadCellProps: { sx: { color: "#FFF" } }, // custom props
    size: 120, // increase the width of this column
    Cell: (prop) => {
      const { cell } = prop;
      const row = cell.getValue();
      if (row.booking_datetime) {
        return `${Moment(row.booking_datetime).utc().format("HH:mm")}~${Moment(row.endingTime)
          .utc()
          .format("HH:mm")}`;
      }
      return "";
    },
  },
  {
    accessorKey: "service", // simple recommended way to define a column
    header: "Shërbimi",
    muiTableHeadCellProps: { sx: { color: "#FFF" } }, // custom props
    size: 120, // increase the width of this column
    Cell: (prop) => {
      const { cell } = prop;
      const services = [...cell.getValue()];
      if (services.length > 0) {
        console.log("---------------------", services[0]);
        return convertData2Option2(services[0])
          .map((item) => item.label)
          .join(" | ");
      }

      return "";
    },
  },
  {
    accessorKey: "comment", // simple recommended way to define a column
    header: "Komenti",
    muiTableHeadCellProps: { sx: { color: "#FFF" } }, // custom props
    size: 120, // increase the width of this column
  },
  {
    accessorKey: "date", // simple recommended way to define a column
    header: "Data",
    muiTableHeadCellProps: { sx: { color: "#FFF" } }, // custom props
    size: 120, // increase the width of this column
  },
  {
    accessorKey: "phone", // simple recommended way to define a column
    header: "Telefoni",
    muiTableHeadCellProps: { sx: { color: "#FFF" } }, // custom props
    size: 120, // increase the width of this column
  },
  {
    accessorKey: "tools", // simple recommended way to define a column
    header: "Mjetet ",
    muiTableHeadCellProps: { sx: { color: "#FFF" } }, // custom props
    size: 120, // increase the width of this column
    Cell: (prop) => {
      console.log("prop = ", prop);
      const { cell } = prop;
      const params = cell.getValue();
      // const { editData, bookingData } = prop;
      // const services = cell.getValue();
      // if (services.length > 1)
      //   return convertData2Option2(services)
      //     .map((item) => item.label)
      //     .join(" | ");

      return (
        <button
          type="button"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            border: 0,
          }}
          onClick={() => {
            params.editData(params.bookingData);
          }}
        >
          <Icon color="red" fontSize="medium">
            create_outlined_icon
          </Icon>
        </button>
      );
    },
  },
];

function BookingsPage({ allStaffs, alertDispatch, showAlert, allServices, editBooking }) {
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(Moment().format("DD.MM.YYYY"));
  const [staff, setStaff] = useState(null);
  const [service, setService] = useState(null);
  const [bookList, setBookList] = useState([]);

  console.log(staff, alertDispatch, showAlert, bookList);

  const editData = (data) => {
    // console.log("editData = ", data);
    editBooking(data);
  };

  const convertData2Option = (data) =>
    data.map((item) => ({
      value: item.id,
      label: item.name,
    }));

  const handleSearchResponse = async (res, isError) => {
    if (isError) {
      alertDispatch(showAlert({ type: "error", msg: res }));
    }

    if (res.data) {
      const fields = res.data.map((item) => {
        const tmpFields = item.fields;
        tmpFields.id = item.pk;
        return tmpFields;
      });

      let tmpFields = [];
      const tmpRes = [];
      //   staff: "John",
      // name: 30,
      // time: "John",
      // service: 30,
      // comment: "John",
      // date: 30,
      // phone: "John",

      while (fields.length > 0) {
        const tmpField = fields[0];
        tmpFields = fields.filter(
          (item) =>
            item.booking_datetime === tmpField.booking_datetime &&
            item.client.pk === tmpField.client.pk
        );
        // tmpServices = item.service.map()
        tmpField.services = tmpFields.map((item) => {
          const tmpServices = item.service.map((i) => i[0]);
          return tmpServices;
        });
        console.log("servicessss = ", ...tmpField.services);
        let totalTimes = 0;
        let totalPrice = 0;
        tmpField.services.forEach((item) => {
          totalTimes += item.service_time;
          totalPrice += item.price;
        });
        tmpField.totalTimes = totalTimes;
        tmpField.totalPrice = totalPrice;
        tmpField.endingTime = Moment(tmpField.booking_datetime).utc().add(totalTimes, "minutes");
        tmpFields.forEach((item) =>
          fields.splice(
            fields.findIndex(
              (e) => e.booking_datetime === item.booking_datetime && e.client.pk === item.client.pk
            ),
            1
          )
        );

        const tmpData = {};
        tmpData.staff = tmpField.staff.name;
        tmpData.name = `${tmpField.client.name} ${tmpField.client.surname}`;
        tmpData.time = {
          booking_datetime: tmpField.booking_datetime,
          endingTime: tmpField.endingTime,
        };
        tmpData.service = tmpField.services;
        tmpData.comment = tmpField.comment;
        tmpData.date = Moment(tmpField.booking_datetime).utc().format("DD.MM.YYYY");
        tmpData.phone = tmpField.client.phone;
        tmpData.no = parseInt(tmpRes.length, 10) + 1;
        tmpData.tools = { editData, bookingData: tmpField };
        tmpRes.push(tmpData);
      }

      console.log(TAG, "res = ", tmpRes, fields);
      setBookList(tmpRes);
    }

    // console.log(bookList);
  };
  const callApiSearchData = async () => {
    let url = `${config.BASE_URL}${config.SEARCH_BOOKING}`;

    let sufix = "";
    if (selectedDate && selectedDate.length > 0) {
      sufix = `date=${Moment(selectedDate, "DD.MM.YYYY").format("YYYY-MM-DD")}`;
    }
    if (staff) {
      const strTmp = `staff=${staff.value}`;
      if (sufix.length > 0) {
        sufix += `&${strTmp}`;
      } else {
        sufix = strTmp;
      }
    }
    if (service) {
      const strTmp = `service=${service.value}`;
      if (sufix.length > 0) {
        sufix += `&${strTmp}`;
      } else {
        sufix = strTmp;
      }
    }

    if (sufix.length > 0) {
      url += `?${sufix}`;
    }

    console.log(TAG, "callApiSearchData url = ", url);
    callServiceGetAuth(url, handleSearchResponse);
  };

  const handleClickedSearch = () => {
    console.log("clicked search button");
    callApiSearchData();
  };

  return (
    <S.MainContainer>
      <Grid container spacing={3} style={{ flex: 1 }}>
        <Grid item xs={12} md={12} lg={12}>
          <div style={{ flex: 1, height: "100%", display: "flex", flexDirection: "column" }}>
            <Grid container spacing={3} flex justifyContent="space-between">
              <Grid
                item
                xs={12}
                md={12}
                lg={3}
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alginitems="center"
                position="relative"
              >
                <S.Labels>Kërko sipas datës:</S.Labels>
                <IconButton
                  onClick={() => setShowCalendar(!showCalendar)}
                  title={selectedDate}
                  icon="calendar_month_icon"
                  imageSize="30px"
                  fontSize={16}
                  iconColor="#C20B7E"
                  textColor="gray"
                />
                {showCalendar && (
                  <div style={{ position: "relative" }}>
                    <div style={{ position: "absolute" }}>
                      <Calendar
                        useWeekdaysShort
                        onChange={(value) => {
                          setShowCalendar(false);
                          setSelectedDate(Moment(value).format("DD.MM.YYYY"));
                        }}
                      />
                    </div>
                  </div>
                )}
              </Grid>
              <Grid item xs={12} md={12} lg={3}>
                <S.Labels>Kërko sipas stafit:</S.Labels>
                <S.Select
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable
                  isSearchable
                  placeholder="Zgjedh..."
                  value={staff}
                  onChange={(value) => setStaff(value)}
                  options={convertData2Option(allStaffs)}
                  style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={3}>
                <S.Labels>Kërko sipas shërbimit:</S.Labels>
                <S.Select
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable
                  placeholder="Zgjedh..."
                  isSearchable
                  value={service}
                  onChange={(value) => setService(value)}
                  options={convertData2Option(allServices)}
                  style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={1}
                display="flex"
                justifyContent="space-between"
                flexDirection="column"
                alginitems="center"
              >
                <S.Labels />
                <S.SearchButton onClick={handleClickedSearch}>Kërko</S.SearchButton>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={2}
                display="flex"
                justifyContent="space-between"
                flexDirection="column"
                alginitems="center"
              >
                <S.Labels />
                <S.ClockContainer>
                  <LiveClock />
                </S.ClockContainer>
              </Grid>
            </Grid>
            <div style={{ flex: 1, marginTop: "20px", width: "100%", overflow: "auto" }}>
              {/* <DataGrid
                rows={bookList}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                disableRowSelectionOnClick
                disableSelectAllCheckbox
                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
              /> */}

              <S.ResizableTable>
                <MaterialReactTable
                  id="datatable"
                  class="datatable"
                  headerStyle={{ backgroundColor: "red" }}
                  columns={tmpcolumns}
                  data={bookList}
                  // enableRowSelection // enable some features
                  // enableColumnOrdering
                  enableFilters={false}
                  enableColumnActions={false}
                  enableColumnResizing
                  columnResizeMode="onChange"
                  defaultColumn={{
                    maxSize: 400,
                    minSize: 50,
                    size: 80, // default size is usually 180
                  }}
                  style={{ backgroundColor: "green !important" }}
                  enableGlobalFilter={false} // turn off a feature
                />
              </S.ResizableTable>
            </div>
          </div>
        </Grid>
      </Grid>
    </S.MainContainer>
  );
}

const S = {
  MainContainer: styled.div`
    display: flex;
    flex-direction: column;
    padding: 30px;
    min-height: 800px;
    max-height: 100vh;
    overflow: auto;
  `,
  Labels: styled.div`
    color: gray;
    font-size: 16px;
    font-weight: 400;
  `,
  Select: styled(Select)`
    flex: 1;
    min-height: 50px !important;
    font-size: 15px !important;
    .select__control {
      background-color: rgba(0, 0, 0, 0.06);
      height: 50px;
    }
    .select__multi-value {
      border: 1px solid #C20B7E;
    }
    .select__menu {
      z-index: 1000 !important;
      position: absolute;
      background-color: white !important;
      width: auto !important;
      min-width: 100% !important;
    }
  `,
  SearchButton: styled(Button)`
    height: 50px !important;
    border-radius: 5px !important;
    background-color: #C20B7E !important;
    text-transform: none !important;
    font-size: 15px !important;
    font-weight: 100 !important;
    color: white !important;
    :hover {
      background-color: #90085d !important;
      color: white !important;
    }
  `,
  ClockContainer: styled.div`
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  `,

  ResizableTable: styled.div`
    background-color: red !important;
    .MuiPaper-root {
    }
    & .MuiToolbar-root:first-child .MuiBox-root {
      background-color: #C20B7E !important;
    }
    thead {
      background-color: #C20B7E !important;
    }

    .MuiTableHead-root .MuiTableRow-head {
      background-color: #C20B7E !important;
    }
    .MuiTableContainer-root {
      border-top: 1px solid lightgray;
      border-radius: 0px;
      padding-bottom: 50px;
      background-color: rgba(0, 0, 0, 0.06);
    }

    .MuiDivider-root {
      opacity: 0.75;
      border-color: white;
    }

    td {
      overflow: visible;
    }

    // & .MuiToolbar-root {
    //   background-color: #C20B7E;
    // }
    // .MuiToolbar-root .MuiToolbar-gutters .MuiToolbar-dense .css-1bit1fl-MuiToolbar-root {
    //   background0color: red;
    // }
  `,
};

BookingsPage.propTypes = {
  allServices: PropTypes.instanceOf(Array).isRequired,
  allStaffs: PropTypes.instanceOf(Array).isRequired,
  alertDispatch: PropTypes.func.isRequired,
  showAlert: PropTypes.func.isRequired,
  editBooking: PropTypes.func.isRequired,
};

export default BookingsPage;
