import { useState } from "react";

import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { showAlert } from "actions/alert";
import { showLoading } from "actions/loading";
import { callServicePostAuth } from "services/AuthServices";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CustomLabels from "layouts/bookings/pages/components/CustomLabels";
import config from "configs/config.json";

// import Moment from "moment/moment";

const TAG = "CreateEditService";

function CreateEditService({ selectedItem, onClose }) {
  const [name, setName] = useState(selectedItem ? selectedItem.name : "");
  // const [price, setPrice] = useState(selectedItem ? selectedItem.price : "");
  const [serviceTime, setServiceTime] = useState(selectedItem ? selectedItem.service_time : "");

  const dispatch = useDispatch();

  const handleAddNewStudioResponse = async (res, isErr) => {
    dispatch(showLoading(false));
    if (isErr) {
      dispatch(showAlert({ type: "error", msg: res }));
      return;
    }

    onClose();
  };

  const callApiAddStudio = async () => {
    const url = config.BASE_URL + config.ADD_SERVICE;

    const postData = {
      name,
      // price,
      serviceTime,
    };

    console.log(TAG, "callApiAddStudio url", url);
    console.log(TAG, "callApiAddStudio data = ", JSON.stringify(postData));

    callServicePostAuth(url, postData, handleAddNewStudioResponse);
  };

  const callApiEditStudio = () => {
    const url = config.BASE_URL + config.EDIT_SERVICE;

    const postData = {
      id: selectedItem.id,
      name,
      // price,
      serviceTime,
    };

    console.log(TAG, "callApiEditStudio url", url);
    console.log(TAG, "callApiEditStudio data = ", JSON.stringify(postData));

    callServicePostAuth(url, postData, handleAddNewStudioResponse);
  };

  const handleClickedRegister = () => {
    if (!selectedItem) {
      callApiAddStudio();
    } else {
      callApiEditStudio();
    }
  };

  return (
    <S.Card>
      <S.Header>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          Shërbimet
        </div>

        <S.CloseBtnContainer>
          <Button
            aria-label="delete"
            size="large"
            onClick={onClose}
            width={20}
            height={20}
            style={{ width: "20px", height: "20px", borderRadius: "20px", padding: 0, margin: 0 }}
          >
            <CloseIcon fontSize="large" color="white" />
          </Button>
        </S.CloseBtnContainer>
      </S.Header>
      <S.Container>
        <Grid container spacing={3} height="100%">
          <Grid item xs={12} md={12} lg={12}>
            <CustomLabels title="Emri :" fontSize={16} fontWeight={600} />
            <S.NoLabelTextField
              variant="filled"
              label={null}
              value={name}
              onChange={(val) => {
                setName(val.target.value);
              }}
            />
          </Grid>
          {/* <Grid item xs={12} md={12} lg={12}>
            <CustomLabels title="Price (€):" fontSize={16} fontWeight={600} />
            <S.NoLabelTextField
              variant="filled"
              label={null}
              value={price}
              onChange={(val) => {
                setPrice(val.target.value);
              }}
            />
          </Grid> */}
          <Grid item xs={12} md={12} lg={12}>
            <CustomLabels title="Service Time (mins):" fontSize={16} fontWeight={600} />
            <S.NoLabelTextField
              variant="filled"
              label={null}
              value={serviceTime}
              onChange={(val) => {
                setServiceTime(val.target.value);
              }}
            />
          </Grid>
        </Grid>
      </S.Container>
      <S.Bottom>
        <S.Button onClick={() => handleClickedRegister()} width={200}>
          {selectedItem ? "Ndrysho" : "Krijo"}
        </S.Button>
      </S.Bottom>
    </S.Card>
  );
}

const S = {
  Card: styled(Card)`
    width: 60%;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    overflow: auto;

    @media (max-width: 1024px) {
      width: 90%;
    }
  `,
  Header: styled.div`
    height: 50px;
    color: white;
    background-color: #c20b7e;
    font-size: 1.5rem;
    font-weight: 400;
    width: 100%;
    position: relative;

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  `,
  CloseBtnContainer: styled.div`
    position: absolute;
    right: 10px;
    top: 2px;
    color: white;
  `,
  Container: styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    overflow: auto;
    @media (max-width: 1024px) {
      padding: 40px 20px;
    }
    @media (max-width: 768px) {
      padding: 20px;
    }
  `,
  Bottom: styled.div`
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 10px;
  `,
  Button: styled(Button)`
    height: ${(props) => (props.height ? `${props.height}px` : "50px")} !important;
    width: ${(props) => (props.width ? `${props.width}px` : "100%")} !important;
    border-radius: 10px !important;
    background-color: ${(props) => (props.dark ? "gray !important" : "#C20B7E !important")};
    text-transform: none !important;
    font-size: 1rem !important;
    font-weight: 100 !important;
    color: white !important;
    margin-bottom: 2px;
    :hover {
      background-color: ${(props) => (props.dark ? "lightgray !important" : "#E560B3 !important")};
      color: white !important;
    }
  `,
  NoLabelTextField: styled(TextField)`
    width: 100%;
    .MuiFilledInput-root {
      padding: 5px !important;
    }
    input {
      padding: 0 !important;
      height: 40px;
    }
  `,

  Select: styled(Select)`
    flex: 1;

    border-radius: 0px;
    font-size: 1rem !important;

    .select__control {
      box-shadow: unset;
      background-color: rgba(0, 0, 0, 0.06);
      border-radius: 0px;
      min-height: 50px !important;
      font-size: 1rem;
      border: none;
      border-bottom: solid 1px #000000de;
      :hover {
        border: none;
        border-bottom: solid 1px #000000de;
      }
      :focuse {
        border: none;
      }
      :actived {
        border: none;
      }
    }
    .select__multi-value {
      border: 1px solid #c20b7e;
    }
    .select__indicator {
      color: #c20b7e !important;
    }
    .select__indicator-separator {
      display: none !important;
    }
  `,
};

CreateEditService.propTypes = {
  selectedItem: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
};
export default CreateEditService;
