import * as types from "./types";
import { login, getToken, getUserInfo } from "../services/AuthServices";

const FILE_TAG = "actions/auth.js";

export const getLoginInfo = () => async (dispatch) => {
  const user = await getUserInfo();
  console.log(user);
  if (user) {
    console.log(user);
    localStorage.setItem("user", JSON.stringify(user));

    dispatch({
      type: types.LOGIN_SUCCESS,
      payload: user,
    });

    return Promise.resolve();
  }
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("user");

  dispatch({
    type: types.LOGIN_FAIL,
  });

  return Promise.reject();
};

export const doLogin = (username, password) => async (dispatch) => {
  const res = await login(username, password);
  if (!res || (res && res.result === "success")) {
    const tokens = await getToken(username, password);

    if (tokens) {
      localStorage.setItem("access_token", tokens.access);
      localStorage.setItem("refresh_token", tokens.refresh);
    }

    dispatch({
      type: types.LOGIN_SUCCESS,
      payload: { user: res.user },
    });

    localStorage.setItem("user", JSON.stringify(res.user));

    return Promise.resolve();
  }
  dispatch({
    type: types.LOGIN_FAIL,
  });
  if (res && res.msg) {
    dispatch({
      type: types.SHOW_ALERT,
      payload: { type: "error", msg: res.msg },
    });

    console.log(FILE_TAG, "error", res.msg);
  }
  return Promise.reject();
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("user");
  dispatch({
    type: types.LOGOUT,
  });
};
