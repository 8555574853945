import { useState } from "react";

import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { showAlert } from "actions/alert";
import { showLoading } from "actions/loading";
import { callServicePostAuth } from "services/AuthServices";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CustomLabels from "layouts/bookings/pages/components/CustomLabels";
import CustomCalender from "layouts/bookings/pages/components/CustomCalendars/CustomCalender";
import IconButton from "layouts/bookings/pages/components/IconButton";
import config from "configs/config.json";
import Moment from "moment/moment";

const TAG = "CreateEditStaff";

function CreateEditStaff({ selectedItem, studios, onClose }) {
  console.log(TAG, studios, selectedItem);

  const [name, setName] = useState(selectedItem ? selectedItem.fields.name : "");
  const [position, setPosition] = useState(selectedItem ? selectedItem.fields.position : "");
  const [phone, setPhone] = useState(selectedItem ? selectedItem.fields.phone : "");
  // const [studio, setStudio] = useState("");
  const [salaryFixed, setSalaryFixed] = useState(
    selectedItem ? selectedItem.fields.salary_fixed : ""
  );
  const [salaryPercent, setSalaryPercent] = useState(
    selectedItem ? selectedItem.fields.salary_percent : ""
  );

  const [showBeginCalendar, setShowBeginCalendar] = useState(false);
  const [showEndCalendar, setShowEndCalendar] = useState(false);
  const [beginDate, setBeginDate] = useState(
    selectedItem ? Moment(selectedItem.fields.started_date).format("DD.MM.YYYY") : ""
  );
  const [endDate, setEndDate] = useState(
    selectedItem ? Moment(selectedItem.fields.ended_date).format("DD.MM.YYYY") : ""
  );
  const [selectedStudio, setSelectedStudio] = useState(
    selectedItem ? selectedItem.fields.studio.pk : 0
  );

  const dispatch = useDispatch();

  const handleAddNewStaffResponse = async (res, isErr) => {
    dispatch(showLoading(false));
    if (isErr) {
      dispatch(showAlert({ type: "error", msg: res }));
      return;
    }

    onClose();
  };

  const callApiAddNewStaff = async () => {
    const url = config.BASE_URL + config.ADD_NEW_STAFF;

    const postData = {
      name,
      position,
      phone,
      studio: selectedStudio,
      salaryFixed: !salaryFixed || salaryFixed === "" ? "0" : salaryFixed,
      salaryPercent: !salaryPercent || salaryPercent === "" ? "0" : salaryPercent,
      beginDate: Moment(beginDate, "DD.MM.YYYY").format("YYYY-MM-DD"),
      endDate: Moment(endDate, "DD.MM.YYYY").format("YYYY-MM-DD"),
    };

    console.log(TAG, "callApiAddNewStaff url", url);
    console.log(TAG, "callApiAddNewStaff data = ", JSON.stringify(postData));

    callServicePostAuth(url, postData, handleAddNewStaffResponse);
  };

  const callApiEditStaff = () => {
    const url = config.BASE_URL + config.UPDATE_STAFF;

    const postData = {
      id: selectedItem.pk,
      name,
      position,
      phone,
      studio: selectedStudio,
      salaryFixed: !salaryFixed || salaryFixed === "" ? "0" : salaryFixed,
      salaryPercent: !salaryPercent || salaryPercent === "" ? "0" : salaryPercent,
      beginDate: Moment(beginDate, "DD.MM.YYYY").format("YYYY-MM-DD"),
      endDate: Moment(endDate, "DD.MM.YYYY").format("YYYY-MM-DD"),
    };

    console.log(TAG, "callApiAddNewStaff url", url);
    console.log(TAG, "callApiAddNewStaff data = ", JSON.stringify(postData));

    callServicePostAuth(url, postData, handleAddNewStaffResponse);
  };

  const handleClickedRegister = () => {
    if (!selectedItem) {
      callApiAddNewStaff();
    } else {
      callApiEditStaff();
    }
  };

  return (
    <S.Card>
      <S.Header>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          Stafi
        </div>

        <S.CloseBtnContainer>
          <Button
            aria-label="delete"
            size="large"
            onClick={onClose}
            width={20}
            height={20}
            style={{ width: "20px", height: "20px", borderRadius: "20px", padding: 0, margin: 0 }}
          >
            <CloseIcon fontSize="large" color="white" />
          </Button>
        </S.CloseBtnContainer>
      </S.Header>
      <S.Container>
        <Grid container spacing={3} height="100%">
          <Grid item xs={12} md={12} lg={12}>
            <CustomLabels title="Emri & mbiemri:" fontSize={16} fontWeight={600} />
            <S.NoLabelTextField
              variant="filled"
              label={null}
              value={name}
              onChange={(val) => {
                setName(val.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <CustomLabels title="Pozita:" fontSize={16} fontWeight={600} />
            <S.NoLabelTextField
              variant="filled"
              label={null}
              value={position}
              onChange={(val) => {
                setPosition(val.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <CustomLabels title="Telefoni:" fontSize={16} fontWeight={600} />

            <S.NoLabelTextField
              variant="filled"
              label={null}
              value={phone}
              onChange={(val) => {
                setPhone(val.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <CustomLabels title="Studio:" fontSize={16} fontWeight={600} />
            {/* <S.NoLabelTextField
              variant="filled"
              label={null}
              value={salaryFixed}
              onChange={(val) => {
                setSalaryFixed(val.target.value);
              }}
            /> */}
            <S.SelectFilter
              className="basic-single"
              classNamePrefix="select"
              isClearable
              isSearchable
              dropdown
              value={studios.filter((item) => item.id === selectedStudio)[0]}
              onChange={(value) => setSelectedStudio(value ? value.id : null)}
              options={studios}
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.2)",
                marginLeft: 10,
                height: "50px !important",
                fontSize: "1rem",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <CustomLabels title="Paga fikse:" fontSize={16} fontWeight={600} />
            <S.NoLabelTextField
              variant="filled"
              label={null}
              value={salaryFixed}
              onChange={(val) => {
                setSalaryFixed(val.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <CustomLabels title="Paga me përqindje:" fontSize={16} fontWeight={600} />
            <S.NoLabelTextField
              variant="filled"
              label={null}
              value={salaryPercent}
              onChange={(val) => {
                setSalaryPercent(val.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <CustomLabels title="Data e fillimit:" fontSize={16} fontWeight={600} />
            <div style={{ position: "relative", width: "100%", height: "50px" }}>
              <IconButton
                onClick={() => {
                  setShowBeginCalendar(!showBeginCalendar);
                  setShowEndCalendar(false);
                }}
                title={beginDate}
                icon="calendar_month_icon"
                imageSize="30px"
                fontSize={16}
                iconColor="#C20B7E"
                textColor="gray"
                contentPadding={10}
                disableBorder
                borderRadius={10}
              />
              {showBeginCalendar && (
                <div style={{ width: "100%", position: "absolute", zIndex: 10 }}>
                  <CustomCalender
                    onChange={(value) => {
                      setBeginDate(Moment(value).format("DD.MM.YYYY"));
                      setShowBeginCalendar(false);
                    }}
                    useWeekdaysShort
                  />
                </div>
              )}
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <CustomLabels title="Data e mbarimit:" fontSize={16} fontWeight={600} />
            <div style={{ position: "relative", width: "100%" }}>
              <IconButton
                onClick={() => {
                  setShowEndCalendar(!showEndCalendar);
                  setShowBeginCalendar(false);
                }}
                title={endDate}
                icon="calendar_month_icon"
                imageSize="30px"
                fontSize={16}
                iconColor="#C20B7E"
                textColor="gray"
                contentPadding={10}
                disableBorder
                borderRadius={10}
              />
              {showEndCalendar && (
                <div style={{ width: "100%", position: "absolute", zIndex: 10 }}>
                  <CustomCalender
                    onChange={(value) => {
                      setEndDate(Moment(value).format("DD.MM.YYYY"));
                      setShowEndCalendar(false);
                    }}
                    useWeekdaysShort
                    minDate={beginDate ? new Date(Moment(beginDate, "DD.MM.YYYY")) : new Date()}
                  />
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </S.Container>
      <S.Bottom>
        <S.Button onClick={() => handleClickedRegister()} width={200}>
          Regjistro
        </S.Button>
      </S.Bottom>
    </S.Card>
  );
}

const S = {
  Card: styled(Card)`
    width: 40%;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 90%;
    @media (max-width: 1024px) {
      width: 90%;
      height: 90%;
    }
  `,
  Header: styled.div`
    height: 50px;
    color: white;
    background-color: #C20B7E;
    font-size: 1.5rem;
    font-weight: 400;
    width: 100%;
    position: relative;

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  `,
  CloseBtnContainer: styled.div`
    position: absolute;
    right: 10px;
    top: 2px;
    color: white;
  `,
  Container: styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    overflow: auto;
    @media (max-width: 1024px) {
      padding: 40px 20px;
    }
    @media (max-width: 768px) {
      padding: 20px;
    }
  `,
  Bottom: styled.div`
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 10px;
  `,
  Button: styled(Button)`
    height: ${(props) => (props.height ? `${props.height}px` : "50px")} !important;
    width: ${(props) => (props.width ? `${props.width}px` : "100%")} !important;
    border-radius: 10px !important;
    background-color: ${(props) => (props.dark ? "gray !important" : "#C20B7E !important")};
    text-transform: none !important;
    font-size: 1rem !important;
    font-weight: 100 !important;
    color: white !important;
    margin-bottom: 2px;
    :hover {
      background-color: ${(props) => (props.dark ? "lightgray !important" : "#E560B3 !important")};
      color: white !important;
    }
  `,
  NoLabelTextField: styled(TextField)`
    width: 100%;
    .MuiFilledInput-root {
      padding: 5px !important;
    }
    input {
      padding: 0 !important;
      height: 40px;
    }
  `,

  SelectFilter: styled(Select)`
    flex: 1;

    border-radius: 0px;
    font-size: 1rem !important;

    .select__control {
      box-shadow: unset;
      background-color: rgba(0, 0, 0, 0.06);
      border-radius: 0px;
      min-height: 50px !important;
      font-size: 1rem;
      border: none;
      border-bottom: solid 1px #000000de;
      :hover {
        border: none;
        border-bottom: solid 1px #000000de;
      }
      :focuse {
        border: none;
      }
      :actived {
        border: none;
      }
    }
    .select__multi-value {
      border: 1px solid #C20B7E;
    }
    .select__indicator {
      color: #C20B7E !important;
    }
    .select__indicator-separator {
      display: none !important;
    }
  `,
};

CreateEditStaff.propTypes = {
  selectedItem: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
  studios: PropTypes.instanceOf(Array).isRequired,
};
export default CreateEditStaff;
