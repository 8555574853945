/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";

// Material Dashboard 2 React routes
// import { adminRoutes as routes } from "routes";
import { routes, adminRoutes } from "routes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav } from "context";

// Images
import brandWhite from "assets/images/logo-gray.png";
// import brandDark from "assets/images/logo-ct-dark.png";

import { isAuthenticated } from "services/AuthServices";

import SignIn from "layouts/authentication/sign-in";
import PropTypes from "prop-types";

import { useSelector, useDispatch } from "react-redux";
import MDSnackbar from "components/MDSnackbar";
import { showAlert } from "./actions/alert";
// import { showLoading } from "./actions/loading";

function AuthenticatedRoute(props) {
  const { component, exact, path } = props;
  return <Route exact={exact} path={path} render={isAuthenticated() ? component : <SignIn />} />;
}

AuthenticatedRoute.propTypes = {
  component: PropTypes.element.isRequired,
  exact: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
};

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    sidenavColor,
    // transparentSidenav,
    // whiteSidenav,
    // darkMode,
  } = controller;

  const { user } = useSelector((state) => state.auth);
  const { alertInfo } = useSelector((state) => state.alert);
  const bshowLoading = useSelector((state) => state.loading);
  // const bshowLoading = true;
  const reduxDispatch = useDispatch();

  const [onMouseEnter, setOnMouseEnter] = useState(false);

  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      // if (route.collapse) {
      //   return getRoutes(route.collapse);
      // }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={isAuthenticated() ? route.component : <SignIn />}
            key={route.key}
          />
        );
        // return <AuthenticatedRoute exact path={route.route} component={route.component} />;
      }

      return null;
    });

  const handleClose = (event, reason) => {
    console.log(event);
    if (reason === "clickaway") {
      return;
    }
    reduxDispatch(showAlert(null));
  };

  return (
    <ThemeProvider theme={theme}>
      <div style={{ width: "100%", height: "100%", display: miniSidenav ? "inherit" : "flex" }}>
        <CssBaseline />
        {layout === "dashboard" && isAuthenticated() && (
          <Sidenav
            color={sidenavColor}
            brand={brandWhite}
            brandName={
              user && user.role === 1 ? "Paneli i administratorit" : "Paneli i përdoruesit"
            }
            routes={user && user.role === 1 ? adminRoutes : routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        )}
        {layout === "vr" && <Configurator />}

        <Routes>
          {getRoutes(user && user.role === 1 ? adminRoutes : routes)}
          <Route
            path="*"
            element={<Navigate to={user && user.role === 1 ? "/salesreport" : "/ballina"} />}
          />
        </Routes>

        {alertInfo && (
          <MDSnackbar
            autoHideDuration={6000}
            color={alertInfo.type}
            title={
              // eslint-disable-next-line no-nested-ternary
              alertInfo.type === "success"
                ? "Njoftim"
                : alertInfo.type === "error"
                ? "Gabim "
                : alertInfo.type
            }
            content={alertInfo.msg}
            open={alertInfo && true}
            onClose={handleClose}
            close={handleClose}
          />
        )}
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme2) => theme2.zIndex.drawer + 1 }}
          open={bshowLoading !== undefined && bshowLoading === true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </ThemeProvider>
  );
}
