/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// import { useMemo } from "react";
// @mui material components
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
// import { Line } from "react-chartjs-2";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import configs from "examples/Charts/LineCharts/NoAxisLineChart/configs";

const TAG = "ComplexStatisticsCard";
function ComplexStatisticsCard({
  color,
  title,
  count,
  // percentage,
  percent,
  txtColor,
  icon,
  chart,
}) {
  console.log(TAG, icon, color, chart);
  // const { data, options } = configs(
  //   (chart && chart.labels) || [],
  //   (chart && chart.datasets) || {},
  //   txtColor,
  //   color
  // );
  // console.log(options);
  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
        {/* <MDBox
          variant="gradient"
          bgColor={color}
          color={color === "light" ? "dark" : "white"}
          coloredShadow={color}
          borderRadius="xl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="4rem"
          height="4rem"
          mt={-3}
        >
          <Icon fontSize="medium" color="inherit">
            {icon}
          </Icon>
        </MDBox> */}
        <MDBox lineHeight={1.25} display="flex" justifyContent="space-between" width="100%">
          <MDTypography variant="button" fontWeight="light" color="text" textwrap="wrap">
            {title}
          </MDTypography>
          {/* <MDTypography variant="h4">{count}</MDTypography> */}
          <MDBox
            variant="contained"
            bgColor={color}
            px="5px"
            borderRadius="5px"
            display="flex"
            alignItems="center"
          >
            <Icon fontSize="medium" style={{ color: txtColor, fontSize: 40 }}>
              {icon}
            </Icon>
            <MDTypography variant="text" fontSize="12px" style={{ color: txtColor }}>
              {percent}%
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox pt={1} px={2} my={1.5} justifyContent="center" display="flex" width="100%">
        <MDTypography variant="text" style={{ color: txtColor }} fontSize="35px">
          {count}
        </MDTypography>
      </MDBox>
      {/* <Divider /> */}
      {/* <MDBox pb={2} px={2}>
        <MDTypography component="p" variant="button" color="text" display="flex">
          <MDTypography
            component="span"
            variant="button"
            fontWeight="bold"
            color={percentage.color}
          >
            {percentage.amount}
          </MDTypography>
          &nbsp;{percentage.label}
        </MDTypography>
      </MDBox> */}
      {/* <MDBox pb={2} px={2}>
        {useMemo(
          () => (
            <div style={{ height: "4rem" }}>
              <Line data={data} options={options} />
            </div>
          ),
          [chart]
        )}
      </MDBox> */}
    </Card>
  );
}

// Setting default values for the props of ComplexStatisticsCard
ComplexStatisticsCard.defaultProps = {
  txtColor: "inherit",
  chart: null,
  // percentage: {
  //   color: "success",
  //   text: "",
  //   label: "",
  // },
};

// Typechecking props for the ComplexStatisticsCard
ComplexStatisticsCard.propTypes = {
  chart: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])),
  color: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  // percentage: PropTypes.shape({
  //   color: PropTypes.oneOf([
  //     "primary",
  //     "secondary",
  //     "info",
  //     "success",
  //     "warning",
  //     "error",
  //     "dark",
  //     "white",
  //   ]),
  //   amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  //   label: PropTypes.string,
  // }),
  icon: PropTypes.node.isRequired,
  txtColor: PropTypes.string,
  percent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default ComplexStatisticsCard;
