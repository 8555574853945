import { Button } from "@mui/material";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import { useRef, useLayoutEffect } from "react";
import styled from "styled-components";

function IconButton({
  onClick,
  title,
  icon,
  imageSize,
  fontSize,
  iconColor,
  textColor,
  height,
  contentPadding,
  disableBorder,
  borderRadius,
}) {
  const btnRef = useRef(null);
  useLayoutEffect(() => {
    btnRef.current.style.setProperty("font-size", imageSize, "important");
  }, []);

  return (
    <S.Button
      onClick={onClick}
      style={{
        fontSize: `${fontSize}px !important`,
      }}
      height={height}
      contentpadding={contentPadding}
      disableborders={disableBorder}
      borderradius={borderRadius}
    >
      <Icon
        ref={btnRef}
        fontSize="large"
        style={{ color: iconColor, fontSize: `${fontSize}px !important` }}
      >
        {icon}
      </Icon>
      <div
        style={{
          flex: 1,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: textColor,
          fontSize,
        }}
      >
        {title}
      </div>
    </S.Button>
  );
}

const S = {
  Button: styled(Button)`
    width: 100%;
    min-width: 150px;
    background-color: rgba(0, 0, 0, 0.06) !important;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding-left: ${(props) =>
      props.height ? `${props.contentPadding}px !important` : "30px !important"};
    padding-right: ${(props) =>
      props.height ? `${props.contentPadding}px !important` : "30px !important"};
    height: ${(props) => (props.height ? props.height : "50px")};
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: ${(props) => (props.disableBorder ? "none" : "1px gray solid")};
    border-radius: ${(props) =>
      props.borderradius ? `${props.borderradius}px !important` : "none"};
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
  `,
};
IconButton.defaultProps = {
  height: "50px",
  borderRadius: undefined,
  disableBorder: undefined,
  contentPadding: undefined,
};
IconButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  imageSize: PropTypes.string.isRequired,
  fontSize: PropTypes.number.isRequired,
  iconColor: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  contentPadding: PropTypes.number,
  disableBorder: PropTypes.bool,
  borderRadius: PropTypes.number,
  height: PropTypes.string,
};

export default IconButton;
