import { SHOW_ALERT, CLEAR_ALERT } from "./types";

export const showAlert = (info) => ({
  type: SHOW_ALERT,
  payload: info,
});

export const clearAlert = () => ({
  type: CLEAR_ALERT,
});
