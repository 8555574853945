import PropTypes from "prop-types";

import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Divider from "@mui/material/Divider";
import AddIcon from "@mui/icons-material/Add";
import RowItems from "layouts/dashboard/components/Tasks/RowItems";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Moment from "moment/moment";

import { useDispatch } from "react-redux";
import { showAlert } from "actions/alert";
import { showLoading } from "actions/loading";
import { callServiceGetAuth, callServicePostAuth } from "services/AuthServices";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import config from "configs/config.json";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import styled from "styled-components";
import AddTodoListModal from "./AddTodoListModal";

//   Monday - E hënë
// Tuesday - E martë
// Wednesday - E mërkure
// Thursday - E enjte
// Friday - E premte
// Saturday - E shtunë
// Sunday - E diel
// const arrDayofWeekNames = [
//   "Monday",
//   "Tuesday",
//   "Wednesday",
//   "Thursday",
//   "Friday",
//   "Saturday",
//   "Sunday",
// ];
function Tasks({ title }) {
  const [openAddTodoModal, setOpenAddTodoModal] = useState(false);
  const [todoList, setTodolist] = useState([]);
  const [allTodoList, setAllTodoList] = useState([]);
  const [showCompleted, setShowCompleted] = useState(false);
  const [deleteDlgOpen, setDeleteDlgOpen] = useState(false);
  const [selectedId2Delete, setSelectedId2Delete] = useState();

  const dispatch = useDispatch();

  const handleCloseDlg = () => {
    setDeleteDlgOpen(false);
  };

  let callApiGetTodoList = async () => {};

  const handleCloseAddTodoModal = (val) => {
    console.log("handleCloseDlg", val);
    callApiGetTodoList();
    setOpenAddTodoModal(false);
  };

  const handleActiveTodoResponse = async (res, isErr) => {
    dispatch(showLoading(false));
    if (isErr) {
      dispatch(showAlert({ type: "error", msg: res }));
    }
    if (res.result === "success") callApiGetTodoList();
  };

  const callApiActiveTodo = async (id, isLoop) => {
    console.log(isLoop);
    const url = `${config.BASE_URL}${config.TODOLIST_ACTIVE}`;
    const postData = {
      id,
      status: 1,
    };
    console.log("handleClickTodoActived = ", id, url);

    dispatch(showLoading(false));

    callServicePostAuth(url, postData, handleActiveTodoResponse);
  };
  const handleClickTodoActived = (id, isLoop) => {
    callApiActiveTodo(id, isLoop);
  };

  const handleDeleteToResponse = (res, isErr) => {
    dispatch(showLoading(false));
    if (isErr) {
      dispatch(showAlert({ type: "error", msg: res }));
      return;
    }
    callApiGetTodoList();
    setDeleteDlgOpen(false);
  };

  const callApiDeleteTodo = async () => {
    const url = `${config.BASE_URL}${config.TODOLIST_DELETE}`;
    const postData = {
      id: selectedId2Delete,
    };
    console.log("handleClickTodoActived = ", selectedId2Delete, url);

    dispatch(showLoading(false));

    callServicePostAuth(url, postData, handleDeleteToResponse);
  };

  const handleClickTodoDel = (id) => {
    setDeleteDlgOpen(true);
    setSelectedId2Delete(id);
  };

  useEffect(() => {
    const tmpTodolist = [];
    allTodoList.forEach((item) => {
      const field = item.fields;
      console.log(field);

      const nowDayofWeek = Moment(new Date()).weekday();

      // const nowDayofWeek2 = (nowDayofWeek - 1) ** 2;
      const diffDays = Math.floor(
        Math.abs(Moment(field.created_at).diff(Moment(new Date()), "days"))
      );
      // if (field.looptype === 0 && diffDays !== 0) {
      //   return;
      // }

      // if (showCompleted === true && field.status === 0) return;

      // if (field.looptype !== 0) {
      //   const diffDate2 = Math.floor(
      //     Math.abs(Moment(new Date()).diff(Moment(field.updated_at), "days"))
      //   );
      //   if (diffDate2 === 0 && showCompleted !== true && field.status === 1) return;
      // }

      // if (field.looptype === 0 && diffDays === 0 && field.status === 1 && showCompleted !== true)
      //   return;

      // // eslint-disable-next-line no-bitwise
      // const checkAvailableDay = nowDayofWeek2 & field.looptype;
      // if (field.looptype !== -1 && field.looptype > 0 && !checkAvailableDay) {
      //   return;
      // }

      let lblPlanDate = "";
      if (field.looptype === 1 && diffDays === 0) lblPlanDate = "Sot";
      else if (field.looptype === 2) {
        // lblPlanDate = arrDayofWeekNames[nowDayofWeek - 1];
        lblPlanDate = "Çdo ditë";
      } else {
        const diffDays2 = Math.floor(Moment(field.planDate).diff(Moment(new Date()), "days"));
        if (diffDays2 >= 0) {
          lblPlanDate = field.planDate;
        } else return;
      }

      console.log("newDayofWeek = ", nowDayofWeek, field, lblPlanDate);

      const todo = {
        id: item.pk,
        title: field.title,
        content: field.content,
        planTime: field.planTime,
        looptype: field.looptype,
        status: field.status,
        lblPlanDate,
      };

      tmpTodolist.push(todo);
    });

    setTodolist(tmpTodolist);
  }, [allTodoList, showCompleted]);

  const handleGetTodoListResponse = (res, isErr) => {
    dispatch(showLoading(false));
    if (isErr) {
      dispatch(showAlert({ type: "error", msg: res }));
      return;
    }
    console.log(res, isErr);
    if (res.data) {
      const data = [...res.data];
      setAllTodoList(data);
    }
  };

  callApiGetTodoList = async () => {
    const url = `${config.BASE_URL}${config.TODOLIST_TODAY}`;

    callServiceGetAuth(url, handleGetTodoListResponse);
  };

  useEffect(() => {
    callApiGetTodoList();
  }, []);

  const showCompletedData = (isFlag) => {
    setShowCompleted(isFlag);
  };

  const handleClickDeleteBtn = () => {
    callApiDeleteTodo();
  };

  return (
    <Card height="100%" style={{ height: "100%" }}>
      <MDBox pt={2} px={2} display="flex" flexDirection="column">
        <Dialog
          open={deleteDlgOpen}
          onClose={handleCloseDlg}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Konfirmo fshirjen</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Jeni i sigurt që dëshironi ta fshini këtë? Ky veprim nuk mund të zhbëhet.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDlg}>Anulo</Button>
            <S.Button
              variant="contained"
              color="success"
              onClick={handleClickDeleteBtn}
              autoFocus
              width={100}
            >
              Fshije
            </S.Button>
          </DialogActions>
        </Dialog>
        <Modal
          open={openAddTodoModal}
          onClose={handleCloseAddTodoModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <AddTodoListModal onClose={handleCloseAddTodoModal} />
        </Modal>
        <MDBox
          lineHeight={1.25}
          display="flex"
          justifyContent="space-between"
          width="100%"
          py={1}
          px={2}
        >
          <MDTypography
            variant="text"
            flex={1}
            fontWeight="light"
            color="text"
            textwrap="wrap"
            style={{ fontSize: 20 }}
          >
            {title}
          </MDTypography>
          <MDTypography
            variant="div"
            flex={1}
            display="flex"
            justifyContent="flex-end"
            fontWeight="light"
            color="text"
            textwrap="wrap"
            style={{ fontSize: 20 }}
          >
            <IconButton aria-label="delete" onClick={() => setOpenAddTodoModal(true)}>
              <AddIcon />
            </IconButton>
          </MDTypography>
        </MDBox>
        <FormControlLabel
          control={<Checkbox />}
          label="Shfaq të përfunduar"
          style={{ overflow: "nowrap", marginLeft: 10 }}
          onChange={(val) => showCompletedData(val.target.checked)}
        />
        <Divider width="100%" height="2px" />
        <MDBox
          lineHeight={1.25}
          display="flex"
          flexDirection="column"
          width="100%"
          style={{ height: "30rem", overflow: "auto" }}
          py={1}
          px={2}
        >
          {todoList.map((item, index) => (
            <RowItems
              key={`${item.title}${item.content}`}
              index={index}
              title={item.title}
              todo={item}
              onActiveTodo={(id, isLoop) => handleClickTodoActived(id, isLoop)}
              onDeleteTodo={(id) => handleClickTodoDel(id)}
            />
          ))}
          {/* <RowItems index={1} title="task1" selected />
          <RowItems index={2} title="task2" />
          <RowItems index={3} title="task3" />
          <RowItems index={4} title="task4" />
          <RowItems index={5} title="task5" />
          <RowItems index={6} title="task6" />
          <RowItems index={7} title="task7" />
          <RowItems index={8} title="task8" />
          <RowItems index={9} title="task9" />
          <RowItems index={10} title="task10" />
          <RowItems index={11} title="task11" /> */}
        </MDBox>
      </MDBox>
    </Card>
  );
}

const S = {
  Button: styled(Button)`
    height: ${(props) => (props.height ? `${props.height}px` : "50px")} !important;
    width: ${(props) => (props.width ? `${props.width}px` : "100%")} !important;
    border-radius: 10px !important;
    background-color: ${(props) => (props.dark ? "gray !important" : "#C20B7E !important")};
    text-transform: none !important;
    font-size: 1rem !important;
    font-weight: 100 !important;
    color: white !important;
    margin-bottom: 2px;
    :hover {
      background-color: ${(props) => (props.dark ? "lightgray !important" : "#E560B3 !important")};
      color: white !important;
    }
  `,
};

Tasks.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Tasks;
