import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";

function MyCircularProgress({ mysize, color, variant, value, thickness }) {
  const useStyles = makeStyles(() => ({
    circle: {
      stroke: `url(#linearColors_${mysize})`,
    },
  }));

  const classes = useStyles({});
  console.log(mysize, color, variant, value, thickness, classes);
  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: " 100%",
        background: "transparent",
      }}
    >
      <div
        style={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",

          height: " 100%",
        }}
      >
        <div style={{ position: "absolute" }}>
          <svg>
            <linearGradient id={`linearColors_${mysize}`}>
              <stop offset="0%" stopOpacity="0.1" stopColor={color} />
              <stop offset="100%" stopOpacity="1" stopColor={color} />
            </linearGradient>
          </svg>
        </div>
        <div style={{ position: "absolute", background: "transparent" }}>
          <CircularProgress
            variant="determinate"
            value={100}
            thickness={0.5}
            style={{
              width: `${mysize - 4}px`,
              height: `${mysize - 4}px`,
              strokeLinecap: "round",
              color: "lightgray",
              opacity: 0.5,
            }}
          />
        </div>
        <div style={{ position: "absolute" }}>
          <CircularProgress
            variant="determinate"
            value={value}
            thickness={thickness}
            style={{
              width: `${mysize}px`,
              height: `${mysize}px`,
              strokeLinecap: "round",
              color,
            }}
            classes={{
              circle: classes.circle,
            }}
          />
        </div>

        {/* <CircularProgress
        variant="determinate"
        color="#C20B7E"
        value={50}
        thickness={2}
        size={100}
        style={{ strokeLinecap: "round" }}
        classes={{ circle: classes.circle }}
      /> */}
      </div>
    </div>
  );
}

MyCircularProgress.propTypes = {
  mysize: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  thickness: PropTypes.number.isRequired,
};

export default MyCircularProgress;
