/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
// import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Timeline context
import { useTimeline } from "examples/Timeline/context";

// Custom styles for the TimelineItem
import timelineItem from "examples/Timeline/TimelineItem/styles";

function TimelineItem({ color, staff, service, timeWent, lastItem }) {
  const isDark = useTimeline();

  return (
    <MDBox
      position="relative"
      mb={1}
      sx={(theme) => timelineItem(theme, { lastItem, isDark })}
      height="3rem"
    >
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgColor={color}
        color="white"
        width="1rem"
        height="1rem"
        borderRadius="50%"
        position="absolute"
        top="8%"
        left="2px"
        zIndex={2}
        sx={{ fontSize: ({ typography: { size } }) => size.sm }}
      />

      <MDBox
        ml={5.75}
        lineHeight={0}
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        borderBottom="dashed 1px rgba(0,0,0,0.06)"
      >
        <MDBox display="flex" flex={4}>
          <MDTypography
            variant="button"
            fontWeight="medium"
            fontSize="18px"
            style={{ color: "#8597AC", flex: 1 }}
          >
            {staff}
          </MDTypography>
          <MDTypography
            variant="button"
            fontSize="18px"
            fontWeight="light"
            style={{ color: "#8597AC", flex: 1 }}
          >
            {service}
          </MDTypography>
        </MDBox>

        <MDBox
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          flex={1}
          flexWrap="nowrap"
        >
          <MDTypography variant="caption" color={isDark ? "secondary" : "text"}>
            {timeWent}
          </MDTypography>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of TimelineItem
TimelineItem.defaultProps = {
  lastItem: false,
  staff: "staff",
  service: "service",
  timeWent: "time went",
};

// Typechecking props for the TimelineItem
TimelineItem.propTypes = {
  color: PropTypes.string.isRequired,

  staff: PropTypes.string,
  service: PropTypes.string,
  timeWent: PropTypes.string,

  lastItem: PropTypes.bool,
};

export default TimelineItem;
