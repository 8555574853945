import { useState, useEffect } from "react";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import { FormControlLabel } from "@material-ui/core";
import MDBox from "components/MDBox";
import Moment from "moment/moment";
import CustomCalender from "layouts/bookings/pages/components/CustomCalendars/CustomCalender";
// import Checkbox from "@mui/material/Checkbox";
import config from "configs/config.json";
import { useDispatch } from "react-redux";
import { showAlert } from "actions/alert";
import { showLoading } from "actions/loading";
import IconButton from "layouts/bookings/pages/components/IconButton";
import CustomLabels from "layouts/bookings/pages/components/CustomLabels";
import { callServiceGetAuth, callServicePostAuth } from "services/AuthServices";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import styled from "styled-components";
import Icon from "@mui/material/Icon";
import Select from "react-select";
import DataTable from "components/DataTable";
import logoImg from "assets/images/main-logo-color.png";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import XLSX from "sheetjs-style";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import CreateEditService from "./createeditservice";

const TAG = "SalesReport";

const optionReports = [
  { value: "pdf", label: "PDF" },
  { value: "xls", label: "XLS" },
];

const columns = [
  { Header: "Nr ", accessor: "no", align: "center", fontSize: "1rem", width: 30 },
  { Header: "Pershkrimi", accessor: "desc", align: "center", fontSize: "1rem" },
  { Header: "Data", accessor: "date", align: "center", fontSize: "1rem" },
  { Header: "Shuma (€)", accessor: "price", align: "center", fontSize: "1rem" },
  { Header: "Përdoruesi", accessor: "user", align: "center", fontSize: "1rem" },
  {
    Header: "Edito",
    accessor: "edit",
    align: "center",
    fontSize: "1rem",
    width: 30,
    padding: 0,
    px: 1,
  },
  {
    Header: "Fshij",
    accessor: "delete",
    align: "center",
    fontSize: 20,
    width: 30,
    padding: 0,
    px: 1,
  },
];

function AdminExpences() {
  const [dataRows, setDataRows] = useState([]);
  const [beginDate, setBeginDate] = useState(Moment().startOf("month").format("DD.MM.YYYY"));
  const [endDate, setEndDate] = useState(Moment().format("DD.MM.YYYY"));
  const [showBeginCalendar, setShowBeginCalendar] = useState(false);
  const [showEndCalendar, setShowEndCalendar] = useState(false);
  const [tableFooter, setTableFooter] = useState([]);
  const dispatch = useDispatch();
  const [reportFileType, setReportFileType] = useState("pdf");

  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedExpence, setSelectedExpence] = useState(null);
  const [deleteDlgOpen, setDeleteDlgOpen] = useState(false);
  const [selectedId2Delete, setSelectedId2Delete] = useState();

  let callApiSearchExpences = async () => {};

  useEffect(() => {
    console.log(TAG);
  });

  const renderEditBtn = ({ item, onClicked }) => (
    <div
      onClick={() => onClicked(item)}
      onKeyDown={() => onClicked(item)}
      role="button"
      tabIndex="0"
      style={{
        width: 20,
        height: 20,
        cursor: "pointer",
      }}
    >
      <Icon fontSize="medium">edit_note</Icon>
    </div>
  );

  const renderDeleteBtn = ({ id, onClicked }) => (
    <div
      onClick={() => onClicked(id)}
      onKeyDown={() => onClicked(id)}
      role="button"
      tabIndex="0"
      style={{
        borderRadius: 5,
        width: 20,
        height: 20,
        cursor: "pointer",
      }}
    >
      <Icon fontSize="medium">delete_outline_icon</Icon>
    </div>
  );

  const callbackEditBtn = (item) => {
    console.log(TAG, "callbackEditBtn id = ", item);
    // const service = initialServices.filter((item) => item.id === id);

    setSelectedExpence(item);
    // console.log("edit", id, service[0]);
    // setSelectedExpence(service[0]);
    setOpenEditModal(true);
  };

  const handleDeleteServiceResponse = async (res, isErr) => {
    dispatch(showLoading(false));
    if (isErr) {
      dispatch(showAlert({ type: "error", msg: res }));
      return;
    }

    if (res.result === "failed") {
      dispatch(showAlert({ type: "error", msg: res }));
      return;
    }

    callApiSearchExpences();
    setDeleteDlgOpen(false);
  };

  const handleCloseDlg = () => {
    setDeleteDlgOpen(false);
  };

  const callApiDeleteService = async () => {
    const url = config.BASE_URL + config.DEL_EXPENCES;

    const postData = {
      id: selectedId2Delete,
    };

    setSelectedId2Delete();
    dispatch(showLoading(true));
    callServicePostAuth(url, postData, handleDeleteServiceResponse);
  };

  const callbackDeleteBtn = (id) => {
    // callApiDeleteService(id);
    setDeleteDlgOpen(true);
    setSelectedId2Delete(id);
  };

  const handleCloseEditModal = () => {
    // callAPIGetAllServices();
    setOpenEditModal(false);
    callApiSearchExpences();
  };

  const handleClickDeleteBtn = () => {
    callApiDeleteService();
  };

  const handleSearchExpencesResult = async (res, isErr) => {
    if (isErr) {
      dispatch(showAlert({ type: "error", msg: res }));
      return;
    }

    console.log(res);

    setTableFooter([]);
    setDataRows([]);
    let totalPrice = 0;
    if (res.data && res.data.length > 0) {
      const rows = res.data.map((item, index) => {
        const row = {};
        row.no = index + 1;
        row.id = item.fields.pk;
        row.desc = item.fields.desc;
        row.price = item.fields.price;
        row.date = Moment(item.fields.date).format("DD.MM.YYYY");
        row.user = item.fields.createdby.username;

        row.edit = renderEditBtn({ item, onClicked: callbackEditBtn });
        row.delete = renderDeleteBtn({ id: item.pk, onClicked: callbackDeleteBtn });

        totalPrice += item.fields.price;

        return row;
      });

      setDataRows(rows);
      if (rows.length > 0) {
        setTableFooter(["", "", "", totalPrice, "", "", ""]);
      }
    }
  };

  callApiSearchExpences = async () => {
    const begin = Moment(beginDate, "DD.MM.YYYY").format("YYYY-MM-DD"); // begin of period
    const end = Moment(endDate, "DD.MM.YYYY").format("YYYY-MM-DD"); // end of period

    const url = `${config.BASE_URL}${config.SEARCH_EXPENCES}?start=${begin}&end=${end}`;

    callServiceGetAuth(url, handleSearchExpencesResult);
  };

  const handleClickSearch = () => {
    callApiSearchExpences();
  };

  const handleClickedPrint = () => {
    if (reportFileType === "pdf") {
      const customerHeader = [
        {
          no: "Nr",
          desc: "Pershkrimi",
          date: "Data",
          price: "Shuma €",
          user: "Përdoruesi",
        },
      ];

      const doc = new jsPDF("l", "mm", "a4");

      const img = new Image();
      img.src = logoImg;
      doc.addImage(img, "png", 120, 10, 65, 20);

      doc.setFontSize(14);
      doc.setTextColor("#C20B7E");
      // doc.setFontStyle("arial");

      doc.text(`Raporti sipas datave: ${beginDate}~${endDate}`, 10, 35);
      const dim = doc.getTextDimensions(`Raporti sipas datave: ${beginDate}~${endDate}`);
      doc.setTextColor("black");

      doc.setFontSize(12);

      const tmpRows = [
        ...dataRows.map((item) => ({
          no: item.no,
          desc: item.desc,
          date: item.date,
          price: item.price,
          user: item.user,
        })),
        {
          no: "",
          desc: "Totali",
          date: tableFooter[1],
          price: tableFooter[2],
          user: tableFooter[3],
        },
      ];

      autoTable(doc, {
        head: customerHeader,
        body: tmpRows,
        startY: dim.h + 35,
        headStyles: {
          backgroundColor: "#C20B7E",
          fillColor: "#C20B7E",
        },
        margin: {
          bottom: 20,
        },
        /* apply styling to table body */
        bodyStyles: {
          valign: "top",
        },
        /* apply global styling */
        styles: {
          cellWidth: "wrap",
          rowPageBreak: "auto",
          halign: "justify",
        },
        /* apply styling specific to table columns */
        columnStyles: {
          text: {
            cellWidth: "auto",
          },
        },
      });

      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i += 1) {
        // Go to page i
        doc.setPage(i);
        // Print Page 1 of 4 for example
        doc.setFontSize(7);
        doc.text(
          ` Të gjitha të drejtat e këtij dokumenti janë të rezervuara nga Passion. Nuk lejohet kopjimi, duplikimi, citimi i tij në asnjë rast pa lejen me shkrim.`,
          doc.internal.pageSize.width / 2,
          198,
          {
            align: "center",
          }
        );

        const dim2 = doc.getTextDimensions("Staff details.");

        doc.text(`www.bepassion.com`, doc.internal.pageSize.width / 2, 198 + dim2.h, {
          align: "center",
        });
      }

      doc.save(`expences raport - ${Moment(new Date()).format("DD.MM.YYYY")}.pdf`);
    } else if (reportFileType === "xls") {
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet([]);
      XLSX.utils.book_append_sheet(wb, ws, "sheet1");

      XLSX.utils.sheet_add_json(ws, [
        { title: `Raporti sipas datave: ${beginDate}~${endDate}` },
        "",
      ]);
      XLSX.utils.sheet_add_json(ws, [], { origin: -1 });
      XLSX.utils.sheet_add_json(ws, [], { origin: -1 });

      const clientDetail = dataRows.map((item) => ({
        No: item.no,
        Pëshkrimi: item.desc,
        Data: item.date,
        Shuma: item.price,
        Përdoruesi: item.user,
      }));
      clientDetail.push({
        No: tableFooter[0],
        Pëshkrimi: "Totali",
        Data: tableFooter[2],
        Shuma: tableFooter[3],
        Përdoruesi: tableFooter[4],
      });
      XLSX.utils.sheet_add_json(ws, clientDetail, { origin: -1 });
      XLSX.writeFile(wb, `expences raport -  - ${Moment(new Date()).format("DD.MM.YYYY")}.xls`);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Dialog
        open={deleteDlgOpen}
        onClose={handleCloseDlg}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Konfirmo fshirjen</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Jeni i sigurt që dëshironi ta fshini këtë? Ky veprim nuk mund të zhbëhet.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDlg}>Anulo</Button>
          <S.Button
            variant="contained"
            color="success"
            onClick={handleClickDeleteBtn}
            autoFocus
            width={100}
          >
            Fshije
          </S.Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={openEditModal}
        onClose={handleCloseEditModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CreateEditService selectedItem={selectedExpence} onClose={handleCloseEditModal} />
      </Modal>
      <MDBox
        pt={1}
        pb={1}
        px={1}
        style={{
          minHeight: "80vh",
          height: "calc(100% - 80px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          overflow: "auto",
        }}
      >
        <S.Card>
          <S.HeaderContainer>
            <img src={logoImg} alt="logo" />
            <div
              style={{
                width: "100%",
                color: "#C20B7E",
                marginTop: 20,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span style={{ color: "#C20B7E", marginTop: 20 }}>Dalje</span>
              <div>
                <S.Button
                  onClick={() => {
                    setSelectedExpence(null);
                    setOpenEditModal(true);
                  }}
                  width={100}
                  height={30}
                >
                  Krijo
                </S.Button>
              </div>
            </div>
          </S.HeaderContainer>
          <S.ServicesContainer>
            <S.GradientBg />
            <S.SearchFilters>
              <S.SearchLeftPan>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12} lg={6}>
                    <CustomLabels title="Kërko sipas datave:" fontSize={16} fontWeight={600} />
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={12} lg={6}>
                        <div style={{ position: "relative", width: "100%" }}>
                          <IconButton
                            onClick={() => {
                              setShowBeginCalendar(!showBeginCalendar);
                              setShowEndCalendar(false);
                            }}
                            title={beginDate}
                            icon="calendar_month_icon"
                            imageSize="30px"
                            fontSize={16}
                            iconColor="#C20B7E"
                            textColor="gray"
                            contentPadding={10}
                            disableBorder
                            borderRadius={10}
                          />
                          {showBeginCalendar && (
                            <div style={{ width: "100%", position: "absolute", zIndex: 10 }}>
                              <CustomCalender
                                onChange={(value) => {
                                  setBeginDate(Moment(value).format("DD.MM.YYYY"));
                                  setShowBeginCalendar(false);
                                }}
                                useWeekdaysShort
                              />
                            </div>
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={12} md={12} lg={6}>
                        <div style={{ position: "relative", width: "100%" }}>
                          <IconButton
                            onClick={() => {
                              setShowEndCalendar(!showEndCalendar);
                              setShowBeginCalendar(false);
                            }}
                            title={endDate}
                            icon="calendar_month_icon"
                            imageSize="30px"
                            fontSize={16}
                            iconColor="#C20B7E"
                            textColor="gray"
                            contentPadding={10}
                            disableBorder
                            borderRadius={10}
                          />
                          {showEndCalendar && (
                            <div style={{ width: "100%", position: "absolute", zIndex: 10 }}>
                              <CustomCalender
                                onChange={(value) => {
                                  setEndDate(Moment(value).format("DD.MM.YYYY"));
                                  setShowEndCalendar(false);
                                }}
                                useWeekdaysShort
                                minDate={
                                  beginDate ? new Date(Moment(beginDate, "DD.MM.YYYY")) : new Date()
                                }
                              />
                            </div>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12} lg={6} alignItems="bottom" justifyContent="end">
                    <Grid container spacing={1} height="100%" marginTop={0}>
                      <Grid item xs={12} md={12} lg={4} alignItems="bottom">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-end",
                            height: "100%",
                            width: "100%",
                          }}
                        >
                          <S.Button
                            onClick={() => {
                              handleClickSearch();
                            }}
                          >
                            Kërko
                          </S.Button>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </S.SearchLeftPan>
              {/* <S.SearchRightPan>right</S.SearchRightPan> */}
            </S.SearchFilters>
            <S.ServicesContent>
              <S.DataTable
                id="table"
                table={{ rows: dataRows, columns }}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={false}
                summary={tableFooter}
              />
            </S.ServicesContent>
          </S.ServicesContainer>
          <S.BottomContainer>
            <div
              style={{
                width: 350,
                height: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                position: "relative",
              }}
            >
              <S.Select
                className="basic-single"
                classNamePrefix="select"
                isClearable
                isSearchable
                dropdown
                placeholder="Zgjedh..."
                menuPlacement="top"
                value={optionReports.filter((item) => item.value === reportFileType)[0]}
                onChange={(value) => setReportFileType(value ? value.value : null)}
                options={optionReports}
                style={{ backgroundColor: "rgba(0, 0, 0, 0.2)", marginRight: 10 }}
              />
              <div style={{ width: 10 }} />
              <S.Button onClick={handleClickedPrint} width={200}>
                Report
              </S.Button>
            </div>
          </S.BottomContainer>
        </S.Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

const S = {
  Card: styled(Card)`
    width: 95%;
    min-height: 95%;
    overflow: auto;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    @media (max-width: 1024px) {
      width: 90%;
      min-height: 95%;
    }
  `,
  Conainer: styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
  `,
  CloseBtnDiv: styled.div`
    position: absolute;
    top: 0px;
    right: 20px;
  `,

  HeaderContainer: styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
    img {
      width: 330px;
      height: 100px;
      @media (max-width: 768px) {
        width: 200px;
        height: 60px;
      }
    }
  `,
  CustomerInfoArea: styled.div`
    display: grid !important;
    grid-template-columns: repeat(7, 1fr) !important;
    margin-right: 20px !important;
    grid-gap: 10px !important;
    border-bottom: none !important;
    max-height: none !important;
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
    @media (max-width: 1024px) {
      grid-template-columns: repeat(2, 1fr) !important;
    }
  `,
  CustomerInfoCell: styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    align-items: center;
  `,
  CustomerInfoCelData: styled.div`
    height: 40px;
    border-radius: ${(props) => (props.borderRadius ? `${props.borderRadius}px` : "40px")};
    background-color: rgba(0, 0, 0, 0.06);
    width: ${(props) => (props.auto ? "auto" : "100%")};

    color: #c20b7e;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
  `,
  ServicesContainer: styled.div`
    position: relative;
    padding-top: 20px;
    flex: 1;
    min-height: 400px;
    backgroundcolor: transparent;
    overflow: auto;
  `,

  SearchFilters: styled.div`
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
  `,
  ServicesContent: styled.div`
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    overflow: auto;
  `,

  GradientBg: styled.div`
    position: absolute;
    background: white;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0.03) 20%, white 50%);
  `,
  SearchLeftPan: styled.div`
    display: flex;
    flex: 1;
  `,
  SearchRightPan: styled.div`
    display: flex;
    flex: 1;
    @media (max-width: 1024px) {
      display: none;
    }
  `,

  SelectFilter: styled(Select)`
    flex: 1;

    border-radius: 10px;
    font-size: 1rem !important;

    .select__control {
      box-shadow: unset;
      background-color: rgba(0, 0, 0, 0.06);
      border-radius: 10px;
      min-height: 30px !important;
      font-size: 1rem;
      border: none;
      :hover {
        border: none;
      }
      :focuse {
        border: none;
      }
      :actived {
        border: none;
      }
    }
    .select__multi-value {
      border: 1px solid #c20b7e;
    }
    .select__indicator {
      color: #c20b7e !important;
    }
    .select__indicator-separator {
      display: none !important;
    }
  `,

  Select: styled(Select)`
    flex: 1;
    min-height: 50px !important;
    border-radius: 10px;
    font-size: 1rem !important;

    .select__control {
      box-shadow: unset;
      background-color: rgba(0, 0, 0, 0.06);
      border-radius: 10px;
      min-height: 50px !important;
      border: none;
      :hover {
        border: none;
      }
      :focuse {
        border: none;
      }
      :actived {
        border: none;
      }
    }
    .select__multi-value {
      border: 1px solid #c20b7e;
    }
    .select__indicator {
      color: #c20b7e !important;
    }
    .select__indicator-separator {
      display: none !important;
    }
  `,
  Button: styled(Button)`
    height: ${(props) => (props.height ? `${props.height}px` : "50px")} !important;
    width: ${(props) => (props.width ? `${props.width}px` : "100%")} !important;
    border-radius: 10px !important;
    background-color: ${(props) => (props.dark ? "gray !important" : "#C20B7E !important")};
    text-transform: none !important;
    font-size: 1rem !important;
    font-weight: 100 !important;
    color: white !important;
    margin-bottom: 2px;
    :hover {
      background-color: ${(props) => (props.dark ? "lightgray !important" : "#E560B3 !important")};
      color: white !important;
    }
  `,
  BottomContainer: styled.div`
    height: 80px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
  `,
  DataTable: styled(DataTable)`
    .MuiTableContainer-root {
      table {
        min-width: 1200px !important;
      }
    }
    .MuiTable-root {
      thead {
        background-color: #c20b7e !important;
        color: white !important;
      }
      table {
        min-width: 800px !important;
      }
    }
  `,
  FilterCellContainer: styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
  `,
};

export default AdminExpences;
