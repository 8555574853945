import { useState, useEffect } from "react";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useDispatch } from "react-redux";
import { showAlert } from "actions/alert";
import styled from "styled-components";
import config from "configs/config.json";
import { callServiceGetAuth } from "services/AuthServices";
import NewBookingPage from "./pages/NewBookingPage";
import BookingsPage from "./pages/BookingsPage";

const TAG = "bookings";

function BookingPage() {
  const dispatch = useDispatch();

  const [selectedPage, setSelectedPage] = useState(true);
  const [allServices, setAllServices] = useState([]);
  const [allStaffs, setAllStaffs] = useState([]);
  const [editData, setEditData] = useState();

  const handleClickToggles = (state) => {
    if (selectedPage === state) return;
    setSelectedPage(state);
  };

  const handleGetAllServicesResponse = async (res, isError) => {
    if (isError) {
      dispatch(showAlert({ type: "error", msg: res }));
      return;
    }
    setAllServices(res);
  };

  const callAPIGetAllServices = () => {
    const url = config.BASE_URL + config.GET_ALL_SERVICE;
    console.log(TAG, "url = ", url);
    callServiceGetAuth(url, handleGetAllServicesResponse);
  };

  const handleGetAllStaffResponse = async (res, isError) => {
    if (isError) {
      dispatch(showAlert({ type: "error", msg: res }));
      return;
    }
    setAllStaffs(res);
  };

  const callAPIGetAllStaffs = () => {
    const url = config.BASE_URL + config.GET_ALL_STAFFS;
    console.log(TAG, "callAPIGetAllStaffs url = ", url);
    callServiceGetAuth(url, handleGetAllStaffResponse);
  };

  const doEditBooking = (data) => {
    // console.log("editBooking = ", data);
    setEditData(data);
    setSelectedPage(true);
    console.log("editData = ", editData);
  };

  useEffect(() => {
    callAPIGetAllServices();
    callAPIGetAllStaffs();
  }, []);

  return (
    <DashboardLayout style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <DashboardNavbar />
      <MDBox
        py={3}
        ml={3}
        mr={3}
        mt={3}
        style={{ display: "flex", flexDirection: "column", height: "100%", minWidth: 300 }}
      >
        <Grid container spacing={3} mb={1}>
          <Grid item xs={12} md={6} lg={6}>
            <S.ToogleButton active={selectedPage} onClick={() => handleClickToggles(true)}>
              KRIJO REZERVIM
            </S.ToogleButton>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <S.ToogleButton active={!selectedPage} onClick={() => handleClickToggles(false)}>
              KONTROLLO REZERVIMET
            </S.ToogleButton>
          </Grid>
        </Grid>
        <Card>
          {selectedPage ? (
            <NewBookingPage
              allServices={allServices}
              allStaffs={allStaffs}
              alertDispatch={dispatch}
              showAlert={showAlert}
              editBookingData={editData}
            />
          ) : (
            <BookingsPage
              allStaffs={allStaffs}
              alertDispatch={dispatch}
              showAlert={showAlert}
              allServices={allServices}
              editBooking={doEditBooking}
            />
          )}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

const S = {
  ToogleButton: styled.div`
    background-color: ${(props) => (props.active ? "gray" : "#C20B7E")};
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border-radius: 10px;
    cursor: pointer;
    font-family: ;
  `,
};
export default BookingPage;
