import { useState } from "react";

import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { showAlert } from "actions/alert";
import { showLoading } from "actions/loading";
import { callServicePostAuth } from "services/AuthServices";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CustomLabels from "layouts/bookings/pages/components/CustomLabels";
import config from "configs/config.json";
import TimeSelecter from "../component/TimeSelecter";

// import Moment from "moment/moment";

const TAG = "CreateEditStudio";

const optionsStatus = [
  { value: 0, label: "Mbyllur" },
  { value: 1, label: "Hapur" },
  { value: 2, label: "Mirëmbajtja" },
];

function CreateEditStudio({ selectedItem, studios, onClose }) {
  console.log(TAG, studios, selectedItem);

  const [name, setName] = useState(selectedItem ? selectedItem.fields.name : "");
  const [address, setAddress] = useState(selectedItem ? selectedItem.fields.address : "");
  const [openingTime, setOpeningTime] = useState(
    selectedItem ? selectedItem.fields.openingtime : "08:00"
  );
  const [closingTime, setClosingTime] = useState(
    selectedItem ? selectedItem.fields.closingtime : "22:00"
  );
  // const [studio, setStudio] = useState("");
  const [status, setStatus] = useState(selectedItem ? selectedItem.fields.status : 0);

  const dispatch = useDispatch();

  const handleAddNewStudioResponse = async (res, isErr) => {
    dispatch(showLoading(false));
    if (isErr) {
      dispatch(showAlert({ type: "error", msg: res }));
      return;
    }

    onClose();
  };

  const callApiAddStudio = async () => {
    const url = config.BASE_URL + config.ADD_STUDIO;

    const postData = {
      name,
      address,
      openingTime,
      closingTime,
      status,
    };
    // console.log(closingTime, openingTime);
    console.log(TAG, "callApiAddStudio url", url);
    console.log(TAG, "callApiAddStudio data = ", JSON.stringify(postData));

    callServicePostAuth(url, postData, handleAddNewStudioResponse);
  };

  const callApiEditStudio = () => {
    const url = config.BASE_URL + config.EDIT_STUDIO;

    const postData = {
      id: selectedItem.pk,
      name,
      address,
      openingTime,
      closingTime,
      status,
    };

    console.log(closingTime, openingTime);

    console.log(TAG, "callApiEditStudio url", url);
    console.log(TAG, "callApiEditStudio data = ", JSON.stringify(postData));

    callServicePostAuth(url, postData, handleAddNewStudioResponse);
  };

  const handleClickedRegister = () => {
    if (!selectedItem) {
      callApiAddStudio();
    } else {
      callApiEditStudio();
    }
  };

  return (
    <S.Card>
      <S.Header>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          Studio
        </div>

        <S.CloseBtnContainer>
          <Button
            aria-label="delete"
            size="large"
            onClick={onClose}
            width={20}
            height={20}
            style={{ width: "20px", height: "20px", borderRadius: "20px", padding: 0, margin: 0 }}
          >
            <CloseIcon fontSize="large" color="white" />
          </Button>
        </S.CloseBtnContainer>
      </S.Header>
      <S.Container>
        <Grid container spacing={3} height="100%">
          <Grid item xs={12} md={12} lg={12}>
            <CustomLabels title="Emri :" fontSize={16} fontWeight={600} />
            <S.NoLabelTextField
              variant="filled"
              label={null}
              value={name}
              onChange={(val) => {
                setName(val.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <CustomLabels title="Adresa:" fontSize={16} fontWeight={600} />
            <S.NoLabelTextField
              variant="filled"
              label={null}
              value={address}
              onChange={(val) => {
                setAddress(val.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <CustomLabels title="KOHA E HAPJES:" fontSize={16} fontWeight={600} />

            {/* <S.NoLabelTextField
              variant="filled"
              label={null}
              value={openingTime}
              onChange={(val) => {
                setOpeningTime(val.target.value);
              }}
            /> */}
            <TimeSelecter initTime={openingTime} onChanged={(time) => setOpeningTime(time)} />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <CustomLabels title="KOHA E MBYLLJES:" fontSize={16} fontWeight={600} />
            {/* <S.NoLabelTextField
              variant="filled"
              label={null}
              value={closingTime}
              onChange={(val) => {
                setClosingTime(val.target.value);
              }}
            /> */}
            <TimeSelecter initTime={closingTime} onChanged={(time) => setClosingTime(time)} />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <CustomLabels title="Statusi :" fontSize={16} fontWeight={600} />
            <S.Select
              className="basic-single"
              classNamePrefix="select"
              isClearable
              isSearchable
              dropdown
              placeholder="Zgjedh..."
              menuPlacement="top"
              value={optionsStatus.filter((item) => item.value === status)[0]}
              onChange={(value) => setStatus(value ? value.value : 0)}
              options={optionsStatus}
              style={{ backgroundColor: "rgba(0, 0, 0, 0.2)", marginRight: 10 }}
            />
          </Grid>
        </Grid>
      </S.Container>
      <S.Bottom>
        <S.Button onClick={() => handleClickedRegister()} width={200}>
          {selectedItem ? "Ndrysho" : "Krijo"}
        </S.Button>
      </S.Bottom>
    </S.Card>
  );
}

const S = {
  Card: styled(Card)`
    width: 60%;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 90%;
    @media (max-width: 1024px) {
      width: 90%;
      height: 90%;
    }
  `,
  Header: styled.div`
    height: 50px;
    color: white;
    background-color: #c20b7e;
    font-size: 1.5rem;
    font-weight: 400;
    width: 100%;
    position: relative;

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  `,
  CloseBtnContainer: styled.div`
    position: absolute;
    right: 10px;
    top: 2px;
    color: white;
  `,
  Container: styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    overflow: auto;
    @media (max-width: 1024px) {
      padding: 40px 20px;
    }
    @media (max-width: 768px) {
      padding: 20px;
    }
  `,
  Bottom: styled.div`
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 10px;
  `,
  Button: styled(Button)`
    height: ${(props) => (props.height ? `${props.height}px` : "50px")} !important;
    width: ${(props) => (props.width ? `${props.width}px` : "100%")} !important;
    border-radius: 10px !important;
    background-color: ${(props) => (props.dark ? "gray !important" : "#C20B7E !important")};
    text-transform: none !important;
    font-size: 1rem !important;
    font-weight: 100 !important;
    color: white !important;
    margin-bottom: 2px;
    :hover {
      background-color: ${(props) => (props.dark ? "lightgray !important" : "#E560B3 !important")};
      color: white !important;
    }
  `,
  NoLabelTextField: styled(TextField)`
    width: 100%;
    .MuiFilledInput-root {
      padding: 5px !important;
    }
    input {
      padding: 0 !important;
      height: 40px;
    }
  `,

  Select: styled(Select)`
    flex: 1;

    border-radius: 0px;
    font-size: 1rem !important;

    .select__control {
      box-shadow: unset;
      background-color: rgba(0, 0, 0, 0.06);
      border-radius: 0px;
      min-height: 50px !important;
      font-size: 1rem;
      border: none;
      border-bottom: solid 1px #000000de;
      :hover {
        border: none;
        border-bottom: solid 1px #000000de;
      }
      :focuse {
        border: none;
      }
      :actived {
        border: none;
      }
    }
    .select__multi-value {
      border: 1px solid #c20b7e;
    }
    .select__indicator {
      color: #c20b7e !important;
    }
    .select__indicator-separator {
      display: none !important;
    }
  `,
};

CreateEditStudio.propTypes = {
  selectedItem: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
  studios: PropTypes.instanceOf(Array).isRequired,
};
export default CreateEditStudio;
