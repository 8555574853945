import { useState, useEffect } from "react";
import styled from "styled-components";
import Moment from "moment/moment";

function LiveClock() {
  const [curTime, setCurTime] = useState("");
  useEffect(() => {
    const interval = setInterval(() => {
      setCurTime(Moment(new Date()).format("YYYY-MM-DD | HH:mm:ss"));
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  return <S.Container>{curTime}</S.Container>;
}

const S = {
  Container: styled.div`
    height: 100%;
    width: 100%;
    border-radius: 5px;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.06);
    color: #C20B7E;
    font-size: 14px;
    justify-content: center;
    display: flex;
    align-items: center;
    font-weight: 400;
    word-wrap: break-word;
  `,
};

export default LiveClock;
