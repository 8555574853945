import PropTypes from "prop-types";

import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";

function RowItems({ index, title, todo, onActiveTodo, onDeleteTodo }) {
  console.log(todo, index, title, onActiveTodo, onDeleteTodo);
  // return <div>hello</div>;

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        marginBottom: 10,
        cursor: "pointer",
      }}
    >
      <div
        style={{
          borderWidth: 2,
          borderStyle: "solid",
          borderColor: "#EFF1F3",
          width: 50,
          minHeight: 50,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {index + 1}
      </div>
      <div
        style={{
          flex: 1,
          borderBottomColor: "#EFF1F3",
          borderBottomWidth: 2,
          padding: 5,
          borderBottomStyle: "solid",
          marginLeft: 5,
          minHeight: 50,
          alignItems: "center",
        }}
      >
        <div style={{ width: "100%", fontSize: 20 }}>{title}</div>
        <div style={{ width: "100%", fontSize: 14 }}>{todo.content}</div>
        <div style={{ width: "100%", fontSize: 12, color: "darkgray" }}>
          planDate : {todo.lblPlanDate}
        </div>
        <div style={{ width: "100%", fontSize: 12, color: "darkgray" }}>Time : {todo.planTime}</div>
        {/* <div
          style={{
            width: 20,
            height: 20,
            borderRadius: 20,
            borderColor: "#DEE3EA",
            borderWidth: 1,
            borderStyle: "solid",
            backgroundColor: selected ? "#C20B7E" : "white",
          }}
        >
          {selected}
        </div> */}
      </div>

      {todo && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton
            color="secondary"
            aria-label="add an alarm"
            style={{
              backgroundColor: todo.status === 0 ? "white" : "#e91e63",
              border: "solid 1px #e91e63",
            }}
            onClick={() => onActiveTodo(todo.id, todo.looptype > 0)}
          >
            <CheckIcon
              color="secondary"
              style={{ color: todo.status === 0 ? "#e91e63" : "white" }}
            />
          </IconButton>
          <IconButton aria-label="delete" onClick={() => onDeleteTodo(todo.id)}>
            <DeleteIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
}

// RowItems.defaultProps = {
//   selected: false,
// };
RowItems.propTypes = {
  index: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  // selected: PropTypes.bool,
  todo: PropTypes.objectOf(PropTypes.object).isRequired,
  onActiveTodo: PropTypes.func.isRequired,
  onDeleteTodo: PropTypes.func.isRequired,
};

export default RowItems;
