import { useState, useEffect, useMemo } from "react";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import config from "configs/config.json";
import { useDispatch } from "react-redux";
import { showAlert } from "actions/alert";
import { showLoading } from "actions/loading";
import { callServiceGetAuth } from "services/AuthServices";
import Card from "@mui/material/Card";
// import Tooltip from "@mui/material/Tooltip";
// import Grid from "@mui/material/Grid";
// import Modal from "@mui/material/Modal";
import IconButton from "layouts/bookings/pages/components/IconButton";
import Button from "@mui/material/Button";
import styled from "styled-components";
import CustomCalender from "layouts/bookings/pages/components/CustomCalendars/CustomCalender";
import CustomLabels from "layouts/bookings/pages/components/CustomLabels";
import Moment from "moment/moment";
import Select from "react-select";
import DataTable from "components/DataTable";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import XLSX from "sheetjs-style";
import logoImg from "assets/images/main-logo-color.png";
import CustomTooltip from "./customtooltip";

const TAG = "SalesReport";

const optionReports = [
  { value: "pdf", label: "PDF" },
  { value: "xls", label: "XLS" },
];

const columns = [
  { Header: "Data", accessor: "date", align: "center", fontSize: 20 },
  { Header: "Të hyrat", accessor: "income", align: "center", fontSize: 20 },
  { Header: "Shpenzime", accessor: "expences", align: "center", fontSize: 20 },
  { Header: "Totali i mbetur", accessor: "real_income", align: "center", fontSize: 20 },
  { Header: "Studio", accessor: "studio", align: "center", fontSize: 20 },
];

function SalesReport() {
  const [beginDate, setBeginDate] = useState(Moment().startOf("month").format("DD.MM.YYYY"));
  const [endDate, setEndDate] = useState(Moment().format("DD.MM.YYYY"));
  const [showBeginCalendar, setShowBeginCalendar] = useState(false);
  const [showEndCalendar, setShowEndCalendar] = useState(false);
  const [selectedStudio, setSelectedStudio] = useState(0);
  const [dataRows, setDataRows] = useState([]);
  const [tableFooter, setTableFooter] = useState([]);
  const [initialStudios, setInitialStudios] = useState();
  const [applyFilters, setApplyFilters] = useState(true);
  // const [openExpenceDescModal, setOpenExpenceDescModal] = useState(false);
  const dispatch = useDispatch();
  const [reportFileType, setReportFileType] = useState("pdf");

  const convertData2Option = (data) =>
    data.map((item) => ({
      value: item.fields.name,
      label: item.fields.name,
      id: item.pk,
    }));

  const handleGetAllIndivitualResponse = async (res, isErr) => {
    dispatch(showLoading(false));
    if (isErr) {
      dispatch(showAlert({ type: "error", msg: res }));
      return;
    }

    if (res.result === "success" && res.data) {
      // setIndividualData(res.data);
      // console.log(TAG, "response = ", res.data);
      // setInitialService(convertData2Option(res.data.services));
      // setInitialStudios(convertData2Option(res.data.staffs));
      setInitialStudios(convertData2Option(res.data.studios));
    }
  };

  const callAPIGetAllIndividuals = async () => {
    const url = config.BASE_URL + config.GET_ALL_INDIVITIAL_INFOS;
    console.log(TAG, "callAPIGetAllIndividuals url = ", url);

    dispatch(showLoading(true));

    callServiceGetAuth(url, handleGetAllIndivitualResponse);
  };

  useEffect(() => {
    if (!initialStudios) callAPIGetAllIndividuals();

    console.log(selectedStudio);

    // const row = {};
    // row.date = "1.2.2020";
    // row.income = "200";
    // row.expences = "10";
    // row.real_income = "190";
    // row.studio = "s1";
    // setDataRows([row, row, row, row, row, row, row, row, row, row, row, row, row]);
    setDataRows([]);
    setTableFooter([]);
  }, []);

  useEffect(() => {
    // if (bookings && bookings.length > 0 && applyFilters) getData4Table();
  }, [applyFilters]);

  const handleClickedPrint = () => {
    if (reportFileType === "pdf") {
      const customerHeader = [
        {
          date: "DATA",
          income: "TË HYRAT",
          expences: "SHPENZIME",
          real_income: "TOTALI I MBETUR",
          studio: "STUDIO",
        },
      ];

      const doc = new jsPDF("l", "mm", "a4");
      const img = new Image();
      img.src = logoImg;
      doc.addImage(img, "png", 120, 10, 65, 20);

      doc.setFontSize(14);
      doc.setTextColor("#C20B7E");
      // doc.setFontStyle("arial");

      doc.text(`Raporti sipas datave: ${beginDate}~${endDate}`, 10, 35);
      const dim = doc.getTextDimensions(`Raporti sipas datave: ${beginDate}~${endDate}`);
      doc.setTextColor("black");
      doc.setFontSize(12);

      autoTable(doc, {
        head: customerHeader,
        body: dataRows,
        startY: dim.h + 35,
        margin: {
          bottom: 20,
        },
        headStyles: {
          backgroundColor: "#C20B7E",
          fillColor: "#C20B7E",
        },
        /* apply styling to table body */
        bodyStyles: {
          valign: "top",
        },
        /* apply global styling */
        styles: {
          cellWidth: "wrap",
          rowPageBreak: "auto",
          halign: "justify",
        },
        /* apply styling specific to table columns */
        columnStyles: {
          text: {
            cellWidth: "auto",
          },
        },
      });

      const pageCount = doc.internal.getNumberOfPages();
      console.log("page count = ", pageCount);
      for (let i = 1; i <= pageCount; i += 1) {
        // Go to page i
        doc.setPage(i);
        // Print Page 1 of 4 for example
        doc.setFontSize(7);
        doc.text(
          ` Të gjitha të drejtat e këtij dokumenti janë të rezervuara nga Passion. Nuk lejohet kopjimi, duplikimi, citimi i tij në asnjë rast pa lejen me shkrim.`,
          doc.internal.pageSize.width / 2,
          198,
          {
            align: "center",
          }
        );

        const dim2 = doc.getTextDimensions("Staff details.");

        doc.text(`www.bepassion.com`, doc.internal.pageSize.width / 2, 198 + dim2.h, {
          align: "center",
        });
      }

      doc.save(`sales raport - ${Moment(new Date()).format("DD.MM.YYYY")}.pdf`);
    } else if (reportFileType === "xls") {
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet([]);
      XLSX.utils.book_append_sheet(wb, ws, "sheet1");

      XLSX.utils.sheet_add_json(ws, [
        { title: `Raporti sipas datave: ${beginDate}~${endDate}` },
        "",
      ]);
      XLSX.utils.sheet_add_json(ws, [], { origin: -1 });
      XLSX.utils.sheet_add_json(ws, [], { origin: -1 });

      const clientDetail = dataRows.map((item) => ({
        DATA: item.date,
        "TË HYRAT": item.income,
        SHPENZIME: item.expences,
        "TOTALI I MBETUR": item.real_income,
        STUDIO: item.studio,
      }));
      XLSX.utils.sheet_add_json(ws, clientDetail, { origin: -1 });
      XLSX.writeFile(wb, `sales raport -  - ${Moment(new Date()).format("DD.MM.YYYY")}.xls`);
    }
  };

  // const handleClickExpenceCell = (date, studio) => {
  //   console.log(date, studio);
  //   // setOpenExpenceDescModal(true);
  // };

  const handleSalesReportResponse = async (res, isErr) => {
    dispatch(showLoading(false));
    if (isErr) {
      dispatch(showAlert({ type: "error", msg: res }));
      return;
    }

    if (res.result !== "success") {
      dispatch(showAlert({ type: "error", msg: res }));
      return;
    }

    const footer = [0, 0, 0, 0, 0];
    const studios = [];
    const rows = res.data.map((item) => {
      const row = {};
      row.date = Moment(item.date).format("DD.MM.YYYY");
      row.income = item.price__sum;
      row.expences = (
        <CustomTooltip descriptions={["buy something1", "again buy something2"]}>
          <div>{item.expence}</div>
        </CustomTooltip>
        // <div
        //   style={{ cursor: "pointer" }}
        //   role="button"
        //   tabIndex="0"
        //   onClick={() => handleClickExpenceCell(item.date, item.studio)}
        //   onKeyDown={() => handleClickExpenceCell(item.date, item.studio)}
        // >
        //   {item.expence}
        // </div>
      );
      row.real_income = item.res;
      row.studio = item.studio__name;

      footer[1] += row.income;
      footer[2] += item.expence;
      footer[3] += row.real_income;

      if (studios.filter((i) => i === row.studio).length === 0) studios.push(row.studio);
      return row;
    });
    footer[4] = studios.length;
    console.log(footer);
    setDataRows([
      ...rows,
      // ...rows,
      // ...rows,
      // ...rows,
      // ...rows,
      // ...rows,
      // ...rows,
      // ...rows,
      // ...rows,
      // ...rows,
      // ...rows,
    ]);
    if (rows.length > 0) setTableFooter(footer);
    else setTableFooter([]);
    // console.log("data = ", res);
  };

  const callApiGetSalesReport = async () => {
    const begin = Moment(beginDate, "DD.MM.YYYY").format("YYYY-MM-DD"); // begin of period
    const end = Moment(endDate, "DD.MM.YYYY").format("YYYY-MM-DD"); // end of period
    const studios = selectedStudio !== null ? selectedStudio : 0; // all data

    const url = `${config.BASE_URL}${config.SEARCH_SALESREPORT}?start=${begin}&end=${end}&studioId=${studios}`;

    dispatch(showLoading(true));
    callServiceGetAuth(url, handleSalesReportResponse);
  };
  const handleClickSearch = () => {
    setApplyFilters(true);
    callApiGetSalesReport();
  };

  // const handleCloseEditModal = () => {
  //   setOpenExpenceDescModal(false);
  // };

  return (
    <DashboardLayout>
      {/* <Modal
        open={openExpenceDescModal}
        onClose={handleCloseEditModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>hello</div>
      </Modal> */}
      <DashboardNavbar />
      <MDBox
        pt={1}
        pb={1}
        px={1}
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          overflow: "auto",
        }}
      >
        <S.Card>
          <S.HeaderContainer>
            <img src={logoImg} alt="logo" />
            <div style={{ width: "100%", color: "#C20B7E", marginTop: 20 }}>
              Raporti i shitjëve & shpenzimeve
            </div>
          </S.HeaderContainer>
          <S.ServicesContainer>
            <S.GradientBg />
            <S.SearchFilters>
              <S.SearchLeftPan>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12} lg={6}>
                    <CustomLabels title="Kërko sipas datave:" fontSize={16} fontWeight={600} />
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={12} lg={6}>
                        <div style={{ position: "relative", width: "100%" }}>
                          <IconButton
                            onClick={() => {
                              setShowBeginCalendar(!showBeginCalendar);
                              setShowEndCalendar(false);
                            }}
                            title={beginDate}
                            icon="calendar_month_icon"
                            imageSize="30px"
                            fontSize={16}
                            iconColor="#C20B7E"
                            textColor="gray"
                            contentPadding={10}
                            disableBorder
                            borderRadius={10}
                          />
                          {showBeginCalendar && (
                            <div style={{ width: "100%", position: "absolute", zIndex: 10 }}>
                              <CustomCalender
                                onChange={(value) => {
                                  setBeginDate(Moment(value).format("DD.MM.YYYY"));
                                  setShowBeginCalendar(false);
                                }}
                                useWeekdaysShort
                              />
                            </div>
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={12} md={12} lg={6}>
                        <div style={{ position: "relative", width: "100%" }}>
                          <IconButton
                            onClick={() => {
                              setShowEndCalendar(!showEndCalendar);
                              setShowBeginCalendar(false);
                            }}
                            title={endDate}
                            icon="calendar_month_icon"
                            imageSize="30px"
                            fontSize={16}
                            iconColor="#C20B7E"
                            textColor="gray"
                            contentPadding={10}
                            disableBorder
                            borderRadius={10}
                          />
                          {showEndCalendar && (
                            <div style={{ width: "100%", position: "absolute", zIndex: 10 }}>
                              <CustomCalender
                                onChange={(value) => {
                                  setEndDate(Moment(value).format("DD.MM.YYYY"));
                                  setShowEndCalendar(false);
                                }}
                                useWeekdaysShort
                                minDate={
                                  beginDate ? new Date(Moment(beginDate, "DD.MM.YYYY")) : new Date()
                                }
                              />
                            </div>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12} lg={6}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={12} lg={6}>
                        <CustomLabels title="Kërko sipas studio:" fontSize={16} fontWeight={600} />
                        <div style={{ width: "100%" }}>
                          {useMemo(
                            () => (
                              <S.Select
                                className="basic-single"
                                classNamePrefix="select"
                                placeholder="Zgjedh..."
                                isClearable
                                isSearchable
                                onChange={(value) => setSelectedStudio(value ? value.id : null)}
                                options={initialStudios}
                                style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
                              />
                            ),
                            [initialStudios]
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={12} md={12} lg={6} alignItems="bottom">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-end",
                            height: "100%",
                          }}
                        >
                          <S.Button
                            onClick={() => {
                              handleClickSearch();
                            }}
                          >
                            Kërko
                          </S.Button>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </S.SearchLeftPan>
              {/* <S.SearchRightPan>right</S.SearchRightPan> */}
            </S.SearchFilters>
            <S.ServicesContent>
              <S.DataTable
                id="table"
                table={{ rows: dataRows, columns }}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={false}
                noEndBorder
                summary={tableFooter}
              />
            </S.ServicesContent>
          </S.ServicesContainer>
          <S.BottomContainer>
            <div
              style={{
                width: 350,
                height: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                position: "relative",
              }}
            >
              <S.Select
                className="basic-single"
                classNamePrefix="select"
                isClearable
                isSearchable
                dropdown
                placeholder="Zgjedh..."
                menuPlacement="top"
                value={optionReports.filter((item) => item.value === reportFileType)[0]}
                onChange={(value) => setReportFileType(value ? value.value : null)}
                options={optionReports}
                style={{ backgroundColor: "rgba(0, 0, 0, 0.2)", marginRight: 10 }}
              />
              <div style={{ width: 10 }} />
              <S.Button onClick={handleClickedPrint} width={200}>
                Report
              </S.Button>
            </div>
          </S.BottomContainer>
        </S.Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

const S = {
  Card: styled(Card)`
    width: 95%;
    flex: 1;
    max-height: 1024px;
    padding-top: 20px;

    @media (max-width: 1024px) {
      width: 90%;
    }
  `,
  Conainer: styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
  `,
  CloseBtnDiv: styled.div`
    position: absolute;
    top: 0px;
    right: 20px;
  `,

  HeaderContainer: styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
    img {
      width: 330px;
      height: 100px;
      @media (max-width: 768px) {
        width: 200px;
        height: 60px;
      }
    }
  `,
  CustomerInfoArea: styled.div`
    display: grid !important;
    grid-template-columns: repeat(7, 1fr) !important;
    margin-right: 20px !important;
    grid-gap: 10px !important;
    border-bottom: none !important;
    max-height: none !important;
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
    @media (max-width: 1024px) {
      grid-template-columns: repeat(2, 1fr) !important;
    }
  `,
  CustomerInfoCell: styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    align-items: center;
  `,
  CustomerInfoCelData: styled.div`
    height: 40px;
    border-radius: ${(props) => (props.borderRadius ? `${props.borderRadius}px` : "40px")};
    background-color: rgba(0, 0, 0, 0.06);
    width: ${(props) => (props.auto ? "auto" : "100%")};

    color: #c20b7e;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
  `,
  ServicesContainer: styled.div`
    position: relative;
    padding-top: 20px;
    flex: 1;
    min-height: 400px;
    height: 100%;
    backgroundcolor: transparent;
    overflow: auto;
  `,

  SearchFilters: styled.div`
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
  `,
  ServicesContent: styled.div`
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
  `,

  GradientBg: styled.div`
    position: absolute;
    background: white;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0.03) 20%, white 50%);
  `,
  SearchLeftPan: styled.div`
    display: flex;
    flex: 1;
  `,
  SearchRightPan: styled.div`
    display: flex;
    flex: 1;
    @media (max-width: 1024px) {
      display: none;
    }
  `,
  Select: styled(Select)`
    flex: 1;
    min-height: 50px !important;
    border-radius: 10px;

    .select__control {
      box-shadow: unset;
      background-color: rgba(0, 0, 0, 0.06);
      border-radius: 10px;
      min-height: 50px !important;
      font-size: 16px;
      border: none;
      :hover {
        border: none;
      }
      :focuse {
        border: none;
      }
      :actived {
        border: none;
      }
    }
    .select__multi-value {
      border: 1px solid #c20b7e;
      font-size: 16px;
    }
    .select__indicator {
      color: #c20b7e !important;
    }
    .select__indicator-separator {
      display: none !important;
    }
  `,
  Button: styled(Button)`
    height: 50px !important;
    width: ${(props) => (props.width ? `${props.width}px` : "100%")} !important;
    border-radius: 10px !important;
    background-color: ${(props) => (props.dark ? "gray !important" : "#C20B7E !important")};
    text-transform: none !important;
    font-size: 20px !important;
    font-weight: 100 !important;
    color: white !important;
    margin-bottom: 2px;
    :hover {
      background-color: ${(props) => (props.dark ? "lightgray !important" : "#E560B3 !important")};
      color: white !important;
    }
  `,
  BottomContainer: styled.div`
    height: 80px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
  `,
  DataTable: styled(DataTable)`
    .MuiTable-root {
      thead {
        th {
          background-color: green !important;
          color: white !important;
        }
      }
    }
  `,
};

export default SalesReport;
