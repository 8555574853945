/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import styled from "styled-components";
// react-router components
// import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import { useNavigate } from "react-router-dom";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React example components

import NotificationItem from "examples/Items/NotificationItem";

import { useSelector } from "react-redux";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  // setOpenConfigurator,
} from "context";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  // const { , transparentNavbar, fixedNavbar, , darkMode, openConfigurator } = controller;
  const { miniSidenav, transparentNavbar, fixedNavbar, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [searchText, setSearchText] = useState("");
  // const route = useLocation().pathname.split("/").slice(1);

  const { user } = useSelector((state) => state.auth);

  const navigation = useNavigate();

  const findCustomerByName = () => {
    // console.log("param");
    navigation("/klientet", { state: { name: searchText } });
    setSearchText("");
  };

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  // const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const dropDownMenuOpened = Boolean(anchorEl);
  const handleClickDropDownMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseDropDownMenu = () => {
    setAnchorEl(null);
  };

  const handleClickLogout = () => {
    handleCloseDropDownMenu();
    localStorage.removeItem("user");
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    document.location.reload();
  };

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions" />
      <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <S.Container
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
      bgcolor="white !important"
      backgroundcolor="white !important"
      style={{ backgroundColor: "white" }}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox
          pr={1}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid #d2d6da",
            borderRadius: "5px",
            paddingLeft: "10px",
          }}
        >
          <Icon sx={iconsStyle}>search</Icon>
          <S.SearchInput
            label="kërkimi"
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                findCustomerByName();
              }
            }}
          />
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox color={light ? "white" : "inherit"} display="flex" alignItems="center">
              <div>
                <IconButton
                  sx={navbarIconButton}
                  size="small"
                  disableRipple
                  onClick={handleClickDropDownMenu}
                >
                  <Icon sx={iconsStyle}>account_circle</Icon>
                </IconButton>

                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={dropDownMenuOpened}
                  onClose={handleCloseDropDownMenu}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {/* <MenuItem onClick={handleCloseDropDownMenu}>Profile</MenuItem>
                  <MenuItem onClick={handleCloseDropDownMenu}>My account</MenuItem> */}
                  <MenuItem onClick={handleClickLogout}>Shkyç</MenuItem>
                </Menu>
              </div>
              <div style={{ fontSize: 16, marginRight: 20 }}>{user ? user.username : ""}</div>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              {/* <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon sx={iconsStyle}>settings</Icon>
              </IconButton> */}
              {/* <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <Icon sx={iconsStyle}>notifications</Icon>
              </IconButton> */}
              {renderMenu()}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </S.Container>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

const S = {
  Container: styled(AppBar)`
    padding: 0px !important;
    width: 100% !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    background-color: white !important;
    border-radius: 0 !important;
    top: 0 !important;
    color: inherit !important;
    margin: 0 !important;
    .MuiToolbar-root {
      width: 100% !important;
      display: flex !important;
      justify-content: space-between !important;
      background-color: white !important;
      padding: 16px !important;
    }
  `,
  SearchInput: styled(MDInput)`
    .MuiOutlinedInput-notchedOutline {
      border: 0 !important;
    }
  `,
};
export default DashboardNavbar;
