/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
// import Icon from "@mui/material/Icon";
import PropTypes from "prop-types";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Moment from "moment/moment";
// Material Dashboard 2 React example components
import TimelineItem from "examples/Timeline/TimelineItem";

const TAG = "RecentBookings";
function RecentBookings({ bookingList }) {
  console.log(TAG, "bookingList = ", bookingList);

  const getServiceNames = (booking) => {
    let servicenames = "";
    const { service } = booking.fields;
    service.forEach((item) =>
      item.forEach((item2) => {
        servicenames += `${item2.fields.name} | `;
      })
    );
    return servicenames;
  };
  return (
    <Card sx={{ height: "100%" }} style={{ padding: "20px" }}>
      <MDBox pt={3} px={3} mb={3}>
        <MDTypography variant="h5" style={{ fontWeight: 400, fontSize: 24 }}>
          Rezervimet e fundit
        </MDTypography>
        {/* <MDBox mt={0} mb={2}>
          <MDTypography variant="button" color="text" fontWeight="regular">
            <MDTypography display="inline" variant="body2" verticalAlign="middle">
              <Icon sx={{ color: ({ palette: { success } }) => success.main }}>arrow_upward</Icon>
            </MDTypography>
            &nbsp;
            <MDTypography variant="button" color="text" fontWeight="medium">
              24%
            </MDTypography>{" "}
            this month
          </MDTypography>
        </MDBox> */}
      </MDBox>
      <MDBox p={2} style={{ height: "25rem", overflow: "scroll" }}>
        {bookingList.map((item, index) => (
          <TimelineItem
            key={item.pk}
            color={index === 0 ? "primary" : "#8597AC"}
            staff={`${item.fields.staff.name}`}
            service={getServiceNames(item)}
            timeWent={Moment(item.fields.created_at).utc().fromNow()}
            lastItem={bookingList.length === index + 1}
          />
        ))}
        {/* <TimelineItem
          color="primary"
          staff="staff1"
          service="service1"
          timeWent="less than a min"
        />
        <TimelineItem
          color="#8597AC"
          staff="staff1"
          service="service1"
          timeWent="less than a min"
        />
        <TimelineItem
          color="#8597AC"
          staff="staff1"
          service="service1"
          timeWent="less than a min"
        />
        <TimelineItem
          color="#8597AC"
          staff="staff1"
          service="service1"
          timeWent="less than a min"
        />
        <TimelineItem
          color="#8597AC"
          staff="staff1"
          service="service1"
          timeWent="less than a min"
        />
        <TimelineItem
          color="#8597AC"
          staff="staff1"
          service="service1"
          timeWent="less than a min"
        />
        <TimelineItem
          color="#8597AC"
          staff="staff1"
          service="service1"
          timeWent="less than a min"
        />
        <TimelineItem
          color="#8597AC"
          staff="staff1"
          service="service1"
          timeWent="less than a min"
        />
        <TimelineItem
          color="#8597AC"
          staff="staff1"
          service="service1"
          timeWent="less than a min"
        />
        <TimelineItem
          color="#8597AC"
          staff="staff1"
          service="service1"
          timeWent="less than a min"
        />
        <TimelineItem
          color="#8597AC"
          staff="staff1"
          service="service1"
          timeWent="less than a min"
        />
        <TimelineItem
          color="#8597AC"
          staff="staff1"
          service="service1"
          timeWent="less than a min"
        />
        <TimelineItem
          color="#8597AC"
          staff="staff1"
          service="service1"
          timeWent="less than a min"
        />
        <TimelineItem
          color="#8597AC"
          staff="staff1"
          service="service1"
          timeWent="less than a min"
        />
        <TimelineItem
          color="#8597AC"
          staff="staff1"
          service="service1"
          timeWent="less than a min"
          lastItem
        /> */}
      </MDBox>
    </Card>
  );
}

RecentBookings.propTypes = {
  bookingList: PropTypes.instanceOf(Array).isRequired,
};

export default RecentBookings;
