import PropTypes from "prop-types";

function CustomLabels({ title, important, fontSize, fontWeight }) {
  return (
    <div style={{ color: "gray", fontSize, fontWeight, wordWrap: "unset", whiteSpace: " nowrap" }}>
      {important && <span style={{ color: "#C20B7E" }}>*</span>}
      {title}
    </div>
  );
}

CustomLabels.defaultProps = {
  important: false,
  fontSize: 20,
  fontWeight: 400,
};

CustomLabels.propTypes = {
  title: PropTypes.string.isRequired,
  important: PropTypes.bool,
  fontSize: PropTypes.number,
  fontWeight: PropTypes.number,
};
export default CustomLabels;
