import { combineReducers } from "redux";
import auth from "./auth";
import alert from "./alert";
import loading from "./loading";
// import message from "./message";
// import loading from "./loading";

export default combineReducers({
  auth,
  //   message,
  //   loading,
  alert,
  loading,
  //   coupone,
});
