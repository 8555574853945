import { useEffect, useState } from "react";

import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { showAlert } from "actions/alert";
import Icon from "@mui/material/Icon";
import { showLoading } from "actions/loading";
import { callServicePostAuth, callServiceGetAuth } from "services/AuthServices";
import IconButton from "layouts/bookings/pages/components/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import Moment from "moment/moment";
import Button from "@mui/material/Button";
import CustomLabels from "layouts/bookings/pages/components/CustomLabels";
import CustomCalender from "layouts/bookings/pages/components/CustomCalendars/CustomCalender";
import config from "configs/config.json";

const TAG = "CreateEditService";

const convertData2Option = (data) =>
  data.map((item) => ({
    value: item.id,
    label: `${item.name} ${item.surname ? item.surname : ""}`,
  }));

function CreateEditService({ selectedItem, onClose }) {
  const [selectedDate, setSelectedDate] = useState(
    selectedItem
      ? Moment(selectedItem.fields.date).utc().format("DD.MM.YYYY")
      : Moment().format("DD.MM.YYYY")
  );
  const [allStaffs, setAllStaffs] = useState([]);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [client, setClient] = useState(selectedItem ? selectedItem.fields.client.pk : 0);
  const [staff, setStaff] = useState(selectedItem ? selectedItem.fields.staff.pk : 0);
  const [price, setPrice] = useState(selectedItem ? selectedItem.fields.price : 0);
  const [allCustomers, setAllCustomers] = useState([]);

  const dispatch = useDispatch();

  const handleGetAllStaffResponse = async (res, isError) => {
    dispatch(showLoading(false));
    if (isError) {
      dispatch(showAlert({ type: "error", msg: res }));
      return;
    }
    setAllStaffs(convertData2Option(res));
  };

  const callAPIGetAllStaffs = () => {
    const url = config.BASE_URL + config.GET_ALL_STAFFS;
    console.log(TAG, "callAPIGetAllStaffs url = ", url);
    dispatch(showLoading(true));
    callServiceGetAuth(url, handleGetAllStaffResponse);
  };

  useEffect(() => {
    callAPIGetAllStaffs();
  }, []);

  const handleAddIncomeResponse = async (res, isErr) => {
    dispatch(showLoading(false));

    if (isErr) {
      dispatch(showAlert({ type: "error", msg: res }));
      return;
    }
    if (res.result === "success") {
      dispatch(showAlert({ type: "success", msg: res.msg }));
    }
    if (res.result !== "success") {
      dispatch(showAlert({ type: "errpr", msg: res.msg }));
      return;
    }

    onClose();
  };

  const callApiAddIncomes = async () => {
    if (!staff || !client || !price || price.length === 0 || parseFloat(price) <= 0) {
      dispatch(showAlert({ type: "error", msg: "Ju lutemi plotësoni fushat" }));
      return;
    }
    const url = config.BASE_URL + config.ADD_INCOMES;
    const postData = {
      staff,
      client,
      date: Moment(selectedDate, "DD.MM.YYYY").format("YYYY-MM-DD"),
      price: parseFloat(price),
    };
    console.log(TAG, "callApiAddIncoms url = ", url);
    console.log(TAG, "callApiAddIncoms postData = ", JSON.stringify(postData));

    dispatch(showLoading(true));
    callServicePostAuth(url, postData, handleAddIncomeResponse);
  };

  const handleGetClientByStaffResponse = async (res, isErr) => {
    dispatch(showLoading(false));

    if (isErr) {
      dispatch(showAlert({ type: "error", msg: res }));
      return;
    }

    if (res.result !== "success") {
      dispatch(showAlert({ type: "errpr", msg: res.msg }));
      return;
    }

    if (res.data && res.data.length > 0) {
      const bookings = res.data;
      const clients = bookings.map((item) => ({
        id: item.fields.client.pk,
        name: `${item.fields.client.name} ${item.fields.client.surname}`,
      }));

      const uniqClients = [...new Set(clients)];

      const tmpData = convertData2Option(uniqClients);

      console.log(tmpData);
      const uniquList = [];
      tmpData.forEach((item) => {
        if (uniquList.filter((item2) => item2.label === item.label).length === 0)
          uniquList.push(item);
      });

      // tmpData = tmpData.filter(
      //   (item, pos) =>
      //     tmpData.filter((item2, pos2) => item.label === item2.label && pos === pos2).length === 1
      // );
      // console.log(tmpData);

      setAllCustomers(uniquList);
    } else {
      setAllCustomers();
    }
  };

  const callApiGetClientByStaff = async (s) => {
    const url = config.BASE_URL + config.SEARCH_CLIENT_FOR_STAFF_TODAY;

    const postData = {
      staff_id: s,
      date: Moment(selectedDate, "DD.MM.YYYY").format("YYYY-MM-DD"),
    };

    dispatch(showLoading(true));
    callServicePostAuth(url, postData, handleGetClientByStaffResponse);
  };

  const handleEditIncomeResponse = async (res, isErr) => {
    dispatch(showLoading(false));
    if (isErr) {
      dispatch(showAlert({ type: "error", msg: res }));
      return;
    }

    if (res.result === "success") {
      dispatch(showAlert({ type: "success", msg: res.msg }));
    }
    if (res.result !== "success") {
      dispatch(showAlert({ type: "errpr", msg: res.msg }));
      return;
    }

    onClose();
  };
  const callApiEditIncomes = async () => {
    if (!staff || !client || !price || price.length === 0 || parseFloat(price) <= 0) {
      dispatch(showAlert({ type: "error", msg: "Ju lutemi plotësoni fushat" }));
      return;
    }
    const url = config.BASE_URL + config.UPDATE_INCOMES;
    const postData = {
      id: selectedItem.pk,
      staff,
      client,
      date: Moment(selectedDate, "DD.MM.YYYY").format("YYYY-MM-DD"),
      price: parseFloat(price),
    };
    console.log(TAG, "callApiEditIncomes url = ", url);
    console.log(TAG, "callApiEditIncomes postData = ", JSON.stringify(postData));

    dispatch(showLoading(true));
    callServicePostAuth(url, postData, handleEditIncomeResponse);
  };

  const handleClickedAddIncome = () => {
    if (!selectedItem) {
      callApiAddIncomes();
    } else {
      callApiEditIncomes();
    }
  };

  const handleChangedStaff = (s) => {
    if (s) {
      // call clients related to staf today
      callApiGetClientByStaff(s);
    }
    setStaff(s);
  };

  useEffect(() => {
    if (selectedItem) {
      callApiGetClientByStaff(selectedItem.fields.staff.pk);
    }
  }, [selectedItem]);

  return (
    <S.Card>
      <S.Header>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          Hyrjet
        </div>

        <S.CloseBtnContainer>
          <Button
            aria-label="delete"
            size="large"
            onClick={onClose}
            width={20}
            height={20}
            style={{ width: "20px", height: "20px", borderRadius: "20px", padding: 0, margin: 0 }}
          >
            <CloseIcon fontSize="large" color="white" />
          </Button>
        </S.CloseBtnContainer>
      </S.Header>
      <S.Container>
        <Grid container spacing={3} height="100%">
          <Grid item xs={12} md={12} lg={12}>
            <CustomLabels title="Data :" fontSize={16} fontWeight={600} />
            <IconButton
              onClick={() => setOpenCalendar(!openCalendar)}
              title={selectedDate}
              icon="calendar_month_icon"
              imageSize="30px"
              fontSize="24px"
              iconColor="#C20B7E"
              textColor="gray"
            />
            <div style={{ position: "relative", width: "100%" }}>
              {openCalendar && (
                <div style={{ width: "100%", position: "absolute", zIndex: 10 }}>
                  <CustomCalender
                    onChange={(value) => {
                      setSelectedDate(Moment(value).format("DD.MM.YYYY"));
                      setOpenCalendar(false);
                    }}
                    useWeekdaysShort
                  />
                </div>
              )}
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <CustomLabels title="Stafi :" fontSize={16} fontWeight={600} />
            <div
              style={{
                width: "100%",
                display: "flex",
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: "lightgray",
                alignItems: "center",
                borderRadius: 5,
                backgroundColor: "rgba(0,0,0,0.06)",
                paddingLeft: 20,
                paddingRight: 24,
              }}
            >
              <div style={{ color: "#C20B7E", display: "flex", alignItems: "center" }}>
                <Icon fontSize="large">person</Icon>
              </div>
              <S.Select
                className="basic-single"
                classNamePrefix="select"
                isClearable
                isSearchable
                placeholder="Zgjedh..."
                value={allStaffs ? allStaffs.filter((item) => item.value === staff)[0] : 0}
                onChange={(val) => handleChangedStaff(val ? val.value : 0)}
                options={allStaffs}
                style={{ height: 50 }}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <CustomLabels title="Klienti :" fontSize={16} fontWeight={600} />
            <div
              style={{
                width: "100%",
                display: "flex",
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: "lightgray",
                alignItems: "center",
                borderRadius: 5,
                backgroundColor: "rgba(0,0,0,0.06)",
                paddingLeft: 20,
                paddingRight: 24,
              }}
            >
              <div style={{ color: "#C20B7E", display: "flex", alignItems: "center" }}>
                <Icon fontSize="large">group</Icon>
              </div>
              <S.Select
                className="basic-single"
                classNamePrefix="select"
                isClearable
                placeholder="Zgjedh..."
                isSearchable
                value={allCustomers ? allCustomers.filter((item) => item.value === client)[0] : 0}
                onChange={(val) => setClient(val ? val.value : 0)}
                options={allCustomers}
                style={{ height: 50 }}
              />
            </div>
          </Grid>
          {/* <Grid item xs={12} md={12} lg={12}>
            <CustomLabels title="Available Price € :" fontSize={16} fontWeight={600} />
            <S.NoLabelTextField
              id="filled-basic-username"
              variant="filled"
              label={null}
              textAlignment="center"
              value="5 ~ 10"
              onChange={(e) => setPrice(e.target.value)}
              style={{
                width: "100%",
              }}
            />
          </Grid> */}
          <Grid item xs={12} md={12} lg={12}>
            <CustomLabels title="Shuma € :" fontSize={16} fontWeight={600} />
            <S.NoLabelTextField
              id="filled-basic-username"
              variant="filled"
              label={null}
              textAlignment="center"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              style={{
                width: "100%",
              }}
            />
          </Grid>
        </Grid>
      </S.Container>
      <S.Bottom>
        <S.Button onClick={() => handleClickedAddIncome()} width={200}>
          Regjistro
        </S.Button>
      </S.Bottom>
    </S.Card>
  );
}

const S = {
  Card: styled(Card)`
    width: 60%;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    overflow: auto;

    @media (max-width: 1024px) {
      width: 90%;
    }
  `,
  Header: styled.div`
    height: 50px;
    color: white;
    background-color: #C20B7E;
    font-size: 1.5rem;
    font-weight: 400;
    width: 100%;
    position: relative;

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  `,
  CloseBtnContainer: styled.div`
    position: absolute;
    right: 10px;
    top: 2px;
    color: white;
  `,
  Container: styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 40px;

    @media (max-width: 1024px) {
      padding: 40px 20px;
    }
    @media (max-width: 768px) {
      padding: 20px;
    }
  `,
  Bottom: styled.div`
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 10px;
  `,
  Button: styled(Button)`
    height: ${(props) => (props.height ? `${props.height}px` : "50px")} !important;
    width: ${(props) => (props.width ? `${props.width}px` : "100%")} !important;
    border-radius: 10px !important;
    background-color: ${(props) => (props.dark ? "gray !important" : "#C20B7E !important")};
    text-transform: none !important;
    font-size: 1rem !important;
    font-weight: 100 !important;
    color: white !important;
    margin-bottom: 2px;
    :hover {
      background-color: ${(props) => (props.dark ? "lightgray !important" : "#E560B3 !important")};
      color: white !important;
    }
  `,
  NoLabelTextField: styled(TextField)`
    width: 100%;
    .MuiFilledInput-root {
      padding: 5px !important;
    }
    input {
      padding: 0 !important;
      text-align: center;
      color: #C20B7E;
      height: 40px;
    }
  `,

  Select: styled(Select)`
    flex: 1;

    border-radius: 0px;
    font-size: 1rem !important;

    .select__control {
      box-shadow: unset;
      background-color: transparent;
      border-radius: 0px;
      min-height: 50px !important;
      font-size: 1rem;
      border: none;
      // border-bottom: solid 1px #000000de;
      :hover {
        border: none;
        // border-bottom: solid 1px #000000de;
      }
      :focuse {
        border: none;
      }
      :actived {
        border: none;
      }
    }
    .select__multi-value {
      border: 1px solid #C20B7E;
    }
    .select__indicator {
      color: #C20B7E !important;
    }
    .select__indicator-separator {
      display: none !important;
    }
  `,
};

CreateEditService.propTypes = {
  selectedItem: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
};
export default CreateEditService;
