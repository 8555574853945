import { useState, useEffect } from "react";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import MDBox from "components/MDBox";
import { FormControlLabel } from "@material-ui/core";
import config from "configs/config.json";
import { useDispatch } from "react-redux";
import { showAlert } from "actions/alert";
import { showLoading } from "actions/loading";
import { callServiceGetAuth, callServicePostAuth } from "services/AuthServices";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import styled from "styled-components";
import Modal from "@mui/material/Modal";
import Select from "react-select";
import DataTable from "components/DataTable";
import logoImg from "assets/images/main-logo-color.png";
import Icon from "@mui/material/Icon";
import Moment from "moment/moment";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import XLSX from "sheetjs-style";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import CreateEditUsers from "./createeditusers";

const TAG = "SalesReport";

const optionReports = [
  { value: "pdf", label: "PDF" },
  { value: "xls", label: "XLS" },
];

const columns = [
  { Header: "Emri & mbiemri", accessor: "name", align: "center", fontSize: 20 },
  { Header: "Telefoni", accessor: "phone", align: "center", fontSize: 20 },
  {
    Header: "Adresa",
    accessor: "address",
    align: "center",
    fontSize: 20,
    px: 0.5,
    width: 100,
  },
  { Header: "Studio", accessor: "studio", align: "center", fontSize: 20, px: 0.5 },
  { Header: "E-mail", accessor: "email", align: "center", fontSize: 20, px: 0.5 },
  { Header: "Lloji", accessor: "role", align: "center", fontSize: 20, px: 0.5 },
  {
    Header: "Aktiv",
    accessor: "active",
    align: "center",
    fontSize: 20,
    width: 30,
  },
  { Header: "Edito", accessor: "edit", align: "center", fontSize: 20, width: 30, padding: 0 },
  { Header: "Fshij", accessor: "delete", align: "center", fontSize: 20, width: 30, padding: 0 },
];

function Users() {
  const [dataRows, setDataRows] = useState([]);
  const [initialUsers, setInitialUsers] = useState();
  const [visibleMode, setVisibleMode] = useState(0);
  const [selectedUser, setSelectedUser] = useState();
  const [openEditModal, setOpenEditModal] = useState(false);
  const dispatch = useDispatch();
  const [reportFileType, setReportFileType] = useState("pdf");
  const [initialStudio, setInitialStudio] = useState();
  const [deleteDlgOpen, setDeleteDlgOpen] = useState(false);
  const [selectedId2Delete, setSelectedId2Delete] = useState();

  const convertData2Option = (data) => {
    console.log("-------------->", data);
    return data.map((item) => ({
      value: item.fields.name,
      label: item.fields.name,
      id: item.pk,
    }));
  };
  const handleGetAllIndivitualResponse = async (res, isErr) => {
    dispatch(showLoading(false));
    if (isErr) {
      dispatch(showAlert({ type: "error", msg: res }));
      return;
    }

    console.log("data", res.data);

    if (res.result === "success" && res.data) {
      // setIndividualData(res.data);
      // console.log(TAG, "response = ", res.data);
      // setInitialService(convertData2Option(res.data.services));
      // setInitialStaffs(res.data.staffs);
      console.log("-------------------------->", res.data.studios);
      setInitialStudio(convertData2Option(res.data.studios));
    }
  };

  const callAPIGetAllIndividuals = async () => {
    const url = config.BASE_URL + config.GET_ALL_INDIVITIAL_INFOS;
    console.log(TAG, "callAPIGetAllIndividuals url = ", url);

    dispatch(showLoading(true));

    callServiceGetAuth(url, handleGetAllIndivitualResponse);
  };

  const handleGetAllUsersResponse = async (res, isErr) => {
    dispatch(showLoading(false));
    if (isErr) {
      dispatch(showAlert({ type: "error", msg: res }));
      return;
    }
    console.log("dddd", res);
    setInitialUsers(res);
  };

  const callAPIGetAllUsers = async () => {
    const url = config.BASE_URL + config.GET_ALL_USERS;
    console.log(TAG, "callAPIGetAllUsers url = ", url);

    dispatch(showLoading(true));

    callServiceGetAuth(url, handleGetAllUsersResponse);
  };

  useEffect(() => {
    if (!initialUsers) {
      callAPIGetAllUsers();
      callAPIGetAllIndividuals();
    }

    setSelectedUser();

    // const row = {};
    // row.name = "xdfds sdf ";
    // row.phone = "200";
    // row.address = "10";
    // row.email = "active ";
    // row.role = "edit";
    // row.active = "10";
    // row.edit = "190";
    // row.delete = "190";

    // setDataRows([row, row, row, row, row, row, row, row, row, row, row, row, row]);
  }, []);

  const getRoleNameByNum = (role) => {
    switch (role) {
      case 1:
        return "Administrator";
      case 2:
        return "Shfrytëzues";
      default:
        return "unknown";
    }
  };

  const handleApiActiveUser = async (res, isErr) => {
    dispatch(showLoading(false));
    if (isErr) {
      dispatch(showAlert({ type: "error", msg: res }));
      return;
    }

    if (res.result !== "success") {
      dispatch(showAlert({ type: "error", msg: res.msg }));
      return;
    }

    callAPIGetAllUsers();
  };

  const callApiActiveUser = async (id, checked) => {
    const url = config.BASE_URL + config.ACTIVE_USER;

    const postData = {
      id,
      actived: checked,
    };

    dispatch(showLoading(false));
    callServicePostAuth(url, postData, handleApiActiveUser);
  };
  const callbackActiveBtn = (id, checked) => {
    callApiActiveUser(id, checked);
  };
  const renderActiveBtn = ({ id, actived, onClicked }) => (
    <Checkbox
      sx={{
        color: "#C20B7E80",
        "&.Mui-checked": {
          color: "#C20B7E",
        },
      }}
      checked={actived}
      onChange={(e) => onClicked(id, e.target.checked)}
    />
  );

  const handleDeleteServiceResponse = async (res, isErr) => {
    dispatch(showLoading(false));
    if (isErr) {
      dispatch(showAlert({ type: "error", msg: res }));
      return;
    }

    if (res.result === "failed") {
      dispatch(showAlert({ type: "error", msg: res }));
      return;
    }

    callAPIGetAllUsers();
    setDeleteDlgOpen(false);
  };

  const callApiDeleteService = async () => {
    const url = config.BASE_URL + config.DELETE_USER;
    // config.DEL_EXPENCES;

    const postData = {
      id: selectedId2Delete,
    };

    setSelectedId2Delete();
    dispatch(showLoading(true));
    callServicePostAuth(url, postData, handleDeleteServiceResponse);
  };

  const handleClickDeleteBtn = () => {
    callApiDeleteService();
  };

  const handleCloseDlg = () => {
    setDeleteDlgOpen(false);
  };

  const callbackEditBtn = (id) => {
    setSelectedUser(initialUsers.filter((item) => item.id === id)[0]);
    setOpenEditModal(true);
  };
  const renderEditBtn = ({ id, onClicked }) => (
    <div
      onClick={() => onClicked(id)}
      onKeyDown={() => onClicked(id)}
      role="button"
      tabIndex="0"
      style={{
        width: 20,
        height: 20,
        cursor: "pointer",
      }}
    >
      <Icon fontSize="medium">edit_note</Icon>
    </div>
  );

  const callbackDeleteBtn = (id) => {
    setDeleteDlgOpen(true);
    setSelectedId2Delete(id);
  };

  const renderDeleteBtn = ({ id, onClicked }) => (
    <div
      onClick={() => onClicked(id)}
      onKeyDown={() => onClicked(id)}
      role="button"
      tabIndex="0"
      style={{
        borderRadius: 5,
        width: 20,
        height: 20,
        cursor: "pointer",
      }}
    >
      <Icon fontSize="medium">delete_outline_icon</Icon>
    </div>
  );

  useEffect(() => {
    if (initialUsers) {
      const rows = initialUsers.map((item) => {
        if (visibleMode === 1 && item.actived === false) return null;
        if (visibleMode === 2 && item.actived === true) return null;

        return {
          name: item.username,
          phone: item.phone,
          address: <div style={{ wordSpacing: "break", width: 100 }}>{item.address}</div>,
          studio: <div style={{ wordSpacing: "break", width: 100 }}>{item.studio_name}</div>,
          email: <div style={{ wordSpacing: "break", width: 100 }}>{item.email}</div>,
          role: getRoleNameByNum(item.role),
          active: renderActiveBtn({
            id: item.id,
            actived: item.actived,
            onClicked: callbackActiveBtn,
          }),
          edit: renderEditBtn({ id: item.id, onClicked: callbackEditBtn }),
          delete: renderDeleteBtn({ id: item.id, onClicked: callbackDeleteBtn }),
        };
      });

      setDataRows([
        ...rows.filter((item) => item !== null),
        // ...rows.filter((item) => item !== null),
        // ...rows.filter((item) => item !== null),
        // ...rows.filter((item) => item !== null),
        // ...rows.filter((item) => item !== null),
        // ...rows.filter((item) => item !== null),
        // ...rows.filter((item) => item !== null),
        // ...rows.filter((item) => item !== null),
        // ...rows.filter((item) => item !== null),
        // ...rows.filter((item) => item !== null),
        // ...rows.filter((item) => item !== null),
        // ...rows.filter((item) => item !== null),
      ]);
    }
  }, [initialUsers, visibleMode]);

  const handleClickCheckShowOnlyActived = (e) => {
    console.log(visibleMode);
    if (e.target.checked) {
      setVisibleMode(1);
    }
  };

  const handleClickCheckShowOnlyNotActived = (e) => {
    if (e.target.checked) {
      setVisibleMode(2);
    }
  };

  const handleClickCheckShowAll = (e) => {
    if (e.target.checked) {
      setVisibleMode(0);
    }
  };

  const handleClickedPrint = () => {
    if (reportFileType === "pdf") {
      const customerHeader = [
        {
          name: "EMRI & MBIEMRI",
          phone: "Telefoni",
          address: "Adresa",
          email: "E-mail",
          role: "Lloji",
          active: "Status",
        },
      ];

      const customData = initialUsers.map((item) => ({
        name: item.username,
        phone: item.phone,
        address: item.address,
        email: item.email,
        role: getRoleNameByNum(item.role),
        active: item.actived ? "Actived" : "",
      }));

      const doc = new jsPDF("l", "mm", "a4");

      const img = new Image();
      img.src = logoImg;
      doc.addImage(img, "png", 120, 10, 65, 20);

      doc.setFontSize(16);
      // doc.setFontStyle("arial");

      doc.text("Përdoruesit Report.", 10, 30);
      const dim = doc.getTextDimensions("Përdoruesit Report.");
      doc.setFontSize(12);
      autoTable(doc, {
        head: customerHeader,
        body: customData,
        startY: dim.h + 30,
        margin: {
          bottom: 20,
        },
        headStyles: {
          backgroundColor: "#C20B7E",
          fillColor: "#C20B7E",
        },
        /* apply styling to table body */
        bodyStyles: {
          valign: "top",
        },
        /* apply global styling */
        styles: {
          cellWidth: "wrap",
          rowPageBreak: "auto",
          halign: "justify",
          width: "100%",
        },
        /* apply styling specific to table columns */
        columnStyles: {
          address: {
            cellWidth: 100,
          },
          name: {
            cellWidth: 40,
          },
          phone: {
            cellWidth: 30,
          },
          email: {
            cellWidth: 40,
          },
          role: {
            cellWidth: 30,
          },
          active: {
            cellWidth: 20,
          },
        },
      });

      const pageCount = doc.internal.getNumberOfPages();
      console.log("page count = ", pageCount);
      for (let i = 1; i <= pageCount; i += 1) {
        // Go to page i
        doc.setPage(i);
        // Print Page 1 of 4 for example
        doc.setFontSize(7);
        doc.text(
          ` Të gjitha të drejtat e këtij dokumenti janë të rezervuara nga Passion. Nuk lejohet kopjimi, duplikimi, citimi i tij në asnjë rast pa lejen me shkrim.`,
          doc.internal.pageSize.width / 2,
          198,
          {
            align: "center",
          }
        );

        const dim2 = doc.getTextDimensions("Staff details.");

        doc.text(`www.bepassion.com`, doc.internal.pageSize.width / 2, 198 + dim2.h, {
          align: "center",
        });
      }

      doc.save(`users - ${Moment(new Date()).format("DD.MM.YYYY")}.pdf`);
    } else {
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet([]);
      XLSX.utils.book_append_sheet(wb, ws, "sheet1");

      const clientDetail = initialUsers.map((item) => ({
        "EMRI & MBIEMRI": item.username,
        Telefoni: item.phone,
        Adresa: item.address,
        "E-mail": item.email,
        Lloji: getRoleNameByNum(item.role),
        Status: item.actived ? "Actived" : "",
      }));
      XLSX.utils.sheet_add_json(ws, clientDetail);
      XLSX.writeFile(wb, `users - ${Moment(new Date()).format("DD.MM.YYYY")}.xls`);
    }
  };

  const handleCloseEditModal = () => {
    // callAPIGetAllIndividuals();
    callAPIGetAllUsers();
    setOpenEditModal(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Dialog
        open={deleteDlgOpen}
        onClose={handleCloseDlg}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Konfirmo fshirjen</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Jeni i sigurt që dëshironi ta fshini këtë? Ky veprim nuk mund të zhbëhet.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDlg}>Anulo</Button>
          <S.Button
            variant="contained"
            color="success"
            onClick={handleClickDeleteBtn}
            autoFocus
            width={100}
          >
            Fshije
          </S.Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={openEditModal}
        onClose={handleCloseEditModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CreateEditUsers
          selectedItem={selectedUser}
          studios={initialStudio}
          onClose={handleCloseEditModal}
        />
      </Modal>
      <MDBox
        pt={1}
        pb={1}
        px={1}
        style={{
          minHeight: "80vh",
          height: "calc(100% - 80px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "auto",
        }}
      >
        <S.Card>
          <S.HeaderContainer>
            <img src={logoImg} alt="logo" />
            <div
              style={{
                width: "100%",
                marginTop: 20,
                justifyContent: "space-between",
                display: "flex",
              }}
            >
              <span style={{ color: "#C20B7E" }}>Përdoruesit</span>
              <S.Button
                onClick={() => {
                  setSelectedUser(null);
                  setOpenEditModal(true);
                }}
                width={100}
                height={30}
              >
                Krijo
              </S.Button>
            </div>
          </S.HeaderContainer>
          <S.ServicesContainer>
            <S.GradientBg />
            <S.SearchFilters>
              <Grid container space={3}>
                <Grid item xs={12} md={4} lg={4}>
                  <S.FilterCellContainer>
                    <FormControlLabel
                      label="Shfaq përdoruesit aktiv:"
                      control={
                        <Checkbox
                          sx={{
                            color: "#C20B7E80",
                            "&.Mui-checked": {
                              color: "#C20B7E",
                            },
                          }}
                          checked={visibleMode === 1}
                          onChange={handleClickCheckShowOnlyActived}
                        />
                      }
                      labelPlacement="start"
                    />
                  </S.FilterCellContainer>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <S.FilterCellContainer>
                    <FormControlLabel
                      label="Shfaq përdoruesit jo aktiv:"
                      control={
                        <Checkbox
                          sx={{
                            color: "#C20B7E80",
                            "&.Mui-checked": {
                              color: "#C20B7E",
                            },
                          }}
                          checked={visibleMode === 2}
                          onChange={handleClickCheckShowOnlyNotActived}
                        />
                      }
                      labelPlacement="start"
                    />
                  </S.FilterCellContainer>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <S.FilterCellContainer>
                    <FormControlLabel
                      label="Shfaqtë gjithë përdoruesit:"
                      control={
                        <Checkbox
                          sx={{
                            color: "#C20B7E80",
                            "&.Mui-checked": {
                              color: "#C20B7E",
                            },
                          }}
                          checked={visibleMode === 0}
                          onChange={handleClickCheckShowAll}
                        />
                      }
                      labelPlacement="start"
                    />
                  </S.FilterCellContainer>
                </Grid>
              </Grid>
            </S.SearchFilters>
            <S.ServicesContent>
              <S.DataTable
                id="table"
                table={{ rows: dataRows, columns }}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={false}
              />
            </S.ServicesContent>
          </S.ServicesContainer>
          <S.BottomContainer>
            <div
              style={{
                width: 350,
                height: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                position: "relative",
              }}
            >
              <S.Select
                className="basic-single"
                classNamePrefix="select"
                isClearable
                isSearchable
                dropdown
                placeholder="Zgjedh..."
                menuPlacement="top"
                value={optionReports.filter((item) => item.value === reportFileType)[0]}
                onChange={(value) => setReportFileType(value ? value.value : null)}
                options={optionReports}
                style={{ backgroundColor: "rgba(0, 0, 0, 0.2)", marginRight: 10 }}
              />
              <div style={{ width: 10 }} />
              <S.Button onClick={handleClickedPrint} width={200}>
                Report
              </S.Button>
            </div>
          </S.BottomContainer>
        </S.Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

const S = {
  Card: styled(Card)`
    width: 95%;
    height: 95%;
    overflow: auto;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    @media (max-width: 1024px) {
      width: 90%;
      min-height: 95%;
    }
  `,
  Conainer: styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
  `,
  CloseBtnDiv: styled.div`
    position: absolute;
    top: 0px;
    right: 20px;
  `,

  HeaderContainer: styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
    img {
      width: 330px;
      height: 100px;
      @media (max-width: 768px) {
        width: 200px;
        height: 60px;
      }
    }
  `,
  CustomerInfoArea: styled.div`
    display: grid !important;
    grid-template-columns: repeat(7, 1fr) !important;
    margin-right: 20px !important;
    grid-gap: 10px !important;
    border-bottom: none !important;
    max-height: none !important;
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
    @media (max-width: 1024px) {
      grid-template-columns: repeat(2, 1fr) !important;
    }
  `,
  CustomerInfoCell: styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    align-items: center;
  `,
  CustomerInfoCelData: styled.div`
    height: 40px;
    border-radius: ${(props) => (props.borderRadius ? `${props.borderRadius}px` : "40px")};
    background-color: rgba(0, 0, 0, 0.06);
    width: ${(props) => (props.auto ? "auto" : "100%")};

    color: #C20B7E;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
  `,
  ServicesContainer: styled.div`
    position: relative;
    padding-top: 20px;
    flex: 1;
    min-height: 400px;
    backgroundcolor: transparent;
    overflow: auto;
  `,

  SearchFilters: styled.div`
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: space-around;
  `,
  ServicesContent: styled.div`
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bttom: 10px;
  `,

  GradientBg: styled.div`
    position: absolute;
    background: white;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0.03) 20%, white 50%);
  `,
  SearchLeftPan: styled.div`
    display: flex;
    flex: 1;
  `,
  SearchRightPan: styled.div`
    display: flex;
    flex: 1;
    @media (max-width: 1024px) {
      display: none;
    }
  `,
  Select: styled(Select)`
    flex: 1;
    min-height: 50px !important;
    border-radius: 10px;

    .select__control {
      box-shadow: unset;
      background-color: rgba(0, 0, 0, 0.06);
      border-radius: 10px;
      min-height: 50px !important;
      border: none;
      :hover {
        border: none;
      }
      :focuse {
        border: none;
      }
      :actived {
        border: none;
      }
    }
    .select__multi-value {
      border: 1px solid #C20B7E;
    }
    .select__indicator {
      color: #C20B7E !important;
    }
    .select__indicator-separator {
      display: none !important;
    }
  `,
  Button: styled(Button)`
    height: 50px !important;
    width: ${(props) => (props.width ? `${props.width}px` : "100%")} !important;
    border-radius: 10px !important;
    background-color: ${(props) => (props.dark ? "gray !important" : "#C20B7E !important")};
    text-transform: none !important;
    font-size: 20px !important;
    font-weight: 100 !important;
    color: white !important;
    margin-bottom: 2px;
    :hover {
      background-color: ${(props) => (props.dark ? "lightgray !important" : "#E560B3 !important")};
      color: white !important;
    }
  `,
  BottomContainer: styled.div`
    height: 80px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
  `,
  DataTable: styled(DataTable)`
    overflow: unset !important;

    .MuiTable-root {
      thead {
        background-color: #C20B7E !important;
        color: white !important;
      }
    }
  `,
  FilterCellContainer: styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
  `,
};

export default Users;
