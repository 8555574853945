import Card from "@mui/material/Card";
import MDBox from "components/MDBox";

import PropTypes from "prop-types";
import MDTypography from "components/MDTypography";
// import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";
import Select from "@mui/material/Select";
import { useState, useEffect } from "react";
import Moment from "moment/moment";
import DataTable from "examples/Tables/DataTable";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch } from "react-redux";
import { showAlert } from "actions/alert";
import config from "configs/config.json";
import { callServicePostAuth } from "services/AuthServices";
import styled from "styled-components";

const TAG = "SalesAccordingMonth";

const monthList = [
  "Janar",
  "Shkurt",
  "Mars",
  "Prill",
  "Maj",
  "Qershor",
  "Korrik",
  "Gusht",
  "Shtator",
  "Tetor",
  "Nëntor",
  "Dhjetor",
];

// const monthList = [
//   "Jan",
//   "Feb",
//   "March",
//   "April",
//   "May",
//   "June",
//   "July",
//   "Aug",
//   "Sept",
//   "Oct",
//   "Nov",
//   "Dec",
// ];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      marginLeft: -30,
    },
  },
};

function SalesAccordingMonth({ title }) {
  console.log(TAG);
  const [selectedYear, setSelectedYear] = useState(2023);
  const [tableData, setTableData] = useState();
  const dispatch = useDispatch();

  const genTableData = (data) => {
    if (data.length === 0) return { columns: [], rows: [] };

    return {
      columns: [
        { Header: "Month", accessor: "month", align: "center", fontSize: 20, maxWidth: "130px" },
        ...data[0].map((item) => ({
          Header: item.name,
          accessor: item.name,
          align: "center",
          fontSize: 20,
        })),
      ],
      rows: [
        ...data.map((item, index) => {
          const resData = { month: monthList[index] };
          item.forEach((t) => {
            resData[t.name] = t.sales;
          });
          return resData;
        }),
      ],
    };
  };

  const handleStudioSalesPerMonthResponse = async (res, isErr) => {
    if (isErr) {
      dispatch(
        showAlert({
          type: "error",
          msg: "Dështim në marrjen e raporteve të shitjeve mujore në studio",
        })
      );
      return;
    }

    if (res.data) {
      const staffData = res.data;

      const data = [];

      for (let i = 1; i <= 12; i += 1) {
        const row = staffData.map((item) => {
          const { name } = item.fields;
          const incomes = item.incomes.filter((m) => Moment(m.month).month() === i);
          const expences = item.expences.filter((m) => Moment(m.month).month() === i);
          const sales =
            (incomes.length > 0 ? incomes[0].total : 0) -
            (expences.length > 0 ? expences[0].total : 0);
          return {
            name: `${name}`,
            sales,
          };
        });

        const checkedRow = [];
        const duplicatedCnt = [];
        for (let j = 0; j < row.length; j += 1) {
          duplicatedCnt.push(checkedRow.filter((item) => item.name === row[j].name).length);
          checkedRow.push(row[j]);
        }

        data.push(
          checkedRow.map((item, index) => {
            if (duplicatedCnt[index] === 0) return item;
            return { name: `${item.name}#${duplicatedCnt[index]}`, sales: item.sales };
          })
        );
      }
      // check duplication

      const tmpTableData = genTableData(data);
      setTableData(tmpTableData);
    }
  };

  const callApiStudiosSalesPerMonth = async () => {
    const url = config.BASE_URL + config.DASHBARD_STUDIO_SALES_MONTH;
    callServicePostAuth(url, { year: selectedYear }, handleStudioSalesPerMonthResponse);
  };

  useEffect(() => {
    setTableData();
    callApiStudiosSalesPerMonth();
  }, [selectedYear]);

  return (
    <Card style={{ height: "100%", padding: "1rem" }}>
      <MDBox display="flex" justifyContent="space-between" pt={2} px={2}>
        <MDBox lineHeight={1.25} display="flex" justifyContent="space-between" width="100%">
          <MDBox display="flex" flexDirection="column">
            <MDTypography
              variant="button"
              fontWeight="light"
              color="text"
              textwrap="wrap"
              style={{ fontSize: 20 }}
            >
              {title}
            </MDTypography>
          </MDBox>
          {/* <MDTypography variant="h4">{count}</MDTypography> */}
          <MDBox
            variant="contained"
            px="5px"
            borderRadius="5px"
            display="flex"
            alignItems="center"
            height="3rem"
          >
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select-standard"
              value={selectedYear}
              fullWidth
              height="40px"
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              onChange={(val) => setSelectedYear(val.target.value)}
              style={{ height: 40, fontWeight: 600 }}
              MenuProps={MenuProps}
            >
              {Array.from(Array(100).keys()).map((item) => (
                <MenuItem value={item + 2020} key={item + 2020}>
                  {item + 2020}
                </MenuItem>
              ))}
            </Select>
          </MDBox>
        </MDBox>
      </MDBox>
      <Divider width="100%" height="2px" />
      <MDBox pt={3}>
        <S.TableContainer>
          {tableData && (
            <DataTable
              table={{ rows: tableData.rows, columns: tableData.columns }}
              isSorted={false}
              entriesPerPage={false}
              showTotalEntries={false}
              noEndBorder
              rowsperPage={6}
            />
          )}
        </S.TableContainer>
      </MDBox>
    </Card>
  );
}

const S = {
  TableContainer: styled.div`
    .MuiTableRow-root {
      th {
        max-width: 120px !important;
      }
    }
  `,
};

SalesAccordingMonth.propTypes = {
  title: PropTypes.string.isRequired,
};

export default SalesAccordingMonth;
