import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import PropTypes from "prop-types";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "gray",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

function CustomTooltip({ children, descriptions }) {
  return (
    <LightTooltip
      title={
        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
          {descriptions.map((item) => (
            <div key={item}>{item}</div>
          ))}
        </div>
      }
      placement="bottom-start"
      arrow={false}
    >
      {children}
    </LightTooltip>
  );
}

CustomTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  descriptions: PropTypes.objectOf(Array).isRequired,
};

export default CustomTooltip;
